import React, { useMemo } from 'react'

import { useMutation } from 'react-query'

import BulkActions from 'containers/ListIndexView/Components/BulkActions'
import { usePortfolioListContext } from 'containers/ListIndexView/PortfolioList'
import PortfolioListBulkActionModal from 'containers/ListIndexView/PortfolioList/PortfolioListBulkActionModal'
import { IPortfolioCompany } from 'containers/ListIndexView/PortfolioList/types'
import { useModal } from 'global/Modal'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { GetInvestorTagsResponse } from 'utils/types/investor'

interface Props {
  selectedCompanies: IPortfolioCompany[]
  ownedCompanyTags?: GetInvestorTagsResponse
}

const PortfolioListBulkActions: React.FC<Props> = ({ selectedCompanies, ownedCompanyTags }) => {
  const { showModal } = useModal()
  const { adminView } = usePortfolioListContext()
  const {
    companyListData: companyList,
    refetchPortfolioCompaniesList,
    view,
    setView,
  } = usePortfolioListContext()

  const selectedCompaniesUuids = useMemo(
    () =>
      selectedCompanies
        .map((company) => company.investor_company_uuid)
        .filter((uuid): uuid is string => uuid !== undefined),
    [selectedCompanies],
  )

  const renderPortfolioListBulkActionModal = (
    resolve: () => void,
    header: string,
    kind: string,
  ) => (
    <PortfolioListBulkActionModal
      onHide={() => resolve()}
      refetch={refetchPortfolioCompaniesList}
      selectedCompaniesUuids={selectedCompaniesUuids}
      ownedCompanyTags={ownedCompanyTags}
      header={header}
      kind={kind}
      companyListUuid={companyList.uuid}
    />
  )

  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  const { mutate: bulkDelete } = useMutation(
    (kind: string) =>
      callApi(api.portfolioBulkActions, companyList.uuid, {
        selected_companies_uuids: selectedCompaniesUuids,
        bulk_action: 'delete',
        kind: kind,
        values: [],
      }),
    {
      onSuccess: () => {
        sleep(1000).then(() => {
          refetchPortfolioCompaniesList()
          cabalToast({ style: 'success', content: 'Successfully deleted!' })
        })
      },
      onError: () => {
        cabalToast({
          style: 'error',
          content: 'Something went wrong, please try again or contact support',
        })
      },
    },
  )

  const handleOnSelect = (header: string, action: string, kind: string) => {
    if (selectedCompanies.length === 0) {
      cabalToast({
        content: 'Select companies to perform bulk actions',
        style: 'error',
      })
      return
    }
    if (action === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        bulkDelete(kind)
      }
    } else {
      showModal(
        (resolve) => renderPortfolioListBulkActionModal(resolve, header, kind),
        'render_portfolio_list_bulk_actions',
      )
    }
  }

  const bulkMenuItems = [
    {
      label: 'Add Tags',
      onSelect: () => handleOnSelect('Add Tags', 'add', 'tags'),
    },
    {
      label: 'Add Stage',
      onSelect: () => handleOnSelect('Add Stage', 'add', 'stage'),
    },
    {
      label: 'Delete Tags',
      onSelect: () => handleOnSelect('Delete Tags', 'delete', 'tags'),
    },
    {
      label: 'Delete Stage',
      onSelect: () => handleOnSelect('Delete Stage', 'delete', 'stage'),
    },
    {
      label: 'Delete Companies',
      onSelect: () => handleOnSelect('Delete Companies', 'delete', 'companies'),
    },
  ]

  return (
    <>
      {adminView && (
        <span onClick={() => view === 'grid' && setView('list')}>
          <BulkActions disabled={selectedCompanies.length === 0} bulkMenuItems={bulkMenuItems} />
        </span>
      )}
    </>
  )
}

export default PortfolioListBulkActions
