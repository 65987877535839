import React, { createContext, useContext, useMemo, useState } from 'react'

import moment from 'moment'
import { useInfiniteQuery } from 'react-query'
import { ArrayParam, BooleanParam, StringParam } from 'use-query-params'

import SalesCompanyList from 'containers/ListIndexView/SalesList/SalesCompanyList'
import SalesPeopleList from 'containers/ListIndexView/SalesList/SalesPeopleList'
import {
  ISalesCompany,
  ISalesPeople,
  SalesFilterTypes,
  SalesListContextProps,
} from 'containers/ListIndexView/SalesList/type'
import { useAccessControl } from 'global/AccessControl'
import PageWrapper from 'global/PageWrapper'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import companyList from 'utils/api/companyList'
import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import { CompanyListBlueprint } from 'utils/types'

interface Props {
  companyListData: CompanyListBlueprint
  refetchCompanyList: () => void
}

export const SalesListContext = createContext<SalesListContextProps | undefined>(undefined)

export const useSalesListContext = () => {
  const context = useContext(SalesListContext)
  if (context === undefined) {
    throw new Error('useSalesListContext must be used within a SalesListProvider')
  }
  return context
}

const SalesList = ({ companyListData, refetchCompanyList }: Props) => {
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  // const [showAddWidget, setShowAddWidget] = useState(false)
  // const [selectedCompanies, setSelectedCompanies] = useState<ISalesCompany[]>([])

  // TODO get rid of this weird setting
  const listTypeData = window.LIST_TYPES[companyListData.list_type]

  const queryParamConfigMap = useMemo(() => {
    return {
      search: ArrayParam,
      portfolio_stages: ArrayParam,
      member_view: BooleanParam,
      size: ArrayParam,
      tags: ArrayParam,
      sort: StringParam,
    }
  }, [])

  const [filters, setFilters_] = useQueryParamsWithSettings(queryParamConfigMap)

  const setFilters = (updatedFilters: SalesFilterTypes) => {
    setFilters_(updatedFilters)
  }

  const {
    isLoading: salesListIsLoading,
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    dataUpdatedAt: companyDataUpdatedAt,
  } = useInfiniteQuery(
    [
      teamSlug,
      'opportunityData',
      companyListData?.uuid,
      companyListData?.status?.stale,
      companyListData?.item_count,
      filters,
    ],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getCompanies,
        teamSlug, // team slug
        page, // page
        {
          ...filters,
          sources: [companyListData?.uuid],
        }, // filters
      ),
    {
      cacheTime: 0,
      refetchInterval: (data) => {
        if (listTypeData?.es || companyListData?.default_type_identifier) {
          const results = data?.pages?.flatMap((page) => {
            return page.company_ids?.map((id) => page.company_records[id]) ?? []
          })
          const isEmptyAndRecentlyIndexed =
            (results ?? []).length === 0 &&
            (!companyListData?.status?.initial_indexing_completed_at ||
              moment().diff(
                moment(companyListData.status.initial_indexing_completed_at),
                'minutes',
              ) < 5)

          if (isEmptyAndRecentlyIndexed) {
            return 1500
          }
        }

        return false
      },
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      // onSettled: () => setShowResultsLoading(false),
      keepPreviousData: true,
      enabled: isAdminOrEmployee,
    },
  )

  const salesCompanies: ISalesCompany[] = useMemo(() => {
    let companiesWithResults: any = []
    // get all the companies with results
    if (data?.pages?.[0]?.sources?.includes(companyListData?.uuid) || !companyListData?.uuid) {
      companiesWithResults = data?.pages?.flatMap((page) => {
        return page.company_ids.map((id) => page.company_records[id])
      })
    }
    return companiesWithResults
  }, [data, companyList])

  // TODO: we should also send sales people records
  const salesPeople: ISalesPeople[] = useMemo(
    () => [
      {
        name: 'John Doe',
        email: 'john.doe@example.com',
        company: 'Example Company',
        description: 'Example Description of what he does',
        avatar_url: 'https://via.placeholder.com/150',
        linkedin_url: 'https://linkedin.com/in/john-doe',
        uuid: 'uuid-1',
      },
      {
        name: 'Jane Smith',
        email: 'jane.smith@example.com',
        company: 'Another Company',
        description: 'Handles sales for Another Company',
        avatar_url: 'https://via.placeholder.com/150',
        linkedin_url: 'https://linkedin.com/in/jane-smith',
        uuid: 'uuid-2',
      },
      {
        name: 'Alice Johnson',
        email: 'alice.johnson@example.com',
        company: 'Tech Corp',
        description: 'Sales representative at Tech Corp',
        avatar_url: 'https://via.placeholder.com/150',
        linkedin_url: 'https://linkedin.com/in/alice-johnson',
        uuid: 'uuid-3',
      },
      {
        name: 'Bob Brown',
        email: 'bob.brown@example.com',
        company: 'Sales Inc',
        description: 'Senior sales manager at Sales Inc',
        avatar_url: 'https://via.placeholder.com/150',
        linkedin_url: 'https://linkedin.com/in/bob-brown',
        uuid: 'uuid-4',
      },
      {
        name: 'Charlie Davis',
        email: 'charlie.davis@example.com',
        company: 'Market Masters',
        description: 'Expert in market sales at Market Masters',
        avatar_url: 'https://via.placeholder.com/150',
        linkedin_url: 'https://linkedin.com/in/charlie-davis',
        uuid: 'uuid-5',
      },
    ],
    [],
  )

  const adminView = !!isAdminOrEmployee && !!companyListData.owned && !filters.member_view
  const memberView = !isAdminOrEmployee || !!filters.member_view
  const salesListEmpty = salesCompanies.length === 0
  const sharedListView = !companyListData.owned

  const contextValue = useMemo(() => {
    return {
      companyListData,
      refetchCompanyList,
      adminView,
      salesCompanies,
      salesPeople,
      // view,
      // setView,
    }
  }, [
    companyListData,
    refetchCompanyList,
    adminView,
    salesCompanies,
    salesPeople,
    // view,
    // setView,
  ])

  return (
    <SalesListContext.Provider value={contextValue}>
      <PageWrapper title={companyListData.name}>
        {/* <SalesCompanyList
          listLoading={salesListIsLoading}
          memberView={memberView}
          adminView={adminView}
          salesListEmpty={salesListEmpty}
          sharedListView={sharedListView}
          filters={filters}
          setFilters={setFilters}
        /> */}

        <SalesPeopleList
          listLoading={salesListIsLoading}
          memberView={memberView}
          adminView={adminView}
          salesListEmpty={salesListEmpty}
          sharedListView={sharedListView}
          filters={filters}
          setFilters={setFilters}
        />
      </PageWrapper>
    </SalesListContext.Provider>
  )
}

export default SalesList
