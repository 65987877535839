import React, { useMemo } from 'react'

import Filters from 'components/Block/Filters'
import { EditListModal, EditListProps } from 'components/EditList'
import EditListV2 from 'components/EditListV2'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { useCurrentUser } from 'store/hooks'

import { CompanyListBlueprint } from 'utils/types'

interface Props {
  list: CompanyListBlueprint[]
  filter: { listType: string; selectedTeams: string[] }
  setFilter: (filter: { listType: string; selectedTeams: string[] }) => void
  setQuery: (query: string) => void
  query: string
  teamOptions: { value: string; label: string }[]
}

const FilterPills: React.FC<Props> = ({ list, teamSlug, filter, setFilter, teamOptions }) => {
  const { user } = useCurrentUser()

  const uniqueListCategories = useMemo(() => {
    const listCategories: string[] = []

    list.forEach((item) => {
      if (item.list_category) {
        listCategories.push(item.list_category)
      }

      if (item?.user?.uuid === user.uuid) {
        listCategories.push('my_lists')
      }
    })
    const orderedListCategory = ['my_lists', 'sales', 'fundraise', 'talent', 'resources']
    const customSort = (a, b) => {
      return orderedListCategory.indexOf(a) - orderedListCategory.indexOf(b)
    }
    const uniqueCategories = new Set(
      listCategories.filter((type) => orderedListCategory.includes(type)),
    )
    return Array.from(uniqueCategories).sort(customSort)
  }, [list, user])

  const renderEditListModal = (resolve: () => void) => {
    return <EditListV2 onHide={() => resolve()} teamSlug={teamSlug} />
  }

  const renderNewListModal = (
    resolve: () => void,
    listType: EditListProps['listType'],
    listName?: string,
    listDescription?: string,
    listTags?: string[],
  ) => (
    <EditListModal
      dynamic={false}
      onHide={() => resolve()}
      teamSlug={teamSlug}
      listType={listType}
      listName={listName}
      listDescription={listDescription}
      listTags={listTags}
    />
  )
  const { showModal } = useModal()

  return (
    <div className="flex justify-between gap-2 my-4">
      <Filters
        teamSlug={teamSlug}
        onChange={setFilter}
        filterTypes={[
          {
            key: 'query',
            type: 'search',
            icon: 'far fa-search',
            labelStr: 'Search',
            label: 'Search',
            skipLabelInAppliedPill: true,
            noPopover: true,
          },
          {
            key: 'teams',
            type: 'dropdown[]',
            label: 'Team',
            options: teamOptions,
          },
          {
            key: 'listType',
            type: 'dropdown[]',
            label: 'List Type',
            options: uniqueListCategories.map((type) => {
              const labelText = `${type.replace('_', ' ').replace(/\b\w/g, (l) => l.toUpperCase())}`
              return {
                value: type,
                label: labelText,
              }
            }),
          },
        ]}
      />

      <CabalButton
        variant="primary"
        onClick={() => {
          return showModal(
            (resolve) => renderNewListModal(resolve, undefined),
            'render_new_list_modal',
          )

          // return showModal((resolve) => renderEditListModal(resolve), 'render_new_list_modal')
        }}
        leftIcon={<i className="far fa-list fa-fw"></i>}
        className="min-w-[120px] h-[36px]" // Ensuring the button has a minimum width to prevent text wrapping and fixed height to prevent resizing
      >
        New List
      </CabalButton>
    </div>
  )
}

export default FilterPills
