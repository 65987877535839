import React, { useEffect, useState } from 'react'

import { useMutation } from 'react-query'
import { useSetState } from 'react-use'
import validator from 'validator'

import { Divider } from 'components/SendMessage/styles'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { TextArea, TextInput } from 'global/Input'
import Modal, { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useUniqueAccessLink } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import errorMessages from 'utils/errorMessages'
import { CompanyBlueprint, PersonBlueprint } from 'utils/types'

import { RequestIntroConnections } from './RequestIntroConnections'

interface Props {
  requestIntro?: ({
    companyUuid,
    context,
    linkedinUrl,
  }: {
    companyUuid: string
    context: string
    linkedinUrl: string
  }) => void
  resolve: () => void
  companyUuid?: string
  companyName?: string
  companyLogo?: string
  requestable?: CompanyBlueprint | PersonBlueprint
  item?: any
  teamSlug?: string
  onCreate?: () => void
  new_intro_layout?: boolean
  selectConnection?: boolean
  gatekeeperId?: number
}

export interface FormDetails {
  linkedinUrl: string
  context: string
  firstName: string
  lastName: string
  isCompanyRequest?: boolean
}

interface RequestIntroFormProps {
  companyName?: string
  companyLogo?: string
  details: FormDetails
  setDetails: (details: Partial<FormDetails>) => void
  setLinkedinUrlValid: (valid: boolean) => void
  jobTitle?: string
  advisorName?: string
  advisorAvatar?: string
  isIntroToCandidate?: boolean
  item?: any
  new_intro_layout?: boolean
  selectConnection?: boolean
  teamSlug?: string
  requestable?: CompanyBlueprint | PersonBlueprint
  gatekeeperId?: number
}

export const RequestIntroForm: React.VFC<RequestIntroFormProps> = ({
  companyName,
  companyLogo,
  details,
  setDetails,
  setLinkedinUrlValid,
  jobTitle,
  advisorName,
  advisorAvatar,
  isIntroToCandidate,
  item,
  new_intro_layout,
  selectConnection,
  requestable,
  gatekeeperId,
  teamSlug,
}) => {
  const {
    user: { avatar_url, name },
  } = useCurrentUser()

  const { isUniqueAccessLink, uniqueAccessLinkData } = useUniqueAccessLink()
  const user = useCurrentUser().user

  if (new_intro_layout) {
    return (
      <RequestIntroConnections
        teamSlug={teamSlug || ''}
        requestable={requestable || undefined}
        gatekeeperId={gatekeeperId || 0}
      />
    )
  }

  return (
    <>
      <div className="flex mt-5 justify-center items-center">
        {isUniqueAccessLink ? (
          <Avatar
            name={
              uniqueAccessLinkData?.recipient_first_name ||
              uniqueAccessLinkData?.recipient_email ||
              ''
            }
            src={uniqueAccessLinkData.email}
            size="40"
            round
          />
        ) : (
          <Avatar name={name} src={avatar_url} size="40" round />
        )}
        <i className="fas fa-exchange mx-2"></i>
        {isIntroToCandidate ? (
          <Avatar src={advisorAvatar} name={advisorName} size="40" round />
        ) : (
          <Avatar
            src={item ? item.avatar_url : companyLogo}
            name={item ? item.title : companyName}
            size="40"
            round
          />
        )}
      </div>
      {jobTitle && (
        <div className="mt-2" data-testid="request-talent-intro-modal-job-role">
          <Typography fontSize="12" fontWeight={600}>
            Position:&nbsp;
          </Typography>
          <Typography fontSize="12" fontWeight={400}>
            {jobTitle}
          </Typography>
        </div>
      )}
      <Divider />
      <div className="text-left">
        <TextInput
          value={details.firstName}
          onChange={(e) => {
            setDetails({ firstName: e.target.value })
          }}
          className="mb-2 w-full"
          valueType="non-empty"
          placeholder="First Name"
          data-testid="request-talent-intro-modal-first-name"
        />
        <TextInput
          value={details.lastName}
          onChange={(e) => {
            setDetails({ lastName: e.target.value })
          }}
          className="mb-2 w-full"
          valueType="non-empty"
          placeholder="Last Name"
          data-testid="request-talent-intro-modal-last-name"
        />
        {item && (
          <TextArea
            value={details.context}
            onChange={(e) => setDetails({ context: e.target.value })}
            className="w-full"
            rows={3}
            placeholder={`Add a note about why you're interested in connecting with ${item.title}.`}
            data-testid="request-talent-intro-modal-context"
          />
        )}
        {!item && (
          <TextArea
            value={details.context}
            onChange={(e) => setDetails({ context: e.target.value })}
            className="w-full"
            rows={3}
            placeholder={
              isIntroToCandidate
                ? `Add a note about why you're interested in connecting with ${advisorName}.`
                : `Add a note about why you're interested in connecting with ${companyName}.`
            }
            data-testid="request-talent-intro-modal-context"
          />
        )}
        {!item && (
          <TextInput
            errorMessage={errorMessages.invalidUrl}
            value={details.linkedinUrl}
            onChange={(e, valid) => {
              setLinkedinUrlValid(!!valid)
              setDetails({ linkedinUrl: e.target.value })
            }}
            placeholder="https://www.linkedin.com/in/username"
            className="mb-2 w-full"
            valueType="url"
            data-testid="request-talent-intro-modal-linkedin-url"
          />
        )}
      </div>
    </>
  )
}

const RequestIntroModal: React.VFC<Props> = ({
  requestIntro,
  resolve,
  companyUuid,
  companyName,
  companyLogo,
  item,
  teamSlug,
  onCreate,
  requestable,
  gatekeeperId,
  new_intro_layout,
  selectConnection,
}) => {
  const { user } = useCurrentUser()
  const [isWorking, setIsWorking] = useState(false)
  const { closeModal } = useModal()
  // TODO: this should be determined based on whether request intro is for company or people
  const isCompanyRequest = false
  const { isUniqueAccessLink, uniqueAccessLinkData, isLoadingUniqueAccessLink } =
    useUniqueAccessLink()
  const [details, setDetails] = useSetState<FormDetails>({
    linkedinUrl: user.linkedin_url || '',
    context: '',
    firstName: user.first_name,
    lastName: user.last_name,
  })
  const [linkedinUrlValid, setLinkedinUrlValid] = useState(validator.isURL(user.linkedin_url || ''))

  const handleClick = () => {
    setIsWorking(true)
    requestIntro
      ? requestIntro({ companyUuid, details, isCompanyRequest })
      : modalRequestIntro({ companyUuid, details, isCompanyRequest })
  }

  const { mutate: modalRequestIntro } = useMutation(
    ['requestIntro'],
    ({ companyUuid, details, isCompanyRequest }) => {
      return callApi(
        api.requestIntro,
        companyUuid,
        teamSlug,
        details,
        undefined,
        undefined,
        isCompanyRequest,
      )
    },
    {
      onSuccess: () => {
        if (onCreate) {
          onCreate()
        }
        cabalToast({ style: 'success', content: 'Intro request was successfully sent!' })
        closeModal('request-intro-modal')
      },
      onError: (error) => {
        const errMsg = error?.response?.data?.error
        cabalToast({
          style: 'error',
          content: `Something went wrong${errMsg ? ': ' + errMsg : '!'}`,
        })
      },
    },
  )

  useEffect(() => {
    if (isUniqueAccessLink) {
      setDetails({
        firstName: uniqueAccessLinkData?.recipient_first_name || '',
        lastName: uniqueAccessLinkData?.recipient_last_name || '',
        linkedinUrl: uniqueAccessLinkData?.recipient_linkedin_url || '',
      })
      setLinkedinUrlValid(validator.isURL(uniqueAccessLinkData?.recipient_linkedin_url || ''))
    }
  }, [isLoadingUniqueAccessLink])

  const disabled = !details.linkedinUrl || !linkedinUrlValid || !details.context || isWorking

  if (selectConnection) {
    return (
      <Modal
        show
        onHide={resolve}
        size="sm"
        header={
          <Typography
            fontSize="16"
            fontWeight={600}
            lineHeight="1.2"
            className="w-full text-center"
          >
            {`Request intro to ${item ? item.title : companyName}`}
          </Typography>
        }
        centerActions={
          <CabalButton
            onClick={handleClick}
            working={isWorking}
            disabled={disabled || isWorking}
            className="w-full"
            data-testid="request-talent-intro-modal-send-btn"
          >
            Send
          </CabalButton>
        }
      >
        <div className="text-center">
          <RequestIntroForm
            item={item}
            companyLogo={companyLogo}
            companyName={companyName}
            details={details}
            setDetails={setDetails}
            setLinkedinUrlValid={setLinkedinUrlValid}
            new_intro_layout={new_intro_layout}
            selectConnection={selectConnection}
            requestable={requestable}
            isIntroToCandidate={false}
            gatekeeperId={gatekeeperId}
            teamSlug={teamSlug}
          />
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      show
      onHide={resolve}
      size="sm"
      header={
        <Typography fontSize="16" fontWeight={600} lineHeight="1.2" className="w-full text-center">
          {`Request intro to ${item ? item.title : companyName}`}
        </Typography>
      }
      centerActions={
        <CabalButton
          onClick={handleClick}
          working={isWorking}
          disabled={disabled || isWorking}
          className="w-full"
          data-testid="request-talent-intro-modal-send-btn"
        >
          Send
        </CabalButton>
      }
    >
      <div className="text-center">
        <RequestIntroForm
          item={item}
          companyLogo={companyLogo}
          companyName={companyName}
          details={details}
          setDetails={setDetails}
          setLinkedinUrlValid={setLinkedinUrlValid}
          new_intro_layout={new_intro_layout}
          teamSlug={teamSlug}
          gatekeeperId={gatekeeperId}
        />
      </div>
    </Modal>
  )
}

export default RequestIntroModal
