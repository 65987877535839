import React, { useMemo } from 'react'

import { compact, isEqual } from 'lodash'
import styled from 'styled-components'

import { useAccessControl } from 'global/AccessControl'
import { Label, Select, SelectOption } from 'global/Input'
import Typography from 'global/Typography'

import { MessageRecipient, SenderModel, Team } from 'utils/types'

/**
 * for alias_email explanation, see MESSAGES.md -> alias_email
 */

export enum SenderFieldAction {
  AddGmailEmail = 'AddGmailEmail',
  AddMicrosoftEmail = 'AddMicrosoftEmail',
}

type Option<T = SenderModel | SenderFieldAction> = SelectOption<T>

interface Props {
  senders: SenderModel[] | undefined
  from?: string
  onSelect: (a?: SenderModel | SenderFieldAction) => void
  team: Team
  recipients?: MessageRecipient[]
}

const SenderField: React.VFC<Props> = ({ senders, onSelect, from, team, recipients }) => {
  const { canSendMessages, canViewAdvisorSensitive } = useAccessControl(team.slug)

  const selectedSender = useMemo(() => {
    return senders?.find((sender) => {
      return sender.email == from || sender.alias_email == from
    })
  }, [senders, from])

  const senderOptions: Option<SenderModel | SenderFieldAction>[] = useMemo(() => {
    if (!!senders?.length) {
      return senders.map((sender) => {
        let email: string | undefined

        // if canViewAdvisorSensitive i.e can see advisor email, add email to label
        // or
        // if !canViewAdvisorSensitive and sender is not advisor, show email
        if (canViewAdvisorSensitive || sender.type !== 'advisor') {
          email = sender.use_alias ? sender.alias_email : sender.email
        }

        return {
          value: sender,
          label: compact([sender.name, email]).join(' - '),
          group:
            sender.type === 'admin'
              ? 'Admins'
              : sender.type === 'employee'
              ? 'Employees'
              : 'Members',
        } as Option
      })
    } else {
      return []
    }
  }, [senders, recipients])

  const allOptions: Option[] | undefined = useMemo(() => {
    if (team && canSendMessages) {
      const updatedOptions = [
        ...senderOptions,
        {
          value: SenderFieldAction.AddGmailEmail,
          label: '+ Add Gmail Account',
          group: '',
        },
      ]

      updatedOptions.push({
        value: SenderFieldAction.AddMicrosoftEmail,
        label: '+ Add Microsoft Account',
        group: '',
      })

      return updatedOptions
    } else {
      return senderOptions
    }
  }, [senderOptions, canSendMessages, team])

  return allOptions && allOptions.length > 0 ? (
    <Label className="mb-0 flex-grow">
      <FieldWrapper
        className="flex w-full justify-between"
        data-intercom-target="messages_to_field"
      >
        <Typography
          fontWeight={400}
          color="admin_label"
          component="div"
          fontSize="12"
          className="my-auto px-3"
        >
          From
        </Typography>

        <Select<SenderModel | SenderFieldAction>
          className="w-full"
          fontSize="14px"
          onChange={(value) => {
            if (value === SenderFieldAction.AddGmailEmail) {
              onSelect(SenderFieldAction.AddGmailEmail)
            } else if (value === SenderFieldAction.AddMicrosoftEmail) {
              onSelect(SenderFieldAction.AddMicrosoftEmail)
            } else {
              onSelect(value as SenderModel)
            }
          }}
          value={selectedSender}
          options={allOptions}
          portal
          removeBorder
          removeFocusRing
          data-testid="composer-from-select"
        />
      </FieldWrapper>
    </Label>
  ) : null
}

const FieldWrapper = styled.div`
  background: ${({ theme }) => theme.layout.main_bg_color};
  border-radius: 0.25rem;
`

export default React.memo(SenderField, isEqual)
