import React, { useState } from 'react'

import pluralize from 'pluralize'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { ISalesCompany, ISalesPeople } from 'containers/ListIndexView/SalesList/type'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import { cn } from 'utils/styles'
import { VoteType } from 'utils/types'

const SalesCompany = ({ person }: { person: ISalesPeople }) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const [vote, setVote] = useState<VoteType>(0)

  return (
    <DataCardV2
      // onClick={() => history.push(`/${teamSlug}/lists/${companyListSlug}/${person.uuid}`)}
      key={person.uuid}
      title={person.name}
      description={person.description}
      avatar={<Avatar src={person.avatar_url} name={person.name} size={'32'} />}
      titleIcon={
        person.linkedin_url && (
          <Typography
            color="fog"
            fontSize="14"
            lineHeight={1}
            onClick={(e) => {
              e.stopPropagation()
              window.open(person.linkedin_url, '_blank')
            }}
          >
            <i className="fab fa-linkedin" />
          </Typography>
        )
      }
      cta={
        <div
          className="flex items-center justify-end w-[82px]"
          // onClick={() => {
          //   sendVote(vote === 0 ? 1 : 0)
          //   setAnimateLike(vote === 0 ? true : false)
          // }}
        >
          {/* {vote === 1 && sendingVote && (
            <div className="mr-1">
              <Loading size={12} strokeWidth={5} />
            </div>
          )} */}
          <Typography
            color={vote === 1 ? 'purple' : 'default_icon'}
            lineHeight="1"
            fontSize="12"
            className="flex items-center"
          >
            <span className="like-button">{vote === 1 ? 'Offered' : 'Offer intro'}</span>
          </Typography>
          <Typography
            color={vote === 1 ? 'purple' : 'default_icon'}
            lineHeight="1"
            fontSize="16"
            className="flex items-center pl-2 like-icon-internal"
            component="p"
          >
            {vote === 1 ? <i className="fas fa-thumbs-up" /> : <i className="far fa-thumbs-up" />}
          </Typography>
        </div>
      }
      className={cn(
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded',
      )}
    />
  )
}

const SalesPeopleMemberView = () => {
  const { salesPeople } = useSalesListContext()
  console.log('salesPeople', salesPeople)
  return (
    <div>
      {salesPeople.map((salesPerson) => (
        <SalesCompany key={salesPerson.uuid} person={salesPerson} />
      ))}
    </div>
  )
}

export default SalesPeopleMemberView
