import React, { useState } from 'react'

import moment from 'moment'

import { SeeMore } from 'containers/CandidateProfile'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import Widget from 'ui-components/Widget'

import { CandidateEducation, CandidateProfile } from 'utils/types'

const EducationRow = ({ education }: { education: CandidateEducation }) => {
  const [isExpanded, setIsExpanded] = useState(education.summary.length < 165)
  const description = []
  const summary = isExpanded ? education.summary : `${education.summary.slice(0, 165)}`

  if (education.field_of_study) {
    description.push(
      <div>
        {/* <i className="far fa-school mr-1" />  */}
        {education.field_of_study}
      </div>,
    )
  }

  if (education.start_at && education.end_at) {
    description.push(
      <div>
        {moment(education.start_at).format('MMM YYYY')} -{' '}
        {moment(education.end_at).format('MMM YYYY')}
      </div>,
    )
  } else if (education.start_at) {
    description.push(<div>{moment(education.start_at).format('MMM YYYY')} </div>)
  }

  return (
    <>
      <DataCardV2
        avatar={<i className="far fa-school" />}
        title={education.school}
        description={description}
      />
      <p className="ml-12 leading-5 -mt-1 mb-1">
        <Typography color="rain_fog" fontSize="12" lineHeight={1}>
          {summary}
        </Typography>
        {education.summary.length > 165 && (
          <SeeMore
            className="ml-1 cursor-pointer"
            color="fog"
            fontSize="12"
            lineHeight={1}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? '...see less' : '...see more'}
          </SeeMore>
        )}
      </p>
    </>
  )
}

interface Props {
  candidateProfile: CandidateProfile
}

const Education: React.FC<Props> = ({ candidateProfile }) => {
  const [limit, setLimit] = React.useState(3)

  const sortedEducationHistory = React.useMemo(() => {
    return candidateProfile.education_history.sort((a, b) => {
      const aEnd = a.end_at ? moment(a.end_at).valueOf() : -Infinity
      const bEnd = b.end_at ? moment(b.end_at).valueOf() : -Infinity
      return bEnd - aEnd
    })
  }, [candidateProfile.education_history])

  const educationHistory = React.useMemo(
    () => sortedEducationHistory.slice(0, limit),
    [sortedEducationHistory, limit],
  )

  if (sortedEducationHistory.length === 0) {
    return null
  }

  return (
    <Widget title="Education">
      <div className="-mx-2">
        {educationHistory.map((education) => (
          <EducationRow key={education.uuid} education={education} />
        ))}
      </div>
      {candidateProfile.education_history.length > 3 && (
        <div className="flex justify-center pt-1">
          <CabalButton
            variant="link"
            onClick={() =>
              limit === 3 ? setLimit(candidateProfile.education_history.length) : setLimit(3)
            }
          >
            See all ({candidateProfile.education_history.length})
          </CabalButton>
        </div>
      )}
    </Widget>
  )
}

export default Education
