import React from 'react'
import { useParams } from 'react-router-dom'

import { useTeam } from 'store/hooks'
import { CompanySlugParam, Team } from 'utils/types'
import { useAccessControl } from 'global/AccessControl'

import { CardBg } from '../styles'
import { CapTableType } from 'utils/types'
import SectionHeader from 'ui-components/SectionHeader'
import AgreementsExport from '../AdvisorProgram/AgreementsExport'
import Legal from '../Company/Legal'
import EquityTiers from 'utils/api/equityTiers'
import EquityTierWrapper from './EquityTierWrapper'
import { useQuery } from 'react-query'
import api, { callApi } from 'utils/api'
import CapTable from './CapTable'

export interface TeamSettingProps {
  team: Team
  reloadTeam: () => Promise<any>
}

const Company = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(companySlug)
  const { canExportAgreements, canViewEquity } = useAccessControl(companySlug)
  const [capTable, setCapTable] = React.useState<CapTableType>()

  useQuery(['getCapTable', team?.slug], () => callApi(api.getCapTable), {
    onSuccess: (response) => {
      setCapTable(response.cap_table)
    },
  })

  if (!team) return <></>

  const teamSettingProps: TeamSettingProps = {
    reloadTeam,
    team,
  }

  return (
    <div>
      <SectionHeader title="Legal" />
      <CardBg>
        <Legal {...teamSettingProps} />
      </CardBg>
      {canViewEquity && (
        <>
          <SectionHeader title="Budget" />
          <CardBg>
            <CapTable {...teamSettingProps} capTable={capTable} setCapTable={setCapTable} />
          </CardBg>

          <SectionHeader title="Tiers" />
          <CardBg>
            <EquityTierWrapper capTable={capTable} setCapTable={setCapTable} />
          </CardBg>
        </>
      )}
      {canExportAgreements && (
        <>
          <SectionHeader title="Grant Details" />

          <CardBg>
            <AgreementsExport {...teamSettingProps} />
          </CardBg>
        </>
      )}
    </div>
  )
}

export default Company
