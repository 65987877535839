import axios from 'axios'

interface GlobalPerson {
  first_name: string
  last_name: string
  email: string
  company_name: string
  position: string
  linkedin_url: string
  image_url: string
  location?: string
}

export default {
  createGlobalPerson: ({
    first_name,
    last_name,
    email,
    company_name,
    position,
    linkedin_url,
    image_url,
    location,
  }: GlobalPerson) => {
    return axios.post(`/api/global_people`, {
      first_name,
      last_name,
      email,
      company_name,
      position,
      linkedin_url,
      image_url,
      location,
    })
  },
  getGlobalPerson: (id: string) => {
    return axios.get(`/api/global_people/${id}`)
  },
}
