import React from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import PortfolioDataTags from 'containers/CompanyPage/SharedListsSection/PortfolioDataTags'
import { usePortfolioListContext } from 'containers/ListIndexView/PortfolioList'
import PortfolioCompanyEditOrAddModal from 'containers/ListIndexView/PortfolioList/PortfolioCompanyEditOrAddModal'
import { IPortfolioCompany } from 'containers/ListIndexView/PortfolioList/types'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'

const PortfolioItem = ({
  company,
  view,
  selectedCompanies,
  setSelectedCompanies,
}: {
  company: IPortfolioCompany
  view: 'grid' | 'list'
  selectedCompanies: IPortfolioCompany[]
  setSelectedCompanies: (selectedCompanies: IPortfolioCompany[]) => void
}) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const { companyListData, refetchPortfolioCompaniesList } = usePortfolioListContext()
  const companyListSlug = companyListData.slug

  const selected = !!selectedCompanies.find((c) => c.uuid === company.uuid)

  const handleSelection = (company: IPortfolioCompany, selected: boolean) => {
    if (selected) {
      setSelectedCompanies([...selectedCompanies, company])
    } else {
      setSelectedCompanies(selectedCompanies.filter((a) => a.uuid !== company.uuid))
    }
  }

  const { mutate: exportCompanyData, isLoading: isExporting } = useMutation(
    () => callApi(api.exportCompanyData, company.uuid),
    {
      onSuccess: () => {
        cabalToast({
          style: 'success',
          content: 'Company data export initiated. Check your email for the file.',
        })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to export company data.' })
      },
    },
  )
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  const { mutate: deleteInvestorCompany, isLoading: isDeleting } = useMutation(
    ['deleteInvestorCompany', company.investor_company_uuid],
    () => callApi(api.deleteInvestorCompany, company.investor_company_uuid!, teamSlug),
    {
      onSuccess: () => {
        sleep(1000).then(() => {
          refetchPortfolioCompaniesList()
          cabalToast({ style: 'success', content: 'Company successfully deleted' })
        })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to delete company' })
      },
    },
  )

  const menuItems = [
    // {
    //   label: 'Export',
    //   onSelect: () => exportCompanyData(),
    // },
    {
      label: 'Edit',
      onSelect: () =>
        showModal(
          (resolve) => (
            <PortfolioCompanyEditOrAddModal
              onHide={resolve}
              investor_company_uuids={company.investor_company_uuid!}
              teamSlug={teamSlug}
              refetchPortfolioList={refetchPortfolioCompaniesList}
              listUuid={companyListData.uuid}
            />
          ),
          'edit-portfolio-company-modal',
        ),
    },
    {
      label: 'Delete',
      onSelect: () => deleteInvestorCompany(),
    },
  ]

  const gridView = view === 'grid'

  return (
    <DataCardV2
      onClick={() => history.push(`/${teamSlug}/lists/${companyListSlug}/${company.uuid}`)}
      key={company.uuid}
      title={company.name}
      avatar={
        <Avatar
          src={company.logo_url ? company?.logo_url : `https://logo.clearbit.com/${company.domain}`}
          name={company.name}
          size={gridView ? '72' : '32'}
        />
      }
      checkbox={{
        checked: selected,
        onChange: (e) => {
          handleSelection(company, e.target.checked)
        },
      }}
      description={company.description}
      descriptionBottom={
        <div className="flex flex-col items-start gap-2.5 mb-2">
          <Typography lineHeight={1}>
            <i className="far fa-users" /> {company?.domain_matching_member_count || 0} Members
          </Typography>

          <Typography lineHeight={1}>
            <i className="far fa-list" /> {company?.shared_list_count || 0} Shared Lists
          </Typography>
        </div>
      }
      dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      cta={
        <CabalButton
          onClick={() => history.push(`/${teamSlug}/lists/${companyListSlug}/${company.uuid}`)}
          variant={gridView ? 'primary' : 'link'}
          working={isDeleting}
          className="w-full"
        >
          {isDeleting ? 'Deleting' : 'View'}
        </CabalButton>
      }
      view={view}
      gridPillsSection={<PortfolioDataTags company={company} />}
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          !gridView,
      })}
    />
  )
}

interface Props {
  selectedCompanies: IPortfolioCompany[]
  setSelectedCompanies: (selectedCompanies: IPortfolioCompany[]) => void
}

const PortfolioListAdminView: React.FC<Props> = ({ selectedCompanies, setSelectedCompanies }) => {
  const { portfolioCompaniesList, view } = usePortfolioListContext()

  const companyRow = (view: 'grid' | 'list') => {
    return (
      <>
        {portfolioCompaniesList.map((company) => (
          <PortfolioItem
            key={company.uuid}
            company={company}
            view={view}
            selectedCompanies={selectedCompanies}
            setSelectedCompanies={setSelectedCompanies}
          />
        ))}
      </>
    )
  }

  return (
    <div>
      {view === 'grid' && <GridWrapper>{companyRow('grid')}</GridWrapper>}
      {view === 'list' && <>{companyRow('list')}</>}
    </div>
  )
}

export default PortfolioListAdminView
