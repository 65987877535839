import * as React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { cabalToast } from 'ui-components/Toast'
import { useMutation, useQuery } from 'react-query'

import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import api, { callApi } from 'utils/api'
import { TeamSettingProps } from '..'
import Loading from 'global/Loading'
import { ModalContext } from 'global/Modal/Context'
import AddAgreementTemplateModal from './AddAgreementTemplateModal'
import Tooltip from 'global/Tooltip'

const Border = styled.div`
  ${tw`p-4`}
  border: ${({ theme }) => theme.border};
`

const AgreementTemplates: React.VFC<TeamSettingProps> = ({ team }) => {
  const history = useHistory()

  const { showModal } = React.useContext(ModalContext)

  const {
    data,
    refetch: refetchTemplates,
    isFetching,
  } = useQuery(['getAgreementTemplates', team.slug], () =>
    callApi(api.getAgreementTemplates, team.slug),
  )
  const { agreement_templates: agreementTemplates } = data || {}

  const { mutate: deleteTemplate, isLoading: deletingTemplate } = useMutation(
    (id: string) => callApi(api.deleteAgreementTemplate, team.slug, id),
    {
      onSuccess: () => {
        refetchTemplates().finally(() => {
          cabalToast({ style: 'success', content: 'Successfully deleted the template!' })
        })
      },
    },
  )

  return (
    <>
      {isFetching && <Loading />}

      <div className="mt-4 mb-2">
        <CabalButton
          className="mb-4"
          variant="secondary"
          onClick={() =>
            showModal(
              (resolve) => (
                <AddAgreementTemplateModal
                  companySlug={team.slug}
                  resolve={resolve}
                  fetchTemplates={refetchTemplates}
                />
              ),
              'add_agreement_template',
            )
          }
        >
          Add Agreement Template
        </CabalButton>


        {agreementTemplates
          ?.sort((a, b) => {
            return a.created_at < b.created_at ? (a.default ? -2 : -1) : 1
          })
          ?.map((agreementTemplate) => {
            return (
              <div className="flow-root mb-2 pb-2" key={agreementTemplate.id}>
                <Border>
                  <div className="flex justify-between items-center">
                    <Typography color="purple" fontSize="14">
                      <i className="far fa-paperclip"></i>
                      <a
                        href={`/api/agreement_templates/${agreementTemplate.id}.pdf`}
                        className="underline cursor-pointer mx-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {agreementTemplate.name ||
                          (agreementTemplate.file?.length > 40
                            ? `${agreementTemplate.file?.substring(0, 40)}...`
                            : agreementTemplate.file)}
                      </a>
                    </Typography>
                    <div>
                      {!agreementTemplate.default ? (
                        <CabalButton
                          onClick={() =>
                            history.push(`/${team?.slug}/prepare/${agreementTemplate.id}`)
                          }
                        >
                          Edit
                        </CabalButton>
                      ) : (
                        <Typography color="fog">(default)</Typography>
                      )}
                      {window.SUPERADMIN && agreementTemplate.default && (
                        <Tooltip label="visible to super admins only">
                          <CabalButton
                            className="ml-2"
                            variant="destructive"
                            onClick={() =>
                              history.push(
                                `/${team?.slug}/prepare/${agreementTemplate.id}?edit_default=1`,
                              )
                            }
                          >
                            Edit Default
                          </CabalButton>
                        </Tooltip>
                      )}
                      {!agreementTemplate.default && (
                        <CabalButton
                          variant="tertiary"
                          onClick={() =>
                            confirm(`Are you sure you want to delete this agreement template?`) &&
                            deleteTemplate(agreementTemplate.id)
                          }
                          className="ml-3"
                          working={deletingTemplate}
                        >
                          <i className="fas fa-trash" />
                        </CabalButton>
                      )}
                    </div>
                  </div>
                </Border>
              </div>
            )
          })}

      </div>
    </>
  )
}

export default AgreementTemplates
