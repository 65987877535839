export interface TagOption {
  title: string
  icon: string
}

// Keep in the alphabetical order
export const TagOptions: TagOption[] = [
  { title: 'Activity', icon: 'wave-pulse' },
  { title: 'Connections', icon: 'arrows-left-right' },
  { title: 'Content', icon: 'folder-grid' },
  { title: 'Discounts', icon: 'percent' },
  { title: 'Discussions', icon: 'comments' },
  { title: 'Feedback', icon: 'pen-line' },
  { title: 'Fundraise', icon: 'money-check-dollar-pen' },
  { title: 'Intros', icon: 'hand-wave' },
  { title: 'Mail', icon: 'envelope' },
  { title: 'People', icon: 'people-group' },
  { title: 'Portfolio', icon: 'folder-open' },
  { title: 'Product', icon: 'screwdriver-wrench' },
  { title: 'Recruiting', icon: 'handshake' },
  { title: 'Resources', icon: 'toolbox' },
  { title: 'Sales', icon: 'chart-mixed-up-circle-dollar' },
  { title: 'Social', icon: 'hashtag' },
  { title: 'Updates', icon: 'newspaper' },
  { title: 'Vendors', icon: 'shop' },
]
