import React from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import Loading from 'global/Loading'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { Team } from 'utils/types'
import { formatCompact } from 'utils/ui'

const StyledMetricsCard = styled.div`
  ${tw`my-2 p-2 rounded-lg gap-2 shadow-sm hover:shadow-md`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.pill.border};
  &:hover .title-hover {
    color: ${({ theme }) => theme.colors.purple};
  }
`
interface Props {
  title: string
  icon?: React.ReactNode
  metric: number
  positiveChange: boolean
  changeMetric: number
  teamSlug: string
  linkTo?: string
}

const MetricsCard: React.FC<Props> = ({ title, icon, metric, changeMetric, teamSlug, linkTo }) => {
  const history = useHistory()

  return (
    <StyledMetricsCard
      className="flex flex-col justify-between px-4 py-2.5 rounded-md cursor-pointer group"
      onClick={() => history.push(linkTo)}
    >
      <Typography fontSize="14" color="fog" className="title-hover">
        {icon}
        {title}
      </Typography>
      <Typography
        fontSize="28"
        fontWeight={500}
        data-testid={`metrics-card-${title.toLowerCase()}-number`}
      >
        {formatCompact(metric, 2)}
      </Typography>
      <div className="flex justify-between">
        <div className="flex gap-1">
          {changeMetric > 0 ? (
            <Typography fontSize="14" color="green">
              <i className="fal fa-arrow-up-right" />
            </Typography>
          ) : changeMetric < 0 ? (
            <Typography fontSize="14" color="red">
              <i className="fal fa-arrow-down-right" />
            </Typography>
          ) : null}
          <Typography fontSize="14" color={changeMetric === 0 ? 'transparent' : 'fog'}>
            {formatCompact(changeMetric)}
          </Typography>
        </div>
      </div>
    </StyledMetricsCard>
  )
}

const Metrics = ({ team }: { team: Team }) => {
  const {
    data: getCompanyMetricsData,
    isFetching: isFetchingMetrics,
    isLoading: isLoadingMetrics,
  } = useQuery(['getCompanyMetrics', team.slug], () => callApi(api.getCompanyMetrics, team.slug))

  if (isFetchingMetrics || isLoadingMetrics) {
    return <Loading size={25} />
  }

  return (
    <Widget title="Metrics">
      <div className="grid grid-cols-4 gap-2 h-[152px] mb-4">
        <MetricsCard
          icon={<i className="mr-1.5 far fa-users" />}
          title={'Members'}
          metric={getCompanyMetricsData.metrics?.all_members}
          positiveChange={true}
          changeMetric={getCompanyMetricsData.metrics?.new_members}
          teamSlug={team.slug}
          linkTo={`/${team.slug}/members`}
        />
        <MetricsCard
          icon={<i className="mr-1.5 far fa-arrows-left-right" />}
          title={'Connections'}
          metric={getCompanyMetricsData.metrics?.all_connections}
          positiveChange={false}
          changeMetric={getCompanyMetricsData.metrics?.new_connections}
          teamSlug={team.slug}
          linkTo={`/${team.slug}/lists/connections`}
        />
        <MetricsCard
          icon={<i className="mr-1.5 far fa-radar" />}
          title={'Matches'}
          metric={getCompanyMetricsData.metrics?.all_matches}
          positiveChange={true}
          changeMetric={getCompanyMetricsData.metrics?.new_matches}
          teamSlug={team.slug}
          linkTo={`/${team.slug}/lists/companies`}
        />
        <MetricsCard
          icon={<i className="mr-1.5 far fa-thumbs-up" />}
          title={'Contributions'}
          metric={getCompanyMetricsData.metrics?.all_contributions}
          positiveChange={false}
          changeMetric={getCompanyMetricsData.metrics?.new_contributions}
          teamSlug={team.slug}
          linkTo={`/${team.slug}/intro-center`}
        />
      </div>
    </Widget>
  )
}

export default Metrics
