import React from 'react'

import pluralize from 'pluralize'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { ISalesCompany, ISalesPeople } from 'containers/ListIndexView/SalesList/type'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import company from 'utils/api/company'
import { cn } from 'utils/styles'

const SalesCompany = ({
  person,
  selectedPeople,
  setSelectedPeople,
}: {
  person: ISalesPeople
  selectedPeople: ISalesPeople[]
  setSelectedPeople: (selectedPeople: ISalesPeople[]) => void
}) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()

  const { companyListData } = useSalesListContext()
  const companyListSlug = companyListData.slug

  const selected = !!selectedPeople.find((p) => p.uuid === person.uuid)

  const handleSelection = (person: ISalesPeople, selected: boolean) => {
    if (selected) {
      setSelectedPeople([...selectedPeople, person])
    } else {
      setSelectedPeople(selectedPeople.filter((a) => a.uuid !== person.uuid))
    }
  }

  const menuItems = [
    {
      label: 'Export',
      onSelect: () => console.log('Export'),
    },
    {
      label: 'Edit',
      onSelect: () => console.log('Edit'),
    },
    {
      label: 'Delete',
      onSelect: () => console.log('Delete'),
    },
  ]

  return (
    <DataCardV2
      onClick={() => history.push(`/${teamSlug}/lists/${companyListSlug}/${person.uuid}`)}
      key={person.uuid}
      title={person.name}
      description={person.description}
      avatar={<Avatar src={person.avatar_url} name={person.name} size={'32'} />}
      titleIcon={
        person.linkedin_url && (
          <Typography
            color="fog"
            fontSize="14"
            lineHeight={1}
            onClick={(e) => {
              e.stopPropagation()
              window.open(person.linkedin_url, '_blank')
            }}
          >
            <i className="fab fa-linkedin" />
          </Typography>
        )
      }
      checkbox={{
        checked: selected,
        onChange: (e) => {
          handleSelection(person, e.target.checked)
        },
      }}
      dropDownMenu={menuItems.length > 0 ? { menuItems: menuItems } : undefined}
      cta={
        <CabalButton
          onClick={() => history.push(`/${teamSlug}/lists/${companyListSlug}/${person.uuid}`)}
          variant={'link'}
          working={false}
          className="w-full"
        >
          {'Request Intro'}
        </CabalButton>
      }
      className={cn(
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded',
      )}
    />
  )
}

interface Props {
  selectedPeople: ISalesPeople[]
  setSelectedPeople: (selectedPeople: ISalesPeople[]) => void
}

const SalesPeopleAdminView = ({ selectedPeople, setSelectedPeople }: Props) => {
  const { salesPeople } = useSalesListContext()
  console.log('salesPeople', salesPeople)
  return (
    <div>
      {salesPeople.map((salesPerson) => (
        <SalesCompany
          key={salesPerson.uuid}
          person={salesPerson}
          selectedPeople={selectedPeople}
          setSelectedPeople={setSelectedPeople}
        />
      ))}
    </div>
  )
}

export default SalesPeopleAdminView
