import React from 'react'

import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import { IResourceCompany } from 'containers/ListIndexView/ResourcesList/types'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'

import { cn } from 'utils/styles'

const ResourceItem = ({ item, view }: { item: IResourceCompany; view: 'grid' | 'list' }) => {
  const gridView = view === 'grid'
  const { companyListData } = useResourcesListContext()
  const { showModal } = useModal()

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={item}
          companyList={companyListData}
          resolve={resolve}
          gatekeeperId={companyListData?.gatekeeper_id}
          facilitator={companyListData?.owning_team}
          includeInitialNote={false}
          requestType={'external'}
        />
      ),
      'facilitate-intro-modal',
    )
  }

  return (
    <DataCardV2
      title={item.name}
      avatar={<Avatar src={item.logo_url} name={item.name} size={gridView ? '72' : '32'} />}
      description={item.description}
      cta={
        <CabalButton
          onClick={() => openRequestIntroModal()}
          variant={gridView ? 'primary' : 'link'}
          className="w-full"
        >
          {'Request Intro'}
        </CabalButton>
      }
      view={view}
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          !gridView,
      })}
    />
  )
}

const ResourceCompanyMemberView = () => {
  const { view, resourceCompany } = useResourcesListContext()

  const itemRow = (view: 'grid' | 'list') => {
    return (
      <>
        {resourceCompany.map((company) => (
          <ResourceItem key={company.uuid} item={company} view={view} />
        ))}
      </>
    )
  }

  return (
    <div>
      {view === 'grid' && <GridWrapper>{itemRow('grid')}</GridWrapper>}
      {view === 'list' && <>{itemRow('list')}</>}
    </div>
  )
}

export default ResourceCompanyMemberView
