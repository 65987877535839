import axios from 'axios'

import {
  CompanyListAccessorBlueprint,
  CompanyListBlueprint,
  GetCompanyHomeResponse,
  GetTalentListResponse,
  RequestIntroArgs,
  Team,
  UserBlueprint,
} from 'utils/types'

export default {
  getCompanyLists: (
    team_slug?: string,
    include_items = false,
    published_only = false,
    current_slug?: string,
    memberUuid?: string,
    params: any = {},
  ) => {
    return axios.get<{ company_lists: CompanyListBlueprint[] }>(`/api/company_lists`, {
      params: {
        team_slug,
        include_items,
        published_only,
        current_slug,
        member_uuid: memberUuid,
        ...params,
      },
    })
  },

  getRequestIntroOptions: async (teamSlug: string) => {
    return await axios.get<RequestIntroArgs>('/api/v2/company_lists/request_intro_options', {
      params: { team_slug: teamSlug },
    })
  },

  addCompanyToList: (list_uuid: string, company_uuid: string) => {
    return axios.post(`/api/company_lists/${list_uuid}/add_company`, { company_uuid: company_uuid })
  },

  getCompanyListAccess: (team_slug: string, uuid: string) => {
    return axios.get<CompanyListBlueprint>(`/api/company_lists/${uuid}/access`, {
      params: { team_slug },
    })
  },

  updateCompanyListAccess: (team_slug: string, params?: any) => {
    return axios.put(`/api/company_lists/${params.uuid}/update_access`, {
      company_list: { ...params },
      team_slug,
    })
  },

  addListAccessors: (
    team_slug: string,
    listUuid: string,
    params: {
      message: string
      notify: boolean
      accessors: (CompanyListAccessorBlueprint | string)[]
    },
  ) => {
    return axios.post(`/api/company_lists/${listUuid}/add_accessors`, {
      ...params,
      team_slug,
    })
  },

  getCompanyList: (
    team_slug: string,
    uuid: string,
    include_items_for_any_list = true,
    include_items_for_manual_list = false,
  ) => {
    return axios.get<{ company_list: CompanyListBlueprint; indexed: boolean }>(
      `/api/company_lists/${uuid}`,
      {
        params: {
          team_slug,
          include_items: include_items_for_any_list,
          include_items_for_manual_list,
        },
      },
    )
  },

  getCompanyListV2: (id: string) => {
    return axios.get<{ company_list: CompanyListBlueprint; indexed: boolean }>(
      `/api/v2/company_lists/${id}`,
    )
  },

  sendCandidatesInvite: (
    list_uuid: string,
    all_candidates_list_uuids: string[],
    owner_uuid: string,
    emails: string[],

    steps: {
      welcome: boolean
      connections: boolean
      personal_info: boolean
      talent_profile: boolean
    },
    note?: string,
  ) => {
    return axios.post(`/api/company_lists/${list_uuid}/candidates_invite`, {
      all_candidates_list_uuids,
      note,
      owner_uuid,
      emails,
      steps,
    })
  },

  copyCandidatesInviteLink: (
    list_uuid: string,
    all_candidates_list_uuids: string[],
    owner_uuid: string,
    emails: string[],
    steps: {
      welcome: boolean
      connections: boolean
      personal_info: boolean
      talent_profile: boolean
    },
    note?: string,
    team_slug?: string,
    with_domain = true,
  ) => {
    return axios.post(`/api/company_lists/${list_uuid}/candidates_invite_link`, {
      all_candidates_list_uuids,
      note,
      owner_uuid,
      emails,
      steps,
      team_slug,
      with_domain,
    })
  },

  getCompanyListFilterOptions: (team_slug?: string) => {
    return axios.get<{ filter_options: any }>(`/api/company_lists/filter_options`, {
      params: { team_slug: team_slug },
    })
  },

  addCompanyList: (team_slug: string, params?: any) => {
    return axios.post<{ company_list: CompanyListBlueprint }>(`/api/company_lists`, {
      ...params,
      team_slug,
    })
  },

  updateCompanyList: (team_slug: string, params?: any) => {
    return axios.put(`/api/company_lists/${params.company_list.uuid}`, {
      ...params,
      team_slug,
    })
  },

  deleteCompanyList: (team_slug: string, uuid: string) => {
    return axios.delete(`/api/company_lists/${uuid}`)
  },

  removeCompaniesFromList: (team_slug: string, uuid: string, company_ids: any) => {
    const params = {
      team_slug,
      company_ids,
    }
    return axios.post(`/api/company_lists/${uuid}/remove_items`, params)
  },

  removeListItems: (team_slug: string, listUuid: string, listItemUuids: string[]) => {
    const params = {
      team_slug,
      list_item_uuids: listItemUuids,
    }
    return axios.post(`/api/company_lists/${listUuid}/remove_items`, params)
  },

  exportList: (uuid: string, filters?: Record<string, unknown>, withConnections = false) => {
    return axios.post(`/api/company_lists/${uuid}/export`, {
      filters: filters,
      with_connections: withConnections,
    })
  },

  getHomeLists: (team_slug: string, list_slug?: string, talentFilters?: any, filters?: any) => {
    return axios.get<GetCompanyHomeResponse>(`/api/companies/home`, {
      params: { team_slug, list_slug, talent_filters: talentFilters, filters: filters },
    })
  },

  getTalentLists: (team_slug: string) => {
    return axios.get<GetTalentListResponse>(`/api/company_lists?is_talent=true`, {
      params: { team_slug },
    })
  },

  getListInvite: (uuid: string) => {
    return axios.get<{ list: CompanyListBlueprint; team: Team; user: UserBlueprint; uuid: string }>(
      `/api/list_invites/${uuid}`,
    )
  },

  acceptListInvite: (team_slug: string, uuid: string) => {
    return axios.put(`/api/list_invites/${uuid}`, { params: { team_slug } })
  },

  createListItem: (params: { team: string; list_uuid: string; list_item: any }) => {
    return axios.post('/api/company_list_items', params)
  },
}
