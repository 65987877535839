import React from 'react'

import Pill from 'global/Pill'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

interface Props {
  location?: string
  office?: string
  category?: string[]
  tags?: string[]
}

const DetailPageDetailsSection = ({ location, office, category, tags }: Props) => {
  return (
    <Widget title="Details">
      <div className="flex gap-6 mt-2">
        {location && (
          <div>
            <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
              Location
            </Typography>

            <Typography fontSize="12" component="div" color="fog" className="flex gap-1 flex-wrap">
              <Pill variant="gray" rounded>
                {location}
              </Pill>
            </Typography>
          </div>
        )}
        {office && (
          <div>
            <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
              Office Location
            </Typography>
            <Typography fontSize="12" component="div" color="fog" className="flex gap-1 flex-wrap">
              <Pill variant="gray" rounded>
                {office.charAt(0).toUpperCase() + office.slice(1)}
              </Pill>
            </Typography>
          </div>
        )}
        {category && category.length > 0 && (
          <div className="mt-4">
            <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
              Category
            </Typography>
            <Typography fontSize="12" component="div" color="fog" className="flex gap-1 flex-wrap">
              {category.map((tag, i) => (
                <Pill variant="gray" rounded key={i}>
                  {tag}
                </Pill>
              ))}
            </Typography>
          </div>
        )}
      </div>
      <div>
        {tags && tags.length > 0 && (
          <div className="mt-4">
            <Typography fontSize="14" component="div" fontWeight={600} className="mb-1">
              Tags
            </Typography>
            <Typography fontSize="12" component="div" color="fog" className="flex gap-1 flex-wrap">
              {tags.map((tag, i) => (
                <Pill variant="gray" rounded key={i}>
                  {tag}
                </Pill>
              ))}
            </Typography>
          </div>
        )}
      </div>
    </Widget>
  )
}

export default DetailPageDetailsSection
