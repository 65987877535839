import React, { useState } from 'react'

import styled from 'styled-components'

import { WidgetTab } from 'containers/AddCandidate'
import CabalButton from 'global/CabalButton'
import Widget from 'ui-components/Widget'

interface ITab {
  key: string
  label: string
  iconClass: string
  component: React.ReactNode
}

interface Props {
  onClose: () => void
  tabs: ITab[]
  title: string
}

const TabWrapper = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

const AddWidget: React.FC<Props> = ({ onClose, tabs, title }) => {
  const [tab, setTab] = useState<string>(tabs[0].key)

  return (
    <Widget
      title={title}
      cta={
        onClose && (
          <CabalButton
            variant="tertiary"
            onClick={(e) => {
              e.preventDefault()
              onClose()
            }}
            padding={'0'}
            leftIcon={<i className="far fa-times" />}
          ></CabalButton>
        )
      }
      className="mb-4"
    >
      <TabWrapper className="flex gap-8 mt-6">
        {tabs.map((tabConfig) => (
          <WidgetTab
            key={tabConfig.key}
            active={tab === tabConfig.key}
            onClick={() => setTab(tabConfig.key)}
          >
            <i className={`${tabConfig.iconClass} mr-1 fa-sm`} /> {tabConfig.label}
          </WidgetTab>
        ))}
      </TabWrapper>

      {tabs.map((tabConfig) => tab === tabConfig.key && tabConfig.component)}
    </Widget>
  )
}

export default AddWidget
