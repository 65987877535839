import React, { useState } from 'react'
import { useMutation, UseMutationResult, useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { History } from 'history'
import { useAdvisors, useCurrentUser, useTeam } from 'store/hooks'
import api, { callApi } from 'utils/api'
import {
  AdvisorModel,
  AgreementSend,
  AgreementTemplateModel,
  CompanySlugParam,
  CreateAgreementsRequest,
  CurrentUserProfile,
  LimitedUser,
  Params,
  Team,
} from 'utils/types'
import AgreementFields from './AgreementFields'
import Compose from './Compose'
import TeamUserFields from './TeamUserFields'
import Loading from 'global/Loading'
import Banner from 'global/Banner'
import Typography from 'global/Typography'
import { uniqBy } from 'lodash'
import CabalTitle from 'global/Title'
import { cabalToast } from 'ui-components/Toast'
import PageWrapper from 'global/PageWrapper'

type CreateAgreementParam = {
  advisors: AdvisorModel[]
  params: CreateAgreementsRequest
  currentUserSending: boolean
}

interface Props {
  history: History
  user: CurrentUserProfile
  team: Team
  allAdvisors: AdvisorModel[]
  advisorUuids?: string[]
  createAgreementsMutation: UseMutationResult<unknown, unknown, CreateAgreementParam>
  stage?: number
  params?: CreateAgreementsRequest
  agreementTemplates: AgreementTemplateModel[]
  agreementSend?: AgreementSend
  creating: boolean
}

export interface SendAgreementsState {
  agreementParams: Params
  senderParams: Params
  advisorParams: Params<Params>
  agreementTemplateId?: string
  messageParams: Params
  senderUuid: string
  advisors: AdvisorModel[]
}

class SendAgreementClass extends React.Component<Props, SendAgreementsState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      agreementParams: {},
      senderParams: {},
      advisorParams: {},
      messageParams: {},
      senderUuid: props.user.uuid,
      advisors: [],
    }

    const { params } = props
    const searchParams = new URLSearchParams(location.search)

    let agreementTemplateId = params?.agreement_template_id
    if (!params?.agreement_template_id && searchParams.has('agreement_template_key')) {
      agreementTemplateId = props.agreementTemplates.find(
        (at) => at.default && at.key === searchParams.get('agreement_template_key'),
      )?.id
    }

    if (params) {
      this.state = {
        ...this.state,
        agreementParams: params.agreement_params,
        advisorParams: params.advisor_params,
        senderParams: params.sender_params,
        messageParams: params.message_params,
        senderUuid: params.sender_uuid,
      }
    }

    this.state = {
      ...this.state,
      agreementTemplateId,
    }
  }

  get currentUserSending() {
    return this.state.senderUuid === this.props.user.uuid
  }

  get agreementTemplate() {
    return this.props.agreementTemplates.find((at) => at.id === this.state.agreementTemplateId)
  }

  get advisors() {
    let { advisors } = this.state
    let { advisorUuids } = this.props
    const { allAdvisors, params } = this.props

    if (params) {
      advisorUuids = params.advisor_uuids
    }

    if (this.state.advisors.length === 0 && advisorUuids) {
      advisors = allAdvisors.filter((a) => advisorUuids?.includes(a.uuid))
      this.setState({ advisors })
    }

    return advisors
  }

  get sender() {
    const { team } = this.props

    return team.admins_and_members.find((f) => f.uuid === this.state.senderUuid)!
  }

  advisorFields = (unique = true) => {
    const fields =
      this.agreementTemplate?.fields.filter(
        (f) => f.default_value?.startsWith('advisor.') && f.for === 'sender',
      ) || []

    if (!unique) return fields

    return uniqBy(fields, 'shortcode')
  }

  senderFields = (unique = true) => {
    const fields =
      this.agreementTemplate?.fields.filter(
        (f) =>
          f.for === 'sender' &&
          !f.default_value?.startsWith('agreement.') &&
          (f.default_value?.startsWith('sender.') || f.default_value?.startsWith('team.')),
      ) || []

    if (!unique) return fields

    return uniqBy(fields, 'shortcode')
  }

  agreementFields = (unique = true) => {
    const fields =
      this.agreementTemplate?.fields.filter(
        (f) =>
          f.for === 'sender' && (f.default_value?.startsWith('agreement.') || !f.default_value),
      ) || []

    if (!unique) return fields

    return uniqBy(fields, 'shortcode')
  }

  createAgreements = async () => {
    const {
      team: { slug },
      agreementSend,
    } = this.props
    const {
      agreementParams: _agreementParams,
      advisorParams: _advisorParams,
      senderParams: _senderParams,
      messageParams,
      senderUuid,
      agreementTemplateId,
    } = this.state

    const agreementParams = { ..._agreementParams }
    const agreementFields = this.agreementFields(false)
    for (const f of agreementFields) {
      const valueOfShortcode = agreementFields
        .filter((atf) => f.shortcode === atf.shortcode)
        .map((atf) => agreementParams[atf.uuid])
        .find((v) => v !== undefined)
      agreementParams[f.uuid] = valueOfShortcode
    }

    const senderParams = { ..._senderParams }
    const senderFields = this.senderFields(false)
    for (const f of senderFields) {
      const valueOfShortcode = senderFields
        .filter((atf) => f.shortcode === atf.shortcode)
        .map((atf) => senderParams[atf.uuid])
        .find((v) => v !== undefined)
      senderParams[f.uuid] = valueOfShortcode
    }

    const advisorParams = { ..._advisorParams }
    const advisorFields = this.advisorFields(false)
    for (const auid in advisorParams) {
      for (const f of advisorFields) {
        const valueOfShortcode = advisorFields
          .filter((atf) => f.shortcode === atf.shortcode)
          .map((atf) => advisorParams[auid][atf.uuid])
          .find((v) => v !== undefined)
        advisorParams[auid][f.uuid] = valueOfShortcode
      }
    }

    await this.props.createAgreementsMutation.mutateAsync({
      advisors: this.advisors,
      params: {
        team_slug: slug,
        agreement_params: agreementParams,
        advisor_params: advisorParams,
        sender_params: senderParams,
        message_params: messageParams,
        agreement_template_id: agreementTemplateId!,
        sender_uuid: senderUuid,
        advisor_uuids: this.advisors.map((a) => a.uuid),
        agreement_send_uuid: agreementSend?.uuid,
      },
      currentUserSending: this.currentUserSending,
    })
  }

  initParams = () => {
    const { params, team, user } = this.props
    const { agreementTemplate } = this
    const { advisorParams, senderParams, senderUuid } = this.state
    const sender =
      team.admins_and_members.find(
        (f) => f.uuid === params?.sender_uuid || f.uuid === senderUuid,
      ) || user

    if (!agreementTemplate) return

    const updatedAdvisorParams: Params = { ...advisorParams }

    for (const a of this.advisors) {
      updatedAdvisorParams[a.uuid] = {}
      for (const f of this.advisorFields()) {
        if (updatedAdvisorParams[a.uuid]?.[f.uuid]) continue
        updatedAdvisorParams[a.uuid] = {
          ...(updatedAdvisorParams[a.uuid] || {}),
          [f.uuid]: a[f.default_value!.split('.')[1]! as keyof AdvisorModel],
        }
      }
    }

    const updatedSenderParams: Params = { ...senderParams }
    if (this.currentUserSending) {
      for (const f of this.senderFields()) {
        if (updatedSenderParams[f.uuid]) continue
        if (f.default_value?.startsWith('sender.')) {
          updatedSenderParams[f.uuid] = sender[f.default_value.split('.')[1]! as keyof LimitedUser]
        } else if (f.default_value?.startsWith('team.')) {
          updatedSenderParams[f.uuid] = team[f.default_value.split('.')[1]! as keyof Team]
        } else {
          updatedSenderParams[f.uuid] = undefined
        }
      }
    }

    this.setState({
      advisorParams: updatedAdvisorParams,
      senderParams: updatedSenderParams,
    })
  }

  onChangeTemplate = (t: AgreementTemplateModel) => {
    this.setState(
      {
        agreementTemplateId: t.id,
        advisorParams: {},
        agreementParams: {},
        senderParams: {},
      },
      this.initParams,
    )
  }

  setParams = (state: Partial<SendAgreementsState>, callback?: () => void) => {
    if (state.senderUuid !== this.state.senderUuid) {
      this.initParams()
    }
    this.setState(state as any, callback)
  }

  componentDidMount() {
    this.initParams()
  }

  render = () => {
    const { agreementParams, advisorParams, senderParams, senderUuid, messageParams } = this.state
    const {
      team: { slug: teamSlug },
      stage,
      allAdvisors,
      advisorUuids,
      agreementTemplates,
    } = this.props

    if (stage === 0 || !stage)
      return (
        <AgreementFields
          allAdvisors={allAdvisors}
          agreementTemplate={this.agreementTemplate}
          showAdvisorSelector={!advisorUuids}
          teamSlug={teamSlug}
          agreementParams={agreementParams}
          setState={this.setParams}
          senderUuid={senderUuid}
          advisors={this.advisors}
          onChangeTemplate={this.onChangeTemplate}
          agreementTemplates={agreementTemplates}
          agreementFields={this.agreementFields()}
        />
      )
    if (stage === 1 && this.agreementTemplate)
      return (
        <TeamUserFields
          teamSlug={teamSlug}
          advisors={this.advisors}
          advisorParams={advisorParams}
          setState={this.setParams}
          senderParams={senderParams}
          currentUserSending={this.currentUserSending}
          advisorFields={this.advisorFields()}
          senderFields={this.senderFields()}
        />
      )
    if (stage === 2 && this.agreementTemplate)
      return (
        <Compose
          teamSlug={teamSlug}
          agreementTemplate={this.agreementTemplate}
          advisors={this.advisors}
          agreementParams={agreementParams}
          senderParams={senderParams}
          advisorParams={advisorParams}
          setState={this.setParams}
          currentUserSending={this.currentUserSending}
          createAgreements={this.createAgreements}
          creatingAgreements={this.props.creating}
          sender={this.sender}
          messageParams={messageParams}
        />
      )

    return <></>
  }
}

const SendAgreement: React.VFC = () => {
  const location = useLocation<
    | {
        advisor_uuids?: string[]
        stage?: number
      }
    | undefined
  >()
  const history = useHistory()
  const { company_slug: companySlug, id: agreementSendUuid } = useParams<
    CompanySlugParam & {
      id?: string
    }
  >()
  const [creating, setCreating] = useState(false)

  const { user } = useCurrentUser()
  const { team } = useTeam(companySlug)

  const { advisors: allAdvisors, reloadAdvisors } = useAdvisors({ teamSlug: companySlug })

  const createAgreementsMutation = useMutation(
    (props: CreateAgreementParam) => callApi(api.createBulkAgreement, props.params),
    {
      onMutate: () => {
        setCreating(true)
      },
    },
  )

  useQuery(
    ['agreementCreateStatus'],
    () => {
      return callApi(api.bulkAgreementStatus, createAgreementsMutation.data!.job_id)
    },
    {
      enabled: !!createAgreementsMutation.data,
      refetchInterval: 2000,
      onSuccess: (data) => {
        if (data.status === 'complete') {
          setCreating(false)
          cabalToast({
            content: `Successfully ${
              createAgreementsMutation.variables?.currentUserSending ? 'sent' : 'created'
            } the agreement(s)`,
            style: 'success',
          })
          history.push(
            createAgreementsMutation.variables?.advisors.length === 1
              ? `/${companySlug}/people/${createAgreementsMutation.variables?.advisors[0].uuid}/agreements`
              : `/${companySlug}/people`,
          )
          reloadAdvisors(createAgreementsMutation.variables?.advisors.map((a) => a.uuid))
        } else if (data.status === 'failed') {
          setCreating(false)
          createAgreementsMutation.reset()
          cabalToast({
            style: 'error',
            content: 'Could not send agreements. Please contact support',
          })
          window.Intercom('show')
        }
      },
    },
  )

  const { data: agreementSend } = useQuery(
    ['getAgreementSend', agreementSendUuid],
    () => callApi(api.getAgreementSend, agreementSendUuid!, companySlug),
    { enabled: !!agreementSendUuid },
  )

  const { data: agreementTemplatesResponse } = useQuery([companySlug, 'agreementTemplates'], () =>
    callApi(api.getAgreementTemplates, companySlug),
  )

  if (!team || !allAdvisors || (agreementSendUuid && !agreementSend) || !agreementTemplatesResponse)
    return <Loading className="my-6" />

  return (
    <PageWrapper title="Send Agreements">
      {agreementSend && (
        <Banner className="p-2 mx-auto mt-6 w-2/5">
          <Typography
            color="white"
            fontSize="13"
            textAlign="center"
            component="div"
            className="flex-1"
          >
            {agreementSend.creator_user.name} created this agreement on your behalf
          </Typography>
        </Banner>
      )}
      <SendAgreementClass
        {...{
          history,
          user,
          team,
          allAdvisors,
          advisorUuids: location.state?.advisor_uuids,
          createAgreementsMutation,
          stage: location.state?.stage,
          params: agreementSend?.params,
          agreementTemplates: agreementTemplatesResponse.agreement_templates,
          agreementSend,
          creating: creating,
        }}
      />
    </PageWrapper>
  )
}

export default SendAgreement
