import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useQuery } from 'react-query'
import { useMount } from 'react-use'

import CraftFAQ from 'containers/CandidateProfile/CraftFAQV2'
import CandidateWidget from 'containers/CompanyPage/CandidateWidget'
import CommentsAndActivities from 'containers/CompanyPage/CommentsAndActivities'
import CompanyHeaderSection from 'containers/CompanyPage/CompanyHeaderSection'
import ConnectionsWidget from 'containers/CompanyPage/ConnectionsWidget'
import ListPills from 'containers/CompanyPage/ListPills'
import PortfolioSection from 'containers/CompanyPage/PortfolioSection'
import ResourcesSection from 'containers/CompanyPage/ResourcesSection'
import SharePipelineSection from 'containers/CompanyPage/SharePipelineSection'
import SharedListsSection from 'containers/CompanyPage/SharedListsSection'
import OfferIntroWidget from 'containers/Home/OfferIntroWidget'
import { useAccessControl } from 'global/AccessControl'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import { useAdvisorship, useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

const CompanyPage = () => {
  const teamSlug = useTeamSlug()
  const { user } = useCurrentUser()
  const { team } = useTeam(teamSlug)
  const { advisor } = useAdvisorship(teamSlug)
  const { canUseExtensionConnections } = useAccessControl(teamSlug)
  const { canViewPortfolio, canViewOffers, isAdminOrEmployee } = useAccessControl(teamSlug)
  const [filter, setFilter] = React.useState('')
  const [parent] = useAutoAnimate()

  const memberUuid = advisor?.uuid || ''
  const candidate = advisor?.role && advisor?.role === 'candidate'

  const {
    refetch: refetchLists,
    data: lists,
    isLoading: loadingLists,
  } = useQuery(['get_page_lists', teamSlug], () => callApi(api.getHomeLists, teamSlug))
  const companyLists = Object.values(lists?.lists || {})?.map((list) => list.list) || []

  const { data: connectionStatus, refetch: refetchConnectionStatus } = useQuery(
    ['connectionStatus', memberUuid],
    () => callApi(api.checkConnectionStatus, memberUuid),
    {
      refetchInterval: 1000 * 3,
      refetchIntervalInBackground: !window.DISABLE_CONNECTION_STATUS_POLLING,
      enabled: !!memberUuid,
    },
  )

  const showResources = !candidate && canViewOffers && (filter === '' || filter === 'Resources')
  const showPortfolio = canViewPortfolio && (filter === '' || filter === 'Portfolio')
  const showSharePipeline = !candidate && canViewPortfolio && filter === ''
  const showCraftFaq = candidate && teamSlug && teamSlug === 'craft-ventures'

  useMount(() => {
    if (!window.II && !isAdminOrEmployee) {
      api.sendUserActivity({ team_slug: teamSlug, name: 'view_deal_board' })
    }
  })

  if (!team) return null

  const candidateUuid = (user.advisors || []).find(
    (a) => a.role === 'candidate' && a.team_slug === teamSlug,
  )?.candidate_uuid

  const offersWidget =
    filter === '' ? (
      <OfferIntroWidget
        team={team}
        memberUuid={memberUuid}
        connectionStatus={connectionStatus}
        checkConnectionStatus={refetchConnectionStatus}
        refetchHome={refetchLists}
        canUseExtensionConnections={canUseExtensionConnections}
        hideSearch={showPortfolio}
        noConnectionsText={
          showPortfolio ? `Add your connections to the ${team.name} network` : null
        }
      />
    ) : (
      ''
    )

  return (
    <PageWrapper title={`${team?.name} page`} className="pt-">
      <>
        <CompanyHeaderSection team={team} companyLists={companyLists} />
        <ListPills
          lists={companyLists}
          filter={filter}
          onSetFilter={setFilter}
          candidateUuid={candidateUuid}
          teamSlug={teamSlug}
        />
        <div className="mt-10 flex flex-col lg:flex-row gap-4">
          <div ref={parent} className="flex flex-col flex-1 gap-8 max-w-[630px]">
            {!showPortfolio && !candidate && offersWidget}

            {loadingLists && <Loading />}

            <SharedListsSection
              companyLists={companyLists}
              companyData={lists?.lists}
              team={team}
              refetchLists={refetchLists}
              people={lists?.people}
              filter={filter}
            />

            {showPortfolio && !candidate && offersWidget}

            {showResources && <ResourcesSection teamSlug={teamSlug} />}
            {showSharePipeline && user.team?.slug && <SharePipelineSection teamSlug={teamSlug} />}
            {showPortfolio && team?.show_old_portfolio_on_page && (
              <PortfolioSection teamSlug={teamSlug} />
            )}
          </div>
          <div className="lg:w-80 flex-shrink-0 flex flex-col gap-4">
            {!candidate && <ConnectionsWidget location="companyPage" />}
            {candidateUuid && <CandidateWidget candidateUuid={candidateUuid} teamSlug={teamSlug} />}
            {showCraftFaq && <CraftFAQ />}
            <CommentsAndActivities />
          </div>
        </div>
      </>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <a style={{ color: '#A7B4C7', fontSize: '12px' }} href="https://clearbit.com">
          Logos provided by Clearbit
        </a>
      </div>
    </PageWrapper>
  )
}

export default CompanyPage
