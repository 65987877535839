import React from 'react'

import Typography from 'global/Typography'

import { IRequestor, ISalesTarget } from '../types'
import UserInfo from './UserInfo'

interface Props {
  requestor: IRequestor
  target: ISalesTarget
  requestors?: IRequestor[]
  selectedValue?: (value: string) => void
}

const UsersInfo: React.FC<Props> = ({ requestor, target, requestors, selectedValue }) => {
  const logo_url = 'https://logo.clearbit.com/'

  const getAvatarUrl = (company: ISalesTarget) => {
    if (company.item_type === 'GlobalPerson') {
      return target?.logo_url || target?.avatar_url || target?.image_url
    } else {
      return (
        target?.logo_url || target?.avatar_url || target?.avatarUrl || logo_url + target?.domain
      )
    }
  }

  target.itemType = target.itemType || target.item_type

  const targetHeadline =
    target.itemType === 'GlobalPerson' || target.itemType === 'InvestorCompanyJob'
      ? target.headline
      : target.domain

  const targetAvatarUrl = getAvatarUrl(target)
  const requestorCompany = requestor?.company_name || requestor?.teamName || ''
  const requestorTitle = requestor.title ? `${requestor.title}` : ''
  const requestorHeadline =
    requestorTitle && requestorCompany
      ? `${requestorTitle} at ${requestorCompany}`
      : requestorTitle || requestorCompany

  const handleRequestorSelect = (value: string) => {
    if (selectedValue) {
      selectedValue(value)
    }
  }

  return (
    <div className="flex items-start justify-evenly">
      <UserInfo
        title={requestor.name || ''}
        description={requestorHeadline || undefined}
        avatar={requestor.avatar_url || requestor.avatarUrl || null}
        showSelector={true}
        options={requestors}
        selectedValue={handleRequestorSelect}
        userUuid={requestor.uuid}
      />

      <div className="my-auto mx-1">
        <Typography color="rain">
          <i className="fa-light fa-arrows-left-right text-3xl" />
        </Typography>
      </div>

      <UserInfo
        title={target.name}
        description={targetHeadline}
        avatar={targetAvatarUrl}
        showSelector={false}
      />
    </div>
  )
}

export default UsersInfo
