import React, { useMemo, useState } from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import EditOfferModal from 'containers/Resources/EditOfferModal'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { Offer as OfferType } from 'utils/types'

export const offerTypes = [
  { label: 'Discounts', slug: 'deals' }, // leaving the slug to not break urls. need to add a simple redirect here.
  { label: 'People', slug: 'people' },
  { label: 'Content', slug: 'resources' },
  { label: 'Vendors', slug: 'vendors' },
]

interface Props {
  teamSlug: string
}

const ResourcesSection: React.FC<Props> = ({ teamSlug }) => {
  const history = useHistory()
  const { canViewOffers, canEditOffers } = useAccessControl(teamSlug)
  const { showModal } = useModal()
  const { isLoggedIn } = useCurrentUser()

  const [allTags, setAllTags] = useState<string[]>([])
  const [allCategories, setAllCategories] = useState<string[]>([])
  const [offers, setOffers] = useState<OfferType[]>()

  const openAddOfferModal = (proposed = false) => {
    showModal(
      (resolve) => (
        <EditOfferModal
          teamSlug={teamSlug}
          proposed={proposed}
          resolve={(offer) => {
            offer && setOffers([...(offers || []), offer])
            resolve()
          }}
          allTags={allTags}
          setAllTags={setAllTags}
          isFetchingTags={isLoading}
          allCategories={allCategories}
          setAllCategories={setAllCategories}
        />
      ),
      'add-offer-modal',
    )
  }

  const { isLoading, isFetching } = useQuery(
    ['offers', teamSlug],
    () => callApi(api.getOffers, teamSlug),
    {
      onSuccess: ({ offers, tags, categories }) => {
        setOffers(offers)
        setAllTags(tags)
        setAllCategories(categories)
      },
      enabled: canViewOffers,
      // cacheTime: Infinity,
      // staleTime: Infinity,
    },
  )

  const deals = useMemo(
    () =>
      offers?.filter(
        (offer) => !offer.proposed && (offer.offer_type === 'deals' || !offer.offer_type),
      ),
    [offers],
  )
  const people = useMemo(() => offers?.filter((offer) => offer.offer_type === 'people'), [offers])
  const vendors = useMemo(() => offers?.filter((offer) => offer.offer_type === 'vendors'), [offers])
  const proposed = useMemo(() => offers?.filter((offer) => offer.proposed), [offers])
  const resources = useMemo(
    () => offers?.filter((offer) => offer.offer_type === 'resources'),
    [offers],
  )

  const allOfferTypes = [
    {
      title: offerTypeLabel('Vendors', teamSlug),
      avatar: <i className="fal fa-store fa-fw" />,
      description: `${vendors?.length || 0} items`,
      cta: (
        <CabalButton
          onClick={() => history.push(`/${teamSlug}/resources/vendors`)}
          variant="link"
          padding="0"
          rightIcon={<i className="fal fa-chevron-right fa-xs" />}
        >
          View
        </CabalButton>
      ),
      cardClick: () => history.push(`/${teamSlug}/resources/vendors`),
    },
    {
      title: offerTypeLabel('People', teamSlug),
      avatar: <i className="fal fa-people-group fa-fw" />,
      description: `${people?.length || 0} items`,
      cta: (
        <CabalButton
          onClick={() => history.push(`/${teamSlug}/resources/people`)}
          variant="link"
          padding="0"
          rightIcon={<i className="fal fa-chevron-right fa-xs" />}
        >
          View
        </CabalButton>
      ),
      cardClick: () => history.push(`/${teamSlug}/resources/people`),
    },
    {
      title: offerTypeLabel('Discounts', teamSlug),
      avatar: <i className="fal fa-percent fa-fw" />,
      description: `${deals?.length || 0} items`,
      cta: (
        <CabalButton
          onClick={() => history.push(`/${teamSlug}/resources/deals`)}
          variant="link"
          padding="0"
          rightIcon={<i className="fal fa-chevron-right fa-xs" />}
        >
          View
        </CabalButton>
      ),
      cardClick: () => history.push(`/${teamSlug}/resources/deals`),
    },
    {
      title: offerTypeLabel('Content', teamSlug),
      avatar: <i className="fal fa-file-alt fa-fw" />,
      description: `${resources?.length || 0} items`,
      cta: (
        <CabalButton
          onClick={() => history.push(`/${teamSlug}/resources/resources`)}
          variant="link"
          padding="0"
          rightIcon={<i className="fal fa-chevron-right fa-xs" />}
        >
          View
        </CabalButton>
      ),
      cardClick: () => history.push(`/${teamSlug}/resources/resources`),
    },
  ]

  if (canEditOffers) {
    allOfferTypes.push({
      title: 'Proposed',
      avatar: <i className="fal fa-lightbulb fa-fw" />,
      description: `${proposed?.length || 0} items`,
      cta: (
        <CabalButton
          onClick={() => history.push(`/${teamSlug}/resources/proposed`)}
          variant="link"
          padding="0"
          rightIcon={<i className="fal fa-chevron-right fa-xs" />}
        >
          View
        </CabalButton>
      ),
      cardClick: () => history.push(`/${teamSlug}/resources/proposed`),
    })
  }

  function offerTypeLabel(label: string, teamSlug: string) {
    if (teamSlug === 'm13') {
      switch (label) {
        case 'Discounts':
          return 'Offers'
        case 'People':
          return 'M13 Team'
        case 'Vendors':
          return 'Preferred Partners'
        default:
          return label
      }
    } else {
      return label
    }
  }

  return (
    <Widget
      classNames="group"
      title={
        <Typography
          className="flex items-center cursor-pointer"
          component="button"
          fontWeight={600}
          onClick={() => history.push(`/${teamSlug}/resources`)}
        >
          Resources
          <Typography color="fog">
            <i className="fal fa-chevron-right ml-2 fa-xs" />
          </Typography>
        </Typography>
      }
      cta={
        <div className="hidden group-hover:flex">
          {canEditOffers && (
            <CabalButton padding="0" variant="link" onClick={() => openAddOfferModal(false)}>
              Add Resource
            </CabalButton>
          )}
          {!canEditOffers && isLoggedIn && (
            <CabalButton padding="0" variant="link" onClick={() => openAddOfferModal(true)}>
              Propose Resource
            </CabalButton>
          )}
        </div>
      }
    >
      {allOfferTypes.map(({ title, avatar, description, cta, cardClick }) => {
        return (
          <DataCardV2
            key={title}
            title={title}
            avatar={avatar}
            description={description}
            cta={cta}
            onClick={cardClick}
          />
        )
      })}
    </Widget>
  )
}

export default ResourcesSection
