import * as React from 'react'
import { useQuery } from 'react-query'
import { useTeamSlug } from 'store/hooks'
import api, { callApi } from 'utils/api'

import { CapTableType, EquityTierEntity } from 'utils/types'
import { cleanNum } from 'utils/ui'
import EquityTiers from './EquityTiers'

interface Props {
  capTable: CapTableType
  setCapTable: (t: CapTableType) => void
}

const EquityTierWrapper: React.VFC<Props> = ({ capTable, setCapTable }) => {
  const [equityTiers, setEquityTiers] = React.useState<EquityTierEntity[]>([])
  const teamSlug = useTeamSlug()

  useQuery(['getEquityTiers', teamSlug], () => callApi(api.getEquityTiers, teamSlug), {
    onSuccess: (data) => {
      setEquityTiers(data.equity_tiers)
    },
  })

  if (!capTable) return <></>

  const { fully_diluted, advisor_common, advisor_issued } = capTable
  const pool_remaining = cleanNum(advisor_common) - cleanNum(advisor_issued)

  return (
    <EquityTiers
      fully_diluted={fully_diluted}
      pool_remaining={pool_remaining}
      equityTiers={equityTiers}
      setEquityTiers={setEquityTiers}
    />
  )
}

export default EquityTierWrapper
