import React from 'react'

import { IntroConnection } from 'containers/Portfolio/MemberView/types/IntroConnection'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

interface Props {
  facilitator: IntroConnection
  introRequest: any
}

const FacilitatedBy: React.FC<Props> = ({ facilitator, introRequest }) => {
  const name =
    introRequest?.requestable?.item_type === 'InvestorCompanyJob'
      ? introRequest?.facilitator?.team_name
      : facilitator.name || ''
  const avatarUrl = facilitator.avatarUrl || facilitator.avatar_url

  return (
    <div className="flex justify-center gap-2 items-center mt-6 mb-4">
      <div className="w-2/5 justify-end flex">
        <Typography fontSize="12" color="fog">
          Facilitated by
        </Typography>
      </div>
      <Avatar name={name} src={avatarUrl} size="20" round />
      <div className="w-2/5 justify-start flex">
        <Typography className="truncate" fontSize="12">
          {name}
        </Typography>
      </div>
    </div>
  )
}

export default FacilitatedBy
