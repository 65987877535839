import React, { useEffect, useState } from 'react'

import isEqual from 'lodash/isEqual'
import { useDeepCompareEffect } from 'react-use'

import { FiltersValueType, renderFilters } from 'components/Filters'
import { useGroups } from 'store/hooks'

import { AdvisorModel } from 'utils/types'

type MemberPageFiltersType = {
  search: string
  advisor_uuids: string[]
}

interface Props {
  advisors: AdvisorModel[]
  teamSlug: string
  setFilteredAdvisors: (m: AdvisorModel[] | undefined) => void
  filteredAdvisors?: AdvisorModel[]
}

const Filter: React.FC<Props> = ({ advisors, teamSlug, setFilteredAdvisors, filteredAdvisors }) => {
  const [sortedGroupAdvisors, setSortedGroupAdvisors] = useState<AdvisorModel[]>([])

  const [filters, setFilters] = useState<{ search: string; groups: string[] }>({
    search: '',
    groups: [],
  })
  const { groups } = useGroups(teamSlug)

  useEffect(() => {
    let _filteredMembers: AdvisorModel[] | undefined
    if (filters.groups.length === 0) {
      _filteredMembers = undefined
    } else {
      _filteredMembers = advisors.filter((m) => {
        if (m.groups)
          for (const group of m.groups) {
            if (filters.groups.includes(group.uuid)) {
              return true
            }
          }
        // if (m.expertises)
        //   for (const expertise of m.expertises) {
        //     if (selectedIds.includes(expertise.id.toString())) {
        //       return true
        //     }
        //   }
        return false
      })
    }

    if (!isEqual(_filteredMembers, filteredAdvisors)) {
      setFilteredAdvisors(_filteredMembers)
      setSortedGroupAdvisors(_filteredMembers || [])
    }
  }, [filters.groups])

  useEffect(() => {
    const searchSpace = sortedGroupAdvisors?.length === 0 ? [...advisors] : [...sortedGroupAdvisors]

    const _filteredMembers = searchSpace.filter((member) =>
      [member.name, member.email, member.title, member.company_name].some((v) =>
        v?.toLowerCase().includes(filters.search.toLowerCase()),
      ),
    )

    if (!isEqual(_filteredMembers, filteredAdvisors)) setFilteredAdvisors(_filteredMembers)
  }, [filters.search, advisors])

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'search',
        type: 'search',
        icon: 'far fa-search',
        labelStr: 'Search',
        label: 'Search',
        skipLabelInAppliedPill: true,
        noPopover: true,
      },
      {
        key: 'groups',
        type: 'dropdown[]',
        label: 'Groups',
        options: groups.map((group) => ({
          label: `${group.name} (${group.advisor_groups_count})`,
          value: group.uuid,
        })),
      },
    ],
    value: filters,
    onChange: setFilters,
  })

  return (
    <div className={'flex-col w-full'}>
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex flex-wrap gap-2">{renderedFilters}</div>
      </div>
      {appliedFilters.length > 0 && (
        <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>
      )}
    </div>
  )
}

export default Filter
