import React, { useState } from 'react'

import FacilitatedBy from 'containers/IntroRequestModal/components/FacilitatedBy'
import UsersInfo from 'containers/IntroRequestModal/components/UsersInfo'
import { IIntroRequest } from 'containers/RequestCenter/types/ITypes'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal from 'global/Modal'
import Typography from 'global/Typography'

interface Props {
  onHide: () => void
  introRequestData: IIntroRequest
}

const ReminderModal: React.FC<Props> = ({ onHide, introRequestData }) => {
  const { requestor, target, facilitator } = introRequestData
  const [note, setNote] = useState('')
  return (
    <Modal
      show
      header={`Reminder to ${requestor.name}`}
      onHide={onHide}
      rightActions={<CabalButton>Send reminder</CabalButton>}
      className="max-w-lg"
    >
      <UsersInfo requestor={requestor} target={target}></UsersInfo>
      <FacilitatedBy facilitator={facilitator} />
      <Typography fontSize="12" fontWeight={400} lineHeight="1" className=" text-center">
        Add note
      </Typography>
      <TextArea
        value={note}
        className="w-full mt-1"
        rows={3}
        data-testid="request-talent-intro-modal-context"
        onChange={(e) => setNote(e.target.value)}
      />
    </Modal>
  )
}

export default ReminderModal
