import React from 'react'

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import Typography from 'global/Typography'

const StyledCard = styled.div`
  ${tw`mb-1 p-2 cursor-pointer`}
  border-radius: 8px;
  display: grid;
  grid-template-columns: 54px 7fr 2fr;
  grid-template-areas: 'avatar company_name cta';
  grid-gap: 2px;
  transition: background-color 200ms ease;

  background-color: ${({ theme }) => {
    if (theme.name === 'light') {
      return `#f9f9f9`
    } else {
      return `#293342`
    }
  }};

  &:hover {
    background-color: ${({ theme }) => {
      if (theme.name === 'light') {
        return `#eeeeee`
      } else {
        return `#232c39`
      }
    }};
  }
`

const PlusCircle = styled.div`
  ${tw`text-center`}
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => {
    if (theme.name === 'light') {
      return `#fff`
    } else {
      return `#0E151F`
    }
  }};
`

const StyledTypography = styled(Typography)`
  font-size: 14px;
`

const AddCompanyEmptyState: React.VFC = () => {
  const history = useHistory()

  return (
    <div className="-my-2">
      <EmptyState
        fullWidth
        heading={`Join your team's workspace`}
        image={
          <Typography color="fog_rain" fontSize="24" className="-mt-2">
            <i className="far fa-moon-stars"></i>
          </Typography>
        }
        body={'Use Cabal for your company or firm'}
        cta={
          <div className="flex flex-col items-center space-y-2 -mt-4 -mb-2">
            <CabalButton
              className="w-1/4"
              onClick={() => history.push('/onboarding')}
              rightIcon={<i className="fal fa-arrow-right"></i>}
            >
              Continue
            </CabalButton>
          </div>
        }
      />
    </div>
  )

  // return (
  //   <StyledCard onClick={() => history.push('/onboarding')}>
  //     <PlusCircle>
  //       <i className="fal fa-plus text-2xl m-auto p-1" />
  //     </PlusCircle>
  //     <StyledTypography
  //       fontWeight={600}
  //       className="mt-auto mb-auto ml-2"
  //       component="h3"
  //       lineHeight="1"
  //     >
  //       Add or Join
  //     </StyledTypography>
  //   </StyledCard>
  // )
}

export default AddCompanyEmptyState
