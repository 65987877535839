import * as React from 'react'

import cx from 'classnames'
import { compact, debounce, isEqual, trim, uniqWith } from 'lodash'
import styled from 'styled-components'
import validator from 'validator'

import { recipientValue } from 'components/Composer'
import { Label, MultiSelect, SelectOption } from 'global/Input'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisors, useGroups, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import { useContacts } from 'utils/api/contacts'
import { Contact, EmailRecipientValue, MessageRecipient, SenderModel } from 'utils/types'

const FieldWrapper = styled.div`
  background: ${({ theme }) => theme.layout.main_bg_color};
  border-radius: 0.25rem;
`

const emailExtractionRegex = /(?:[\w+-.%]+@[\w-.]+\.[A-Za-z]{2,})/g

interface Props {
  selected?: MessageRecipient[]
  onSelect: (selected: MessageRecipient[] | null) => void
  teamSlug: string
  label?: string
  allowGroups?: boolean
  allowCustomEmail?: boolean
  allowAdvisors?: boolean
  fromRequest?: boolean
  willMailMerge?: boolean
  type?: 'to' | 'cc'
  placeholder?: string
  isFixedFunc?: (r: MessageRecipient) => boolean
  sender?: SenderModel
}

function RecipientsField(props: Props) {
  // TODO: add a selector and remove the default value of companySlug

  const {
    selected,
    teamSlug,
    onSelect,
    label,
    allowGroups = true,
    allowCustomEmail = false,
    allowAdvisors = true,
    fromRequest = false,
    willMailMerge = true,
    placeholder = '',
    type = 'to',
    isFixedFunc,
    sender,
  } = props
  const { groups } = useGroups(teamSlug, true)
  const { advisors } = useAdvisors({
    teamSlug: teamSlug,
  })
  const { team } = useTeam(teamSlug)
  const reactSelectInputIdRef = React.useRef(`select-input-${new Date().getTime()}`)

  const [contacts, getContacts] = useContacts()

  if (allowGroups && selected && selected.length === 1 && selected[0].value === 'all') {
    const group = groups?.find((g) => g.name === 'All')
    if (group)
      selected.splice(0, 1, {
        type: 'group',
        value: group.uuid,
        label: group.name,
      })
  }

  const options = React.useMemo(() => {
    const options: SelectOption<MessageRecipient>[] = []

    if (options.length <= 0) {
      if (allowGroups && groups && groups.length > 0 && sender && sender.type === 'admin') {
        for (const group of groups) {
          const value = recipientValue({ group })
          options.push({
            group: 'Groups',
            value,
            label: `${group.name}${
              group.advisor_groups_count ? ` (${group.advisor_groups_count})` : ''
            }`,
            toString: () => group.uuid,
            isFixed: isFixedFunc?.(value),
          })
        }
      }
      if (team?.admins_and_members) {
        for (const user of team?.admins_and_members) {
          const value = recipientValue({ user })
          options.push({
            group: 'Employees',
            value,
            label: `${user.name} - ${user.email}`,
            toString: () => user.uuid,
            isFixed: isFixedFunc?.(value),
          })
        }
      }
      if (advisors && advisors.length > 0 && allowAdvisors) {
        for (const advisor of advisors) {
          if (!advisor.role || advisor.role === 'candidate') {
            const value = recipientValue({ advisor })
            options.push({
              group: 'Members',
              value,
              label: `${advisor.name}`,
              extraLabel: advisor.email,
              toString: () => advisor.uuid,
              isFixed: isFixedFunc?.(value),
            })
          }
        }
      }
    }

    for (const contact of Object.values<Contact>(contacts)) {
      const contactName = trim(contact.name || '')
      const value = recipientValue({ contact })
      options.push({
        group: 'Contacts',
        value,
        toString: () => contact.uuid,
        label: contactName ? `${contactName} - ${contact.email}` : contact.email,
        extraLabel: !!contactName ? contact.email : '',
        isFixed: isFixedFunc?.(value),
      })
    }

    return options
  }, [
    advisors,
    allowAdvisors,
    allowGroups,
    contacts,
    fromRequest,
    groups,
    isFixedFunc,
    team?.admins_and_members,
  ])

  selected
    ?.filter((e) => e.type === 'email')
    .forEach((s) => {
      options.push({
        label: s.label,
        value: s,
        group: 'Emails',
        toString: () => `email-${(s.value as EmailRecipientValue).email}`,
        isFixed: isFixedFunc?.(s),
      })
    })

  const focusSelectInput = React.useCallback(
    () =>
      setTimeout(() => {
        document.getElementById(reactSelectInputIdRef.current)?.focus()
      }, 100),
    [],
  )

  const handleInputChange = debounce((q: string) => {
    getContacts(q)
  }, 750)

  const createEmailOption = React.useCallback(
    (s: string, failSoftly: boolean) => {
      const emails = s.toLowerCase().match(emailExtractionRegex)
      if (!emails) {
        if (!failSoftly) {
          cabalToast({ style: 'error', content: `Invalid input, please enter email addresses` })
        }
        return
      }

      const newSelection: MessageRecipient[] = []

      for (const email of emails) {
        if (!validator.isEmail(email)) {
          cabalToast({ style: 'error', content: `${email} is an invalid email address` })
          return
        }

        if (
          [...(advisors || []), ...(team?.admins_and_members || []), ...contacts].findIndex(
            (a) => a.email === email,
          ) !== -1
        ) {
          const optionToSelect = options.find((o) =>
            `${o.label} ${o.extraLabel}`?.match(emailExtractionRegex)?.includes(email),
          )
          optionToSelect && newSelection.push(optionToSelect.value)
          continue
        }

        newSelection.push({
          label: email,
          value: {
            email: email,
            first_name: '',
            last_name: '',
            add_member: false,
          },
          type: 'email',
        })
      }

      onSelect(uniqWith([...(selected || []), ...newSelection], isEqual))

      focusSelectInput()
    },
    [advisors, focusSelectInput, onSelect, options, selected, team?.admins_and_members],
  )

  const handleOnChange = (selected: MessageRecipient[] | null) => {
    onSelect(selected)
    focusSelectInput()
  }

  const handleClickMultiValue = (e: React.MouseEvent, value: MessageRecipient) => {
    if (value.type === 'group') {
      if (!confirm('Do you want to expand this group?')) return

      const updatedSelected = [...(selected || [])].filter(
        (s) => !(s.type === value.type && s.value === value.value),
      )

      const advisorsFromGroups = advisors?.filter(
        (a) => !!a.groups.find((g) => g.uuid === value.value),
      )

      updatedSelected.push(
        ...(advisorsFromGroups || []).map((a) => {
          return recipientValue({ advisor: a })
        }),
      )

      onSelect(updatedSelected)
    }
  }

  if (!advisors && !team) return <></>

  let tooltip = ''

  if (willMailMerge) {
    if (type === 'to') {
      tooltip = `Recipients in the “To” field will receive their own email. They will not see other recipients.`
    } else if (type === 'cc') {
      tooltip = `Adding people to “Cc” will copy them on each email. If your “To” field has 50 recipients,
        people in this field will receive 50 individual emails.`
    }
  }

  return (
    <Label className="mb-0 flex-grow">
      <FieldWrapper className={'flex justify-between'} data-intercom-target="messages_to_field">
        {label && (
          <Typography
            fontWeight={400}
            color="admin_label"
            component="div"
            fontSize="12"
            className="my-auto px-3"
          >
            {label}
          </Typography>
        )}

        <MultiSelect<MessageRecipient>
          data-testid={`recipient-field-${label}`}
          fontSize={'14px'}
          value={selected}
          onChange={handleOnChange}
          getOptionId={(v) => v?.value}
          options={options}
          placeholder={placeholder}
          portal
          creatable={allowCustomEmail}
          onCreateOption={createEmailOption}
          formatCreateLabel={(i) => i}
          inputId={reactSelectInputIdRef.current}
          className={cx(
            {
              'w-full': !!label,
            },
            'flex-grow',
          )}
          removeBorder
          removeFocusRing
          onInputChange={handleInputChange}
          onClickMultiValue={handleClickMultiValue}
          unknownValueToOption={(mr) => {
            if (mr.type === 'email') {
              return {
                group: 'Email',
                label: mr.value.first_name
                  ? `${compact([mr.value.first_name, mr.value.last_name]).join(' ')} (${
                      mr.value.email
                    })`
                  : mr.value.email,
                value: mr,
              }
            }

            return {
              label: mr.label,
              value: mr,
            }
          }}
          valueKeyFn={(mr) => {
            if (mr.type === 'email') {
              return mr.value.email
            } else {
              return mr.value
            }
          }}
          rightActions={
            tooltip && (
              <Tooltip
                label={
                  <Typography component="div" textAlign="center" className="py-1">
                    {tooltip}
                  </Typography>
                }
              >
                <i className="far fa-question-circle" />
              </Tooltip>
            )
          }
        />
      </FieldWrapper>
    </Label>
  )
}

export const MemoizedRecipientsField = React.memo(RecipientsField)

export default RecipientsField
