import React, { useMemo } from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import CabalMessage from 'models/CabalMessage'
import objectHash from 'object-hash'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useDeepCompareMemo } from 'use-deep-compare'

import Collaborators from 'components/Collaborators'
import CommentsAndActivity from 'components/CommentsAndActivity'
import FocusBannerEmailAuth from 'components/Composer/Sidebar/FocusBannerEmailAuth'
import GoogleAuthModal from 'components/GoogleAuthModal'
import { useGlobalComposerMessage } from 'components/SendMessage/index.class'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { useAppDispatch } from 'store/hooks'
import { useAdvisors, useCurrentUser, useTeam } from 'store/hooks'
import { setGmailAuthorizedEmails } from 'store/reducers/currentUserReducer'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import copyToClipboard from 'utils/copyToClipboard'
import useColorMode from 'utils/hooks/useColorMode'
import {
  EmailRecipientValue,
  EmailSnippet,
  MessagePersonalizationBlueprint,
  TweetModel,
} from 'utils/types'

import { useComposeQuery } from '../useComposeQuery'
import ConnectionIntroRequestInfo from './ConnectionIntroRequestInfo'
import InsertTabs from './InsertTabs'
import Personalizations from './Personalizations'
import RequestInfo from './RequestInfo'
import RequestRetweetPreview from './RequestRetweetPreview'
import VcInsertV2 from './VcInsertV2'
import VersionHistoryModal from './VersionHistoryModal'

const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

export type PersonalizationRecipient = {
  type: 'advisor' | 'email' | 'user'
  first_name: string
  last_name: string
  email: string
  uuid: string
  avatar_url?: string
  name: string
}

interface Props {
  onHide: (hide?: boolean) => void
  setPresenceListContainer: (container: HTMLDivElement) => void
  setBody: (b: string) => void
  teamSlug: string
  composerUuid: string
  handleSelectSnippet: (snippet: EmailSnippet) => void
  setCurrentPersonalization: (p?: MessagePersonalizationBlueprint) => void
  currentPersonalization?: MessagePersonalizationBlueprint
}

const ComposerSidebar: React.VFC<Props> = ({
  onHide,
  teamSlug,
  setPresenceListContainer,
  setBody,
  composerUuid,
  handleSelectSnippet,
  setCurrentPersonalization,
  currentPersonalization,
}) => {
  const { showModal } = useModal()
  const { team } = useTeam(teamSlug)
  const { message, refetchMessage } = useComposeQuery(teamSlug, composerUuid)
  const { isMobile } = useColorMode()
  const { advisors: allAdvisors } = useAdvisors({ teamSlug })
  const [globalComposerMessage] = useGlobalComposerMessage()
  const { user, reloadUser } = useCurrentUser()
  const dispatch = useAppDispatch()

  const messageRecipients = React.useMemo(
    () => globalComposerMessage?.recipients,
    [objectHash(globalComposerMessage?.recipients || null)],
  )

  const { advisors: groupAdvisors = [] } = useAdvisors({
    groupUuids:
      messageRecipients?.filter((r) => r.type === 'group').map((r) => r.value as string) || [],
  })

  const handleCopy = (id: string) => {
    copyToClipboard(`${window.location.origin}/${teamSlug}/compose/${id}`)
    cabalToast({ style: 'success', content: 'Copied! Share the copied url with your teammates' })
  }

  const openVersionHistoryModal = () => {
    if (!message || !team) return

    showModal(
      (resolve) => (
        <VersionHistoryModal onHide={resolve} message={message} team={team} setBody={setBody} />
      ),
      `version-history-${message.uuid}`,
    )
  }

  const getMessagePersonalizationsQuery = useQuery(
    ['getMessagePersonalizations', message?.uuid],
    () => callApi(api.getMessagePersonalizations, teamSlug, message!.uuid),
    {
      enabled: !!globalComposerMessage,
    },
  )

  const { refetch: reloadSenders } = useQuery(
    [teamSlug, 'getSenderUsers', user.uuid],
    () => callApi(api.getSenderUsers, teamSlug, message!.uuid),
    { select: ({ senders }) => senders },
  )

  const individualRecipients = useDeepCompareMemo(() => {
    const recipients: PersonalizationRecipient[] = []

    const advisors =
      messageRecipients
        ?.filter((r) => r.type === 'advisor')
        ?.map((r) => allAdvisors?.find((a) => a.uuid === (r.value as string)))
        ?.concat(...groupAdvisors) || []

    for (const advisor of advisors) {
      if (!advisor) continue

      recipients.push({
        type: 'advisor',
        first_name: advisor.name,
        last_name: advisor.name,
        avatar_url: advisor.avatar_url,
        uuid: advisor.uuid,
        name: advisor.name,
        email: advisor.email,
      })
    }

    const users =
      messageRecipients
        ?.filter((r) => r.type === 'founder')
        ?.map((r) => team?.admins_and_members?.find((u) => u.uuid === (r.value as string))) || []

    for (const recipient of users) {
      if (!recipient) continue

      recipients.push({
        type: 'user',
        first_name: recipient.name,
        last_name: recipient.name,
        avatar_url: recipient.avatar_url,
        uuid: recipient.uuid,
        name: recipient.name,
        email: recipient.email,
      })
    }

    const emails =
      messageRecipients
        ?.filter((r) => r.type === 'email')
        .map((r) => r.value as EmailRecipientValue) || []

    for (const email of emails) {
      recipients.push({
        type: 'email',
        first_name: email.first_name,
        last_name: email.last_name,
        uuid: email.email,
        name: compact([email.first_name, email.last_name]).join(' '),
        email: email.email,
      })
    }

    return compact(recipients)
  }, [allAdvisors, groupAdvisors.map((a) => a.uuid), messageRecipients, team?.admins_and_members])

  const messagePersonalizations = useMemo(
    () =>
      compact(
        getMessagePersonalizationsQuery.data?.personalizations?.filter((p) =>
          individualRecipients?.find((r) => r.uuid === p.recipient.uuid),
        ),
      ),
    [getMessagePersonalizationsQuery.data?.personalizations, individualRecipients],
  )

  const renderGmailAuthModal = React.useCallback(
    (resolve: () => void) => (
      <GoogleAuthModal
        show
        email_alias_address={user.email_alias_address}
        onHide={() => {
          resolve()
        }}
        onSkip={async () => {
          await reloadUser()
          resolve()
        }}
        onAuthorize={async (authorizedEmail) => {
          await reloadUser()
          await reloadSenders()

          if (authorizedEmail) {
            dispatch(setGmailAuthorizedEmails([authorizedEmail]))
          }

          resolve()
        }}
      />
    ),
    [user, reloadUser, reloadSenders, dispatch],
  )

  const handleShowEmailProviderAuthModal = React.useCallback(() => {
    return showModal(renderGmailAuthModal, 'gmail-auth-modal')
  }, [showModal, renderGmailAuthModal])

  if (isMobile) return <></>

  return (
    <div className={cx(' flex-shrink-0 mx-1 px-4')}>
      <HeaderWrapper className={cx('flex justify-between pb-4 pt-3 sticky top-0 z-10')}>
        <div ref={(ref) => ref && setPresenceListContainer(ref)} />
        {message && (
          <div>
            <CabalButton
              variant="tertiary"
              rightIcon={<i className="far fa-history"></i>}
              className="ml-2"
              onClick={openVersionHistoryModal}
              tooltip="Version history"
            />
            <CabalButton
              variant="tertiary"
              rightIcon={<i className="far fa-link"></i>}
              onClick={() => handleCopy(message.uuid)}
              tooltip="Copy link"
            />
            <CabalButton
              variant="tertiary"
              size="small"
              className={cx('ml-2')}
              onClick={() => onHide(true)}
              data-testid="close-composer"
              rightIcon={<i className="far fa-times"></i>}
            />
          </div>
        )}
      </HeaderWrapper>

      {message && (
        <>
          {message.from_request && team && (
            <div className="mt-6">
              <RequestInfo
                team={team}
                request_uuid={message.request_uuid!}
                messageSender={message.sender}
                messageRecipients={messageRecipients}
              />
            </div>
          )}

          {message.intro_request_uuid && (
            <ConnectionIntroRequestInfo uuid={message?.intro_request_uuid} teamSlug={teamSlug} />
          )}

          {message.request?.tweet && (
            <RequestRetweetPreview
              tweet={message.request.tweet as TweetModel}
              teamSlug={teamSlug}
            />
          )}
          {team?.enable_message_collaborators_widget &&
            message.can_current_user_add_collaborators && (
              <Collaborators
                teamSlug={teamSlug}
                attachableType="Message"
                attachableUuid={message.uuid}
                collaborators={message.collaborators || []}
                onReload={refetchMessage}
              />
            )}

          {!team?.features?.vc_insert_v2 && team?.enable_portfolio && (
            <InsertTabs
              handleSelectSnippet={handleSelectSnippet}
              teamSlug={teamSlug}
              advisors={allAdvisors}
            />
          )}

          {team?.features?.vc_insert_v2 && team?.enable_portfolio && (
            <VcInsertV2 handleSelectSnippet={handleSelectSnippet} teamSlug={teamSlug} />
          )}
        </>
      )}

      {message && individualRecipients && (
        <Personalizations
          messageUuid={message.uuid}
          teamSlug={teamSlug}
          recipients={individualRecipients}
          currentPersonalization={currentPersonalization}
          setCurrentPersonalization={setCurrentPersonalization}
          getMessagePersonalizationQuery={getMessagePersonalizationsQuery}
          messagePersonalizations={messagePersonalizations}
        />
      )}

      {message && message.can_current_user_create_comments && message && (
        <CommentsAndActivity
          messageThreadId={null}
          message={CabalMessage.from(message)}
          teamSlug={teamSlug}
        />
      )}

      {/* <div className="mt-6">
          <FocusBannerEmailAuth onGoogleAuthClick={handleShowEmailProviderAuthModal} />
        </div> */}
    </div>
  )
}

export default ComposerSidebar
