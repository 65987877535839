import React, { useState } from 'react'
import api, { callApi } from 'utils/api'
import { useMutation, useQuery } from 'react-query'
import { useSearchParam, useSetState } from 'react-use'
import { Redirect, useParams } from 'react-router-dom'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import Card from 'global/Card'
import { Container } from './styles'
import CardLogo from './CardLogo'
import { TextInput } from 'global/Input'
import { TalentPOC } from 'utils/types/investor'
import CabalButton from 'global/CabalButton'
import { cabalToast } from 'ui-components/Toast'
import { useUniqueAccessLink } from 'store/hooks'

const RerouteRequest: React.VFC = () => {
  const companyUuid = useSearchParam('company_uuid')
  const { secret_uuid: secretUuid } = useParams<{ secret_uuid: string }>()
  const [introRequest, setIntroRequest] = useState<{
    accepted_at: string
    investor_company_logo: string | null
    investor_company_name: string
  }>({ accepted_at: '', investor_company_logo: null, investor_company_name: '' })
  const [talentPOC, setTalentPOC] = useSetState<TalentPOC>()

  const { uuid: uniqueAccessLinkUuid } = useUniqueAccessLink()

  const { isLoading } = useQuery(
    ['getIntroRequest', secretUuid],
    () => callApi(api.getIntroRequest, secretUuid, companyUuid || ''),
    {
      onSuccess: (data) => {
        setIntroRequest(data)
      },
    },
  )

  const { mutate: rerouteIntroRequest, isLoading: working } = useMutation(
    () =>
      callApi(
        api.rerouteIntroRequest,
        secretUuid,
        companyUuid || '',
        talentPOC,
        uniqueAccessLinkUuid,
      ),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Intro request was successfully rerouted!' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Something went wrong!' })
      },
    },
  )

  const {
    accepted_at: acceptedAt,
    investor_company_logo: logo,
    investor_company_name: companyName,
  } = introRequest

  if (isLoading) return <Loading />

  if (introRequest?.accepted_at)
    return (
      <Redirect
        to={`/intro-requests/${introRequest?.secret_uuid}/accepted?company_uuid=${companyUuid}`}
      />
    )

  if (introRequest?.declined_at)
    return (
      <Redirect
        to={`/intro-requests/${introRequest?.secret_uuid}/declined?company_uuid=${companyUuid}`}
      />
    )

  const disabled = !talentPOC.first_name || !talentPOC.last_name || !talentPOC.email

  return (
    <Container>
      <Card className="p-10 w-1/2 m-auto">
        <CardLogo logo={logo} companyName={companyName} />
        <div className="mt-5">
          {acceptedAt ? (
            <>
              <Typography color="fog_rain" component="div">
                The intro request was accepted!
              </Typography>
            </>
          ) : (
            <>
              <Typography color="fog_rain" component="div">
                {`Reroute ${companyName} intro request to:`}
              </Typography>
              <TextInput
                value={talentPOC.email}
                className="w-full mt-3"
                valueType="non-empty"
                onChange={(e) =>
                  setTalentPOC({
                    email: e.target.value || undefined,
                  })
                }
                placeholder="Email"
                data-testid="reroute-intro-email-input"
              />
              <TextInput
                value={talentPOC.first_name}
                className="w-full mt-3"
                valueType="non-empty"
                onChange={(e) =>
                  setTalentPOC({
                    first_name: e.target.value || undefined,
                  })
                }
                placeholder="First Name"
                data-testid="reroute-intro-first-name-input"
              />
              <TextInput
                value={talentPOC.last_name}
                className="w-full mt-3"
                valueType="non-empty"
                placeholder="Last Name"
                onChange={(e) =>
                  setTalentPOC({
                    last_name: e.target.value || undefined,
                  })
                }
                data-testid="reroute-intro-last-name-input"
              />
              <TextInput
                value={talentPOC.linkedin_url}
                className="w-full mt-3"
                valueType="website"
                placeholder="LinkedIn Profile URL (Optional)"
                onChange={(e) =>
                  setTalentPOC({
                    linkedin_url: e.target.value || undefined,
                  })
                }
                data-testid="reroute-intro-linkedin-url-input"
              />
              <CabalButton
                className="mt-3"
                onClick={() => rerouteIntroRequest()}
                leftIcon={<i className="fa fa-paper-plane" />}
                working={working}
                disabled={disabled}
                data-testid="reroute-intro-send-btn"
              >
                Send
              </CabalButton>
            </>
          )}
        </div>
      </Card>
    </Container>
  )
}

export default RerouteRequest
