import React from 'react'

import { useHistory } from 'react-router-dom'

import { useComposer } from 'components/Composer'
import { IIntroRequest } from 'containers/RequestCenter/types/ITypes'
import Avatar from 'global/Avatar'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import PillV2 from 'ui-components/PillV2'
import Widget from 'ui-components/Widget'

import { joinReactElements } from 'utils/array'

const DetailSection = ({
  title,
  imageUrl,
  name,
  position,
  companyName,
}: {
  title: string
  imageUrl?: string
  name: string
  position: string
  companyName: string
}) => {
  return (
    <div className="flex flex-col gap-1.5">
      <Typography color="fog_rain" fontSize="14">
        {title}
      </Typography>
      <div className="flex items-center gap-2">
        <div className="flex-shrink-0">
          <Avatar src={imageUrl} name={name} size={'32'} />
        </div>
        <div className="truncate">
          <Typography lineHeight={1} fontSize="14" component="p">
            {name || ''}
          </Typography>
          {position !== '' && companyName !== '' ? (
            <div className="flex items-center gap-2 mt-1">
              <Typography fontSize="12" color="fog" className="truncate">
                {position}
              </Typography>
              <Typography fontSize="12" lineHeight={1} color={'fog'}>
                {'·'}
              </Typography>
              <Typography fontSize="12" color="fog">
                {companyName}
              </Typography>
            </div>
          ) : (
            <Typography className="mt-1" fontSize="12" color="fog" component="p">
              {position || companyName}
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}

interface IProps {
  introRequestDetails: IIntroRequest
}

const Details: React.FC<IProps> = ({ introRequestDetails }) => {
  const { target, requestor, initiator, facilitator, message } = introRequestDetails
  const { compose } = useComposer()
  const history = useHistory()

  return (
    <Widget title="Details">
      <div className="flex flex-col gap-4">
        {message && (
          <div className="flex flex-col gap-1">
            <Typography color="fog_rain" fontSize="14">
              Message
            </Typography>
            <div className="">
              <PillV2
                className="px-1.5 py-0.5 truncate max-w-full"
                title={<Typography className="truncate">{message.subject}</Typography>}
                onClick={() =>
                  message.draft
                    ? compose({ messageUuid: message.uuid })
                    : history.push(`/messages/sent#${message.uuid}`)
                }
              />
            </div>
          </div>
        )}
        <DetailSection
          title="Target"
          imageUrl={target.image_url || undefined}
          name={target.name}
          position={target?.position || target?.headline || target?.title || ''}
          companyName={target.company_name || ''}
        />
        <DetailSection
          title="Requestor"
          imageUrl={requestor.avatar_url || undefined}
          name={requestor.name || ''}
          position={requestor.position || requestor.headline || requestor.title || ''}
          companyName={requestor.company_name || ''}
        />
        <DetailSection
          title="Facilitator"
          imageUrl={facilitator.avatar_url || undefined}
          name={facilitator.name || ''}
          position={facilitator.position || facilitator.headline || facilitator.title || ''}
          companyName={facilitator.company_name || ''}
        />
        {initiator && initiator.id !== requestor.id && (
          <DetailSection
            title="Initiator"
            imageUrl={initiator.avatar_url || undefined}
            name={initiator.name || ''}
            position={initiator.position || initiator.headline || initiator.title || ''}
            companyName={initiator.company_name || ''}
          />
        )}
      </div>
    </Widget>
  )
}

export default Details
