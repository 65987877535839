import React, { useState } from 'react'

import { UseQueryResult, useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import DefaultContextContainer from 'containers/Asks/DealBoard/DefaultContextContainer'
import AskSettings from 'containers/Settings/AsksSettings'
import CabalButton from 'global/CabalButton'
import { CheckBox, MultiSelect, Select, TextArea } from 'global/Input'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { EmailTemplate, MutationFunc, Team } from 'utils/types'

import { Border } from '../styles'

interface Props {
  updateTeamMutation: MutationFunc<Partial<Team>>
  updateTemplateMutation: MutationFunc<EmailTemplate>
  getTemplatesQuery: UseQueryResult<{
    templates: EmailTemplate[]
  }>
  companySlug: string
  closeModal?: () => void
}

interface TeamParams {
  ideal_customer_profile?: string
  stage_changes_visible_to_advisors?: boolean
}

const MemberViewSettings: React.VFC<Props> = ({
  updateTeamMutation,
  updateTemplateMutation,
  getTemplatesQuery,
  companySlug,
  closeModal,
}) => {
  const { team, reloadTeam } = useTeam(companySlug)
  const [asksContext, setAsksContext] = useState(
    team?.sales_asks_prompt ||
      `Welcome to our Cabal page. Here we’ll share updates and ways to help us win. \n\nTo start, share your connections then click “Offer intro” for contacts you’re willing to introduce. When you offer an intro, we will follow up with custom referral emails for your approval.`,
  )
  const [salesAskPromptUserId, setSalesAskPromptUserId] = useState(team?.sales_ask_prompt_user_id)
  const [showPreview, setShowPreview] = useState(true)
  const history = useHistory()

  const ICPs = team?.ideal_customer_profile ? team?.ideal_customer_profile?.split(',') : undefined

  const [stageChangesVisibleToAdvisors, setStageChangesVisibleToAdvisors] = useState(
    team?.stage_changes_visible_to_advisors,
  )

  const { mutate: updateTeam, isLoading: teamUpdating } = useMutation(
    ['icpUpdate'],
    (teamParams: TeamParams) =>
      callApi(api.updateTeam, companySlug, {
        team: teamParams,
      }).then(() => reloadTeam()),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Changes saved' })
      },
    },
  )

  const teamAdminOptions =
    team?.admins_and_members
      .filter((u) => u.team_role === 'admin')
      .map((u) => ({ value: u.uuid, label: u.name })) || []

  const templates = getTemplatesQuery.data?.templates

  const defaultTemplate = templates?.find((t) => t.default_for === 'member_intro_template')

  const handleTemplateSelection = (v: string) => {
    const selected: EmailTemplate | undefined = templates?.find((t) => t.uuid === v)
    if (selected) {
      selected.public_to_team = true
      selected.default_for = 'member_intro_template'
      updateTemplateMutation.mutate(selected)
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-1.5">
        <Typography fontWeight={600} component="h1">
          Welcome Message
        </Typography>
        {showPreview && (
          <Typography
            color={'purple'}
            fontSize="14"
            component="p"
            className="cursor-pointer"
            onClick={() => setShowPreview(!showPreview)}
          >
            Edit
          </Typography>
        )}
      </div>

      {showPreview ? (
        <Border>
          <DefaultContextContainer companySlug={companySlug} preview={true} />
        </Border>
      ) : (
        <>
          <div className="flex w-full items-center mb-2">
            <Typography fontWeight={600} component="h1" className="mr-4 flex-none">
              From
            </Typography>
            <Select
              value={salesAskPromptUserId}
              className="flex-grow"
              onChange={(option) => option && setSalesAskPromptUserId(option)}
              placeholder="Select user"
              options={teamAdminOptions}
            />
          </div>
          <TextArea
            className="pt-1 mb-4 w-full"
            labeled
            value={asksContext}
            onChange={(e) => setAsksContext(e.currentTarget.value)}
            disabled={
              updateTeamMutation.isLoading && !!updateTeamMutation.variables?.['sales_asks_prompt']
            }
            rows={6}
          />

          <div className="flex justify-end">
            <CabalButton
              variant="secondary"
              disabled={asksContext === ''}
              working={
                updateTeamMutation.isLoading &&
                !!updateTeamMutation.variables?.['sales_asks_prompt']
              }
              onClick={() =>
                updateTeamMutation
                  .mutateAsync({
                    sales_asks_prompt: asksContext,
                    sales_ask_prompt_user_id: salesAskPromptUserId,
                    sales_asks_prompt_changed_at: new Date(),
                  })
                  .then(() => {
                    setShowPreview(true)
                  })
              }
            >
              Save
            </CabalButton>
          </div>
        </>
      )}

      <Typography fontWeight={600} component="h1" className="mt-4 mb-1.5">
        Target Personas
      </Typography>

      <MultiSelect
        value={ICPs}
        onChange={(val) => {
          updateTeam({
            ideal_customer_profile: val.toString(),
          })
        }}
        className="text-sm mb-4"
        placeholder="Add ICPs like founder, advisor etc"
        formatCreateLabel={() => 'Add persona'}
        isClearable
        compactPlaceholder
        creatable
        options={[
          { label: 'Founder', value: 'Founder' },
          { label: 'Investor', value: 'Investor' },
          { label: 'Advisor', value: 'Advisor' },
          { label: 'VC', value: 'VC' },
          { label: 'Sales', value: 'Sales' },
          { label: 'Marketing', value: 'Marketing' },
        ]}
        isLoading={teamUpdating}
      />

      <Typography fontWeight={600} component="h1" className="mb-1.5">
        Activity Settings
      </Typography>

      <div className="mt-4 mb-6">
        <label>
          <div>
            <CheckBox
              checked={stageChangesVisibleToAdvisors}
              onChange={(e) => {
                setStageChangesVisibleToAdvisors(e.currentTarget.checked)
                updateTeam({
                  stage_changes_visible_to_advisors: e.currentTarget.checked,
                })
              }}
              label="Stage change activity visible to advisors"
              disabled={teamUpdating}
            />
          </div>
        </label>
      </div>

      <div className="flex justify-between items-center mb-1.5">
        <Typography fontWeight={600} component="h1" className="mb-1.5">
          Default Message template
        </Typography>
        {showPreview && defaultTemplate && (
          <Typography
            color={'purple'}
            fontSize="14"
            component="p"
            className="cursor-pointer"
            onClick={() => {
              closeModal && closeModal()
              history.push(`/${companySlug}/templates/${defaultTemplate?.uuid}`)
            }}
          >
            Edit
          </Typography>
        )}
      </div>

      <Select
        options={
          templates?.map((t) => ({
            label: t.name,
            value: t.uuid,
          })) || []
        }
        value={defaultTemplate?.uuid}
        onChange={(v) => v && handleTemplateSelection(v)}
        placeholder="Select default template "
        isLoading={updateTemplateMutation.isLoading}
        className="mb-4"
      />
    </div>
  )
}

export default MemberViewSettings
