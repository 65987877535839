import React from 'react'

import { useHistory } from 'react-router-dom'

import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import { useAccessControl } from 'global/AccessControl'
import DataCardV2 from 'ui-components/DataCardV2'

import { htmlTextContent } from 'utils/html'
import { CompanyListItemBlueprint } from 'utils/types'

interface Props {
  listItem: CompanyListItemBlueprint
  teamSlug: string
  openJobModal: (job?: IJob) => void
}

const JobRow: React.FC<Props> = ({ listItem, openJobModal, teamSlug }) => {
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const history = useHistory()
  const job = listItem.job

  const dropDownMenu = {
    menuItems: [
      {
        label: 'Edit',
        onSelect: () => {
          openJobModal()
        },
      },
    ],
  }

  return (
    <DataCardV2
      onClick={() =>
        history.push(`/${teamSlug}/lists/${listItem.company_list.slug}/${listItem.uuid}`)
      }
      avatar={<i className="far fa-briefcase" />}
      className="rounded-md"
      key={job.uuid}
      title={job.title}
      description={job.description && htmlTextContent(job.description)}
      dropDownMenu={isAdminOrEmployee ? dropDownMenu : undefined}
    />
  )
}

export default JobRow
