import * as React from 'react'

import axios from 'axios'

import { CheckBox } from 'global/Input'
import { useAppDispatch, useCurrentUser } from 'store/hooks'
import { setCurrentUser } from 'store/reducers/currentUserReducer'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'

const FooterToggle = (props) => {
  const dispatch = useAppDispatch()
  const { user } = useCurrentUser()
  const [data, setData] = React.useState({})

  function loadData() {
    axios.get('/api/orders').then((response) => {
      const { data } = response.data
      setData(data)
    })
  }

  React.useEffect(() => {
    loadData()
  }, [])

  if (!data) {
    return <div />
  }

  function hideCabalFooterChanged(e: React.SyntheticEvent) {
    api
      .updateCurrentProfile({
        hide_cabal_email_footer: e.target.checked,
      })
      .then((response) => {
        const { profile } = response.data

        dispatch(setCurrentUser(profile))

        cabalToast({ style: 'success', content: 'Setting saved!' })
      })
  }

  return (
    <>
      <CheckBox
        value={true}
        checked={user.hide_cabal_email_footer}
        name="hide_cabal_email_footer"
        onChange={hideCabalFooterChanged}
        label={"Hide 'Sent via Cabal' footer in email"}
      />
    </>
  )
}

export default FooterToggle
