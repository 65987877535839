import * as React from 'react'

import memoizeOne from 'memoize-one'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useDeepCompareMemo } from 'use-deep-compare'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { Select } from 'global/Input'
import Table, { Column, Row } from 'global/Table'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { TeamUser } from 'utils/types'

import InviteTeammateModal from '../../Members/InviteTeammateModal'

interface Props {
  users: TeamUser[]
  teamSlug: string
}

const TableHeading = styled.div`
  ${tw`whitespace-nowrap text-left p-3 py-2 sticky top-0`}
  background-color: ${({ theme }) => theme.table_header};
  color: ${({ theme }) => theme.colors.gray};

  &:first-of-type {
    ${tw`rounded-tl rounded-bl`}
  }

  &:last-of-type {
    ${tw`rounded-br rounded-tr`}
  }
`

const TableData = styled.div`
  ${tw`p-3`}
  color: ${({ theme }) => theme.colors.primary};
  border-bottom-color: ${({ theme }) => theme.colors.border};
  border-top: none;
  border-left: none;
  border-right: none;
`

const TableRow = styled.div`
  ${tw`relative`}
  border-bottom: ${({ theme }) => theme.border};
`

const TeamTable: React.VFC<Props> = ({ users, teamSlug }) => {
  const { user: currentUser } = useCurrentUser()
  const [showAddTeammateModal, setShowAddTeammateModal] = React.useState<boolean>(false)
  const { reloadTeam } = useTeam(teamSlug)

  const queryClient = useQueryClient()

  const {
    mutate,
    variables: updateTeamUserVariables,
    isLoading: updatingTeamUser,
  } = useMutation(
    ['updateTeamUserRole', teamSlug],
    (params: { user: TeamUser; role: string }) =>
      callApi(api.updateTeamUserRole, teamSlug, params.user.uuid, params.role),
    {
      onSuccess: (response) => {
        queryClient.setQueriesData('getTeamUsers', response)
        reloadTeam()
        cabalToast({ style: 'success', content: 'Teammate permissions updated' })
      },
    },
  )

  const { mutate: deleteTeamUser, isLoading: deletingTeamUser } = useMutation(
    ['deleteTeamUser', teamSlug],
    (uuid: string) => callApi(api.deleteTeamUser, teamSlug, uuid),
    {
      onSuccess: (response) => {
        queryClient.setQueriesData('getTeamUsers', response)
        cabalToast({ style: 'success', content: 'Teammate successfully deleted' })
      },
    },
  )

  const onDeleteTeamUser = React.useCallback(
    (user: TeamUser) => {
      if (confirm(`Are you sure that you want to remove ${user.name}?`)) {
        deleteTeamUser(user.uuid)
      }
    },
    [deleteTeamUser],
  )

  const columns: Column<Row>[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Permission',
        accessor: 'team_role',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'delete',
        disableSortBy: true,
      },
    ],
    [],
  )

  const onUserTeamRoleChange = React.useCallback(
    (user: TeamUser, role: string) => {
      mutate({ user, role })
    },
    [mutate],
  )

  const data = useDeepCompareMemo(
    () =>
      users.map<Row<TeamUser>>((user) => {
        return {
          id: user.uuid,
          name: (
            <div className="flex items-center">
              <div className="flex-initial">
                <Avatar name={user.name} size="3rem" src={user.avatar_url || undefined} round />
              </div>
              <div className="flex-1 flex flex-col ml-3">
                <div className="mb-1.5">
                  <Typography textAlign="left" className="whitespace-nowrap block">
                    <div>{user.name}</div>
                    <div className="text-sm text-secondary">{user.email}</div>
                    {user.team_role == 'admin' && user.restrict_admin_agreements_access && (
                      <div className="text-sm text-secondary italic">Limited admin access</div>
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          ),
          title: user.title,
          team_role:
            currentUser.uuid === user.uuid ? (
              <Typography className="ml-2">
                {user.team_role === 'admin' ? 'Admin' : 'Employee'}
              </Typography>
            ) : (
              <Select
                style={{ maxWidth: 100 }}
                value={user.team_role}
                onChange={(option) => option && onUserTeamRoleChange(user, option)}
                placeholder="Permission level"
                options={[
                  { label: 'Admin', value: 'admin' },
                  { label: 'Employee', value: 'member' },
                ]}
                isLoading={updatingTeamUser && updateTeamUserVariables?.user.uuid === user.uuid}
                removeFocusRing
                removeBorder
                lightBg
              />
            ),
          delete:
            currentUser.uuid !== user.uuid ? (
              <div className="text-right">
                <CabalButton
                  variant="tertiary"
                  leftIcon={<i className="far fa-trash" />}
                  working={deletingTeamUser}
                  onClick={() => onDeleteTeamUser(user)}
                />
              </div>
            ) : null,
          data: user,
        }
      }),
    [
      users,
      updatingTeamUser,
      updateTeamUserVariables,
      onUserTeamRoleChange,
      deletingTeamUser,
      onDeleteTeamUser,
      currentUser,
    ],
  )

  return (
    <div id="teammates">
      <InviteTeammateModal
        show={showAddTeammateModal}
        onHide={() => setShowAddTeammateModal(false)}
        teamSlug={teamSlug}
        reload={() => reloadTeam()}
      />
      <div className="flex justify-between items-center flex-initial mt-4">
        <div>
          <Typography fontSize="18" fontWeight={600} className="pr-2 mb-4">
            Team
          </Typography>
          <Typography color="gray">{data.length}</Typography>
        </div>
        <div className="flex items-center">
          <CabalButton
            variant="link"
            onClick={() => {
              setShowAddTeammateModal(true)
            }}
            data-testid="add-employee"
            leftIcon={<i className="far fa-user-plus pr-1" />}
          >
            Add teammates
          </CabalButton>
        </div>
      </div>
      <div className="flex-1 overflow-auto mt-4">
        <Table columns={columns} data={data} tr={TableRow} td={TableData} th={TableHeading} />
      </div>
    </div>
  )
}

export default TeamTable
