import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

interface Props {
  tags?: string[]
}

const Pill = styled.div<{
  backgroundColor: string
  color: string
}>`
  ${tw`flex items-center justify-center rounded-full gap-1 py-1 px-1.5`}
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: 10px;
`

const ResourceDataTags = ({ tags }: Props) => {
  if (!tags || tags?.length === 0) return null

  const visibleTags = tags.slice(0, 2)
  const hiddenTagsCount = tags.length - visibleTags.length

  return (
    <div className="flex gap-1 items-center">
      {visibleTags.map((tag) => (
        <Pill color="#5C69D1" backgroundColor="#DCE5FB" key={tag}>
          {tag}
        </Pill>
      ))}
      {hiddenTagsCount > 0 && (
        <Typography fontSize="12" color="rain">
          +{hiddenTagsCount}
        </Typography>
      )}
    </div>
  )
}

export default ResourceDataTags
