import axios, { AxiosResponse } from 'axios'
import { AsyncReturnType, ValueOf } from 'type-fest'

import introRequest from 'utils/api/introRequest'
import resourceList from 'utils/api/resourceList'
import revisions from 'utils/api/revisions'
import { isExtension } from 'utils/env'
import {
  CurrentUserSettings,
  DraftMessageBlueprint,
  GetJobsResponse,
  Job,
  SlackSettings,
  TeamSettings,
  UploadBlueprint,
} from 'utils/types'
import { GlobalList } from 'utils/types/globalList'

import activity from './activity'
import advisor from './advisor'
import advisorGroups from './advisorGroups'
import advisorOffers from './advisorOffers'
import affinity from './affinity'
import agreement from './agreement'
import ahoy from './ahoy'
import analytics from './analytics'
import auth from './auth'
import blocks from './blocks'
import candidateProfiles from './candidateProfiles'
import capTable from './capTable'
import clearbit from './clearbit'
import collaborators from './collaborators'
import comments from './comments'
import company from './company'
import companyList from './companyList'
import connection_intro_requests from './connection_intro_requests'
import connections from './connections'
import contactPoints from './contactPoints'
import contacts from './contacts'
import contributions from './contributions'
import customFields from './customFields'
import digestSubscriptions from './digestSubscriptions'
import documents from './documents'
import emailSnippets from './emailSnippets'
import equityTiers from './equityTiers'
import extension from './extension'
import externalSources from './externalSources'
import favorite from './favorite'
import feed from './feed'
import forYou from './forYou'
import gatekeeper from './gatekeeper'
import globalPeople from './globalPeople'
import globalPersonEmails from './globalPersonEmails'
import group from './group'
import groupMessages from './groupMessages'
import inbox from './inbox'
import introTable from './introTable'
import investor from './investor'
import jobs from './jobs'
import lever from './lever'
import message from './message'
import notes from './notes'
import notifications from './notifications'
import offers from './offers'
import opps from './opps'
import people from './people'
import portfolio from './portfolio'
import profile from './profile'
import referral from './referral'
import requests from './requests'
import sfdcFields from './sfdcFields'
import tags from './tags'
import team from './team'
import teamUsers from './teamUsers'
import templates from './templates'
import twitter from './twitter'
import uniqueAccessLinks from './uniqueAccessLinks'
import userEmail from './userEmail'
import verifications from './verifications'
import vote from './vote'

const api = {
  ...advisor,
  ...advisorGroups,
  ...advisorOffers,
  ...affinity,
  ...agreement,
  ...analytics,
  ...auth,
  ...blocks,
  ...capTable,
  ...candidateProfiles,
  ...clearbit,
  ...collaborators,
  ...comments,
  ...company,
  ...connections,
  ...contacts,
  ...digestSubscriptions,
  ...documents,
  ...jobs,
  ...emailSnippets,
  ...equityTiers,
  ...externalSources,
  ...favorite,
  ...group,
  ...groupMessages,
  ...investor,
  ...lever,
  ...message,
  ...notes,
  ...notifications,
  ...offers,
  ...opps,
  ...portfolio,
  ...profile,
  ...referral,
  ...requests,
  ...tags,
  ...team,
  ...teamUsers,
  ...templates,
  ...twitter,
  ...userEmail,
  ...vote,
  ...activity,
  ...sfdcFields,
  ...feed,
  ...forYou,
  ...customFields,
  ...inbox,
  ...companyList,
  ...uniqueAccessLinks,
  ...verifications,
  ...people,
  ...contributions,
  ...connection_intro_requests,
  ...extension,
  ...ahoy,
  ...revisions,
  ...gatekeeper,
  ...introRequest,
  ...globalPersonEmails,
  ...globalPeople,
  ...resourceList,
  ...contactPoints,
  ...introTable,

  getJobs: (team_slug: string) => {
    return axios.get<GetJobsResponse>(`/api/jobs`, {
      params: {
        team_slug,
      },
    })
  },

  getGlobalLists: () => {
    return axios.get<GlobalList[]>(`/api/global_lists`)
  },

  addGlobalListsToTeam: (team_slug: string, listUuid: string) => {
    return axios.post(`/api/global_lists/${listUuid}/add_to_team`, {
      params: {
        team_slug,
      },
    })
  },

  addJobs: (team_slug: string, jobs: Job[]) => {
    return axios.post(`/api/jobs`, {
      team_slug,
      jobs,
    })
  },

  removeJobs: (team_slug: string, job_uuids: string[]) => {
    return axios.delete(`/api/jobs/remove_jobs`, {
      params: {
        team_slug,
        job_uuids,
      },
    })
  },

  getEquity: () => {
    return axios.get(`/api/equity`)
  },

  getCurrentAdvisorEquity: (team_slug: string) => {
    return axios.get(`/api/equity/${team_slug}`)
  },

  updateUserSetting: (name: keyof CurrentUserSettings, value: any) => {
    const params = { name, value }
    return axios.post<{ settings: CurrentUserSettings }>(`/api/settings?for=current_user`, params)
  },

  updateTeamSetting: (name: string, value: ValueOf<TeamSettings['settings']>) => {
    const params = { name, value, for: 'current_team' }
    return axios.post(`/api/settings`, params)
  },

  getUserSettings: () => {
    return axios.get<{ settings: CurrentUserSettings }>(`/api/settings?for=current_user`)
  },

  getUserSetting: (name: string, scope: string) => {
    const params = { name, for: scope }
    return axios.get(`/api/settings`, { params })
  },

  getTeamSettings: (params = {}) => {
    params.for ||= 'current_team'
    return axios.get<TeamSettings>(`/api/settings`, { params })
  },

  getTeamSetting: (name: string, scope = 'current_team', team_slug: string = null) => {
    const params: any = { name, for: scope }
    if (team_slug) params['team'] = team_slug
    return axios.get<{
      name: string
      value?: unknown
      error?: string
    }>(`/api/settings`, { params })
  },

  updateTeamSettings: (settings: any) => {
    const params = { settings, for: 'current_team' }
    return axios.post('/api/settings/mass_update', params)
  },

  getSlackSettings: (teamSlug: string) => {
    return axios.get(`/api/integrations/slack/settings`, {
      params: { team_slug: teamSlug },
    })
  },

  searchSlackChannel: (channel: string) => {
    return axios.get(`/api/integrations/slack/channel`, {
      params: { channel },
    })
  },

  saveSlackSettings: (teamSlug: string, settings: SlackSettings) => {
    return axios.post(`/api/integrations/slack/update_settings`, {
      settings,
      team_slug: teamSlug,
    })
  },

  sendTestEmail: (params: any) => {
    return axios.post(`/api/messages/send_test_email`, params)
  },

  sendDraftTestEmail: (team_slug: string, message: DraftMessageBlueprint) => {
    return axios.post(`/api/drafts/${message.uuid}/send_test_email`, {
      team_slug,
      message,
    })
  },

  getAdvisorMessageCounts: (advisor_id: number) => {
    return axios.get(`/api/advisors/${advisor_id}/gmail_messages_counts`)
  },

  getAllAdvisorMessageCounts: () => {
    return axios.get(`/api/advisors/all_gmail_messages_counts`)
  },

  getAdvisorGmailMessages: (advisor_id: number) => {
    return axios.get(`/api/advisors/${advisor_id}/gmail_messages`)
  },

  getWidgets: () => {
    return axios.get('/api/widgets')
  },

  createWidget: (params: any) => {
    return axios.post(`/api/widgets`, params)
  },

  updateWidget: (uuid: number, params: any) => {
    return axios.put(`/api/widgets/${uuid}`, params)
  },

  deleteWidget: (uuid: string) => {
    return axios.delete(`/api/widgets/${uuid}`)
  },

  getJobStatus: (uuid: string) => {
    return axios.get(`/api/job_statuses/${uuid}`)
  },

  getBatch: (batch_id: string, params = {}) => {
    return axios.get(`/superadmin/batches/${batch_id}.json`, { params })
  },

  createQaUpload: (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('mime_type', file.type)
    formData.append('file_name', file.name || file.webkitRelativePath.split('/').pop() || '')

    return axios.post<UploadBlueprint>(`/api/uploads/qa_upload`, formData)
  },

  getJwt: () => {
    return axios.get<{ jwt: string }>(`/api/profiles/jwt`)
  },
}

export interface SfdcInfo {
  stage_names: string[]
}

export type API = typeof api
export type extractGeneric<Type> = Type extends AxiosResponse<infer X> ? X : never

export async function callApi<A extends ValueOf<API>>(api: A, ...args: Parameters<A>) {
  if (typeof api !== 'function') throw "`api` can't be string, please pass a function"

  if (isExtension()) {
    const { callExtApi } = require('extension/utils/extApi')
    const response = await callExtApi(api, ...args)

    return response as extractGeneric<AsyncReturnType<A>>
  }

  const response = await api(...(args as Parameters<A>))
  return response.data as extractGeneric<AsyncReturnType<A>>
}

export default api
