import React from 'react'

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'

interface Props {
  logo?: string
  title: string
  description: React.ReactNode
}

const ListWrapper = styled.div`
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
`

const ListHeader: React.FC<Props> = ({ logo, title, description }) => {
  const history = useHistory()

  return (
    <ListWrapper className="sticky top-0 z-10">
      <div className="flex items-center justify-between mt-4 mb-2">
        <div>
          <div className="flex items-center space-x-2">
            <CabalButton
              onClick={() => history.goBack()}
              variant="tertiary"
              leftIcon={<i className="far fa-chevron-left" />}
              padding="0"
            />
            {logo && <Avatar size="40px" src={logo} name="title" />}
            <Typography fontSize="28" fontWeight={600} data-testid="page-header-title">
              {title}
            </Typography>
          </div>
          <Typography fontSize="12" color="fog" className="flex items-center">
            {description}
          </Typography>
        </div>
      </div>
    </ListWrapper>
  )
}

export default ListHeader
