import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import { useAskContextModal } from 'components/AsksContextModal'
import { useComposer } from 'components/Composer'
import { useComposeDealBoardDraft } from 'components/Composer/hooks'
import CreateConnectionIntroRequestModal from 'components/CreateConnectionIntroRequestModal'
import GetIntroModal from 'components/GetIntro/GetIntroModal'
import AdvisorMore from 'containers/Connections/AdvisorMore'
import AdvisorPill from 'containers/Connections/AdvisorPill'
import { CreateSalesListModal } from 'containers/IntroRequestModal/components/Modals/Sales/CreateSalesListModal'
import { RequestIntroConnections } from 'containers/Portfolio/MemberView/RequestIntroConnections'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { DropdownMenuItem } from 'global/DropDownMenu'
import { RenderModal, useModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import { DataCard } from 'ui-components/DataCard'
import DataCardV2 from 'ui-components/DataCardV2'
import PillV2 from 'ui-components/PillV2'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'
import {
  AdvisorModel,
  CompanyBlueprint,
  GlobalPersonBlueprint,
  PersonBlueprint,
  RequestModel,
  UserBlueprint,
} from 'utils/types'

export const Connections = styled.div`
  ${tw`px-4 w-full flex`}
`

export const Members = styled.div`
  ${tw`px-4`};
  width: 150px;
`

const ActionArea = styled.div`
  ${tw`items-center`}
`

const StyledCard = styled.div`
  ${tw`flex items-center p-2 rounded mb-1.5 w-full`}
  background-color: ${({ theme }) => theme.table_child};
`

interface Props {
  advisors: AdvisorModel[]
  requestable?: CompanyBlueprint
  requestableType?: string
  person: GlobalPersonBlueprint
  companySlug: string
  request?: RequestModel
  refetch: () => void
  teamRequests?: any[]
  hideAvatar?: boolean
  view?: 'list' | 'grid'
}

const ConnectionsTableRow: React.VFC<Props> = ({
  advisors,
  person,
  requestable,
  requestableType,
  companySlug,
  request,
  refetch,
  teamRequests = [],
  hideAvatar = false,
  view = 'list',
}) => {
  const teamSlug = useTeamSlug() || companySlug
  const { team } = useTeam(teamSlug)
  const { compose } = useComposer(teamSlug)
  const { canUseNewRequestFlow } = useAccessControl(teamSlug)
  const { showModal } = useModal()
  const showAskContextModal = useAskContextModal({
    source: person,
    sourceType: 'GlobalPerson',
    sourceId: person.uuid,
    teamSlug: teamSlug,
  })
  const { composeDealBoardDraft } = useComposeDealBoardDraft()

  const renderIntroRequestFlow: RenderModal = (resolve) => (
    <GetIntroModal
      advisors={advisors}
      person={person}
      onHide={() => resolve()}
      teamSlug={teamSlug}
      requestable={requestable}
    />
  )

  const generateIntroRequestDraft = () => {
    callApi(api.getDefaultTemplate, teamSlug, 'intro_template').then((resp) => {
      const template = resp.template
      const subject = template.subject
        .replaceAll('{{connection_full_name}}', person.name)
        .replaceAll('{{connection_first_name}}', person.first_name)
      const body = template.body
        .replaceAll(
          '{{connection_full_name}}',
          person.linkedin_url
            ? `<a href="${person.linkedin_url}" target="_blank" rel="nofollow noopener">${person.name}</a>`
            : person.name,
        )
        .replace(
          '{{connection_first_name}}',
          person.linkedin_url
            ? `<a href="${person.linkedin_url}" target="_blank" rel="nofollow noopener">${person.first_name}</a>`
            : person.first_name,
        )
      const recipientAdvisors = advisors.map((advisor) => {
        return { type: 'advisor', value: advisor.uuid, label: advisor.name }
      })

      callApi(api.createRequest, teamSlug, {
        company_id: requestable?.id,
        global_person_id: person.uuid,
        request_type: 'connection_intro_queue',
        data: {
          subject: subject,
          body: body,
          req_intros_to: recipientAdvisors,
          company: requestable?.name,
          person,
        },
      }).then((request) => {
        compose({ messageUuid: request.message_uuid })
      })
    })
  }

  const onRequestIntro = () => {
    showModal(
      (resolve) => (
        <CreateConnectionIntroRequestModal teamSlug={teamSlug} person={person} resolve={resolve} />
      ),
      'create_connection_request',
    )
  }

  const renderSalesListModal: RenderModal = (advisor, resolve) => (
    <RequestIntroConnections
      requestable={person}
      resolve={resolve}
      teamSlug={teamSlug}
      selectedAdvisor={advisor}
      facilitators={advisors}
    />
  )

  const prepareAdvisorData = (advisors: AdvisorModel[]) => {
    return advisors.map((a) => {
      const advisorRequests = teamRequests.filter(
        (r) => r.advisor_ids && r.advisor_ids.includes(a.uuid),
      )
      const lastRequest = advisorRequests[0]
      return {
        ...a,
        last_request_at: lastRequest?.completed_at,
        request_count: advisorRequests.length,
        last_subject: lastRequest?.subject,
      }
    })
  }

  const shareIntroDraftToAdvisor = (advisor: UserBlueprint) => {
    composeDealBoardDraft({
      user_uuid: advisor.uuid,
      person: person,
      company_id: requestable?.id,
      teamSlug: teamSlug,
      list_type: requestable?.item_type,
    })
  }

  const renderAdvisors = (advisors: AdvisorModel[]) => {
    const showCount = 3
    const preppedAdvisors = prepareAdvisorData(advisors)
    const hiddenAdvisors = preppedAdvisors.slice(showCount)

    return (
      <div>
        <div className="flex flex-wrap gap-1 mt-2">
          <Typography component="button" color="link" data-testid="connection-advisors-link">
            <i className="far fa-arrows-left-right" />{' '}
            {preppedAdvisors.slice(0, showCount).map((advisor, i) => {
              const advisorName =
                advisor.last_name && advisor.first_name
                  ? `${advisor.first_name} ${advisor.last_name?.charAt(0)}.`
                  : advisor.name
              const isLastAdvisor = preppedAdvisors.length === i + 1 && hiddenAdvisors.length === 0
              const prevElement = i + 1 == showCount - 1

              return (
                <span
                  key={`advisor-${advisor.id}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    showModal(
                      (advisor, resolve) => renderSalesListModal(advisor, resolve),
                      'request-intro-connections-modal',
                    )
                  }}
                >
                  {advisorName}
                  {!isLastAdvisor
                    ? hiddenAdvisors.length > 0
                      ? prevElement
                        ? ', '
                        : ' '
                      : prevElement
                      ? ', '
                      : ' and '
                    : ''}
                </span>
              )
            })}
            {hiddenAdvisors.length > 1 && <span>and {hiddenAdvisors.length} others</span>}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <>
      <DataCardV2
        className={cn({
          'h-[76px] rounded-none hover:rounded': view === 'list',
        })}
        onClick={() => showModal(renderSalesListModal, 'render_sales_list_modal')}
        title={person.full_name}
        avatar={
          <Avatar
            name={!hideAvatar ? person.full_name : undefined}
            src={!hideAvatar ? person.image_url || '' : undefined}
            size={view === 'grid' ? '72' : '32'}
          />
        }
        titleIcon={
          person.linkedin_url && (
            <Typography
              color="fog"
              fontSize={view === 'grid' ? '16' : '12'}
              onClick={() => window.open(person.linkedin_url, '_blank')}
              component="button"
            >
              <i className="fab fa-linkedin"></i>
            </Typography>
          )
        }
        description={
          <>
            <Typography
              color="rain_fog"
              fontSize="12"
              lineHeight={1}
              className="flex items-center gap-1.5"
            >
              {person.headline}
            </Typography>
            {view === 'list' && renderAdvisors(advisors)}
          </>
        }
        descriptionBottom={
          advisors && [
            <>
              <i className="far fa-arrows-left-right" /> {advisors.length}
            </>,
          ]
        }
        view={view}
        cta={
          <div className="flex gap-3">
            <CabalButton
              variant={view === 'list' ? 'link' : 'primary'}
              onClick={() => showModal(renderSalesListModal, 'render_sales_list_modal')}
              className="w-full"
            >
              <Typography component="button">
                Request Intro
              </Typography>
            </CabalButton>
          </div>
        }
      />
    </>
  )
}

export default ConnectionsTableRow
