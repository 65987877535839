import React from 'react'

import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import AdvisorProgram from 'containers/Settings/AdvisorProgram'
import Agreements from 'containers/Settings/Agreements'
import AskSettings from 'containers/Settings/AsksSettings'
import Company from 'containers/Settings/Company'
import Messages from 'containers/Settings/Company/Messages'
import Integration from 'containers/Settings/Integration'
import Investors from 'containers/Settings/Investors'
import Members from 'containers/Settings/Members'
// import Billing from 'containers/Settings/Billing'
import TeamUsers from 'containers/Settings/TeamUsers'
import { useAccessControl } from 'global/AccessControl'
import PageWrapper from 'global/PageWrapper'
import Tabs from 'global/Tabs'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'

const CompanyAdmin = () => {
  const teamSlug = useTeamSlug()
  const { user } = useCurrentUser()
  const { team } = useTeam(teamSlug)
  const { canViewEquity, canManageCompany } = useAccessControl(teamSlug)
  const location = useLocation()

  const tabs = [
    { label: 'Company', id: 'company', component: <Company /> },
    { label: 'Integrations', id: 'integrations', component: <Integration /> },
    { label: 'Team', component: <TeamUsers />, id: 'team' },
    { label: 'Messages', component: <Messages />, id: 'analytics' },
    { label: 'Asks', id: 'asks', component: <AskSettings /> },
    { label: 'Agreements', id: 'agreements', component: <Agreements /> },
  ]

  if (team?.enable_portfolio) {
    tabs.push({ label: 'Portfolio', id: 'portfolio', component: <Investors /> })
  }

  if (canViewEquity) {
    tabs.push({ label: 'Advisor Program', id: 'advisor_program', component: <AdvisorProgram /> })
  }

  // if (team?.flags.custom_fields) {
  //   tabs.push({ label: 'Members', component: <Members />, id: 'members' })
  // }

  // if (canManageBilling) {
  //   tabs.push({ label: 'Billing', component: <Billing />, id: 'billing' })
  // }

  const isTalentCoordinator = user.uuid === team?.talent_coordinator

  if (!canManageCompany && !isTalentCoordinator) return null

  return (
    <PageWrapper title="Settings">
      <div className="pb-12 z-30">
        <Tabs tabListclassName="justify-left" data={tabs} pushSubRoutes nestedSticky={true} />
      </div>
    </PageWrapper>
  )
}

export default CompanyAdmin
