import * as React from 'react'
import { formatNum } from 'utils/ui'
import { NumberFormattedInput } from 'global/Input'
import Typography from 'global/Typography'
interface CapGroupProps {
  value: number
  onChange?: (e: {
    target: {
      value: string
    }
  }) => void
  text: string
  percent: number
  [key: string]: unknown
  percentOf: string | null
}

const CapGroup: React.VFC<CapGroupProps> = ({
  text,
  percent,
  value,
  onChange,
  percentOf,
  ...restProps
}) => {
  return (
    <div className="pb-4">
      <Typography color="admin_label" fontSize={'12'} className="block pb-2">
        {text}
        <span className=" ml-1">
          ({percent ? formatNum(percent, 3) : 0}%{percentOf ? ` of ${percentOf}` : ''})
        </span>
      </Typography>

      <NumberFormattedInput
        value={value}
        onChange={onChange}
        disabled={onChange ? false : true}
        readOnly={!onChange}
        placeholder="Number of shares"
        className="w-full"
        {...restProps}
      />
    </div>
  )
}

export default CapGroup
