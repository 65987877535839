import React, { useState } from 'react'

import { mapValues, trim } from 'lodash'
import { useParams } from 'react-router-dom'

import AdvisorGroupsEdit from 'containers/ViewAdvisor/AdvisorGroupsEdit'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import {
  CheckBox,
  DatePickerModal,
  NumberFormattedInput,
  Select,
  TextArea,
  TextInput,
} from 'global/Input'
import Modal from 'global/Modal'
import {
  ModalDivider,
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionHeader,
  ModalSectionWrapper,
} from 'global/Modal/styles'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'
import { useAppDispatch, useCurrentUser, useGroups } from 'store/hooks'
import { removeAdvisor, updateAdvisor } from 'store/reducers/advisorsReducer'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { absoluteHtmlContent } from 'utils/html'
import { AdvisorModel, CompanySlugParam, CreateAdvisorRequest, GroupModel, Team } from 'utils/types'

interface Props {
  show: boolean
  onHide: () => void
  advisor: AdvisorModel
  team: Team
  onSave?: () => void
  onDelete?: () => void
  showDelete?: boolean
  isEditing?: boolean
}

type Advisor = AdvisorModel | Partial<CreateAdvisorRequest>

export const SaveMemberModal: React.VFC<Props> = ({
  show,
  onHide,
  advisor: originalAdvisor,
  team,
  onSave,
  onDelete,
  isEditing = false,
}) => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { canViewGroups } = useAccessControl(team.slug || companySlug)

  const dispatch = useAppDispatch()
  const { groups } = useGroups(team.slug || companySlug)
  const { user } = useCurrentUser()

  const [advisor, setAdvisor] = useState<Advisor>(originalAdvisor)
  const [working, toggleWorking] = useState(false)

  const [isAdvisor, setIsAdvisor] = useState(false)
  const [isInvestor, setIsInvestor] = useState(false)
  const [validations, setValidations] = useState<boolean[]>([!!advisor.email, !!advisor.first_name])

  const updateField = (key: keyof AdvisorModel, value: unknown) => {
    setAdvisor({ ...advisor, [key]: value })
  }

  const canDelete = advisor.user_uuid != user.uuid

  React.useEffect(() => {
    if (originalAdvisor.uuid != advisor.uuid) {
      setAdvisor(originalAdvisor)
    }
  }, [originalAdvisor, advisor])

  const handleSave = () => {
    toggleWorking(true)

    const advisorData = mapValues(advisor, (value) => {
      return typeof value === 'string' ? trim(value) : value
    })

    const request = isEditing
      ? api.saveAdvisor(advisorData as AdvisorModel)
      : api.createAdvisor(advisorData as CreateAdvisorRequest)

    request
      .then(({ data: { advisor, errors } }) => {
        if (advisor) {
          dispatch(
            updateAdvisor({
              uuid: advisor.uuid,
              advisor,
            }),
          )

          onSave?.()
        } else if (errors) {
          for (const error in errors) {
            cabalToast({ style: 'error', content: `${error}: ${errors[error]}` })
          }
        }
      })
      .finally(() => {
        toggleWorking(false)
      })
  }

  const handleDelete = async () => {
    const uuid = (advisor as AdvisorModel).uuid
    if (!uuid) return

    if (
      confirm('Are you sure you want to delete this advisor?') &&
      prompt("Please type CONFIRM if you'd like to continue deleting this advisor.") === 'CONFIRM'
    ) {
      await api.deleteAdvisor(uuid)

      cabalToast({ style: 'success', content: `Advisor has been deleted.` })

      dispatch(removeAdvisor(uuid))

      onDelete?.()
    }
  }

  const setValidation = (i: number, valid: boolean) => {
    setValidations((prev) => {
      const updatedValidations = [...prev]
      updatedValidations[i] = valid
      return updatedValidations
    })
  }

  const canSave = !!advisor.email && !!advisor.first_name && validations.every((v) => !!v)

  return (
    <Modal
      dangerouslyBypassFocusLock
      size="sm"
      show={show}
      onHide={onHide}
      header={isEditing ? 'Edit member' : 'Add member to directory'}
      leftActions={
        !!onDelete && (
          <CabalButton variant={'destructive'} onClick={handleDelete} disabled={!canDelete}>
            Delete
          </CabalButton>
        )
      }
      rightActions={
        <CabalButton
          className="ml-auto"
          data-testid="save-member-button"
          variant="primary"
          working={working}
          disabled={!canSave}
          onClick={handleSave}
        >
          Save member
        </CabalButton>
      }
    >
      <div data-testid="save-member-modal">
        <>
          <ModalSectionWrapper>
            <ModalSectionHeader>Personal</ModalSectionHeader>
            <ModalInputWrapper>
              <ModalInputLabel>Primary email*</ModalInputLabel>
              <TextInput
                className="w-full"
                data-testid="member-email"
                placeholder="Primary email*"
                value={advisor.email}
                valueType="email"
                required
                onChange={(e, valid) => {
                  updateField('email', e.currentTarget.value)
                  setValidation(0, !!valid)
                }}
                autoFocus
              />
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>First Name*</ModalInputLabel>
              <TextInput
                className="w-full"
                data-testid="member-first-name"
                placeholder="First Name*"
                required
                valueType="non-empty"
                value={advisor.first_name}
                onChange={(e, valid) => {
                  updateField('first_name', e.currentTarget.value)
                  setValidation(1, !!valid)
                }}
              />
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>Last Name</ModalInputLabel>
              <TextInput
                className="w-full"
                placeholder="Last Name"
                value={advisor.last_name}
                onChange={(e) => updateField('last_name', e.currentTarget.value)}
              />
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>LinkedIn Profile URL</ModalInputLabel>
              <TextInput
                className="w-full"
                placeholder="LinkedIn Profile URL"
                value={advisor.linkedin_url}
                onChange={(e) => updateField('linkedin_url', e.currentTarget.value)}
              />
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>Location</ModalInputLabel>
              <TextInput
                className="w-full"
                placeholder="Location"
                value={advisor.location}
                onChange={(e) => updateField('location', e.currentTarget.value)}
              />
            </ModalInputWrapper>

            <ModalInputWrapper>
              <ModalInputLabel>Bio for Intros</ModalInputLabel>
              <CKEditor
                value={advisor.bio || ''}
                placeholder="Bio for Intros"
                onChange={(v) => updateField('bio', absoluteHtmlContent(v) || '')}
                height={100}
              />
            </ModalInputWrapper>
          </ModalSectionWrapper>

          {groups && canViewGroups && (
            <>
              <ModalDivider />
              <ModalSectionWrapper>
                <ModalSectionHeader>Groups</ModalSectionHeader>
                <ModalInputWrapper>
                  <ModalInputLabel>Assigned Groups</ModalInputLabel>
                  <AdvisorGroupsEdit
                    teamSlug={team.slug || companySlug}
                    advisor={originalAdvisor}
                    onChange={(uuids) => updateField('group_uuids', uuids)}
                  />
                </ModalInputWrapper>
              </ModalSectionWrapper>
            </>
          )}

          <ModalDivider />
          <ModalSectionWrapper>
            <ModalSectionHeader>Company</ModalSectionHeader>
            <ModalInputWrapper>
              <ModalInputLabel>Company Name</ModalInputLabel>
              <TextInput
                className="w-full mb-2"
                placeholder="Company Name"
                value={advisor.company_name || ''}
                onChange={(e) => updateField('company_name', e.target.value)}
              />
            </ModalInputWrapper>
            <ModalInputWrapper>
              <ModalInputLabel>Title (eg. CEO)</ModalInputLabel>
              <TextInput
                className="w-full mb-2"
                placeholder="Title (eg. CEO)"
                value={advisor.title || ''}
                onChange={(e) => updateField('title', e.target.value)}
              />
            </ModalInputWrapper>
          </ModalSectionWrapper>
        </>

        {!isEditing && (
          <>
            <CheckBox
              className="mr-2 mt-6"
              label="Add advisor grant"
              checked={isAdvisor}
              onChange={(e) => setIsAdvisor(e.target.checked)}
            />

            {isAdvisor && (
              <>
                <Typography
                  fontSize="14"
                  className="mb-2 mt-6"
                  component="h4"
                  fontWeight={600}
                  color="fog"
                >
                  Advisor Grant Details{' '}
                  <Typography fontSize="12" color="gray">
                    (optional)
                  </Typography>
                </Typography>

                <NumberFormattedInput
                  className="w-full mb-2"
                  placeholder="Number of granted shares"
                  value={advisor.granted_shares}
                  onChange={(e) => updateField('granted_shares', e.target.value)}
                  autoFocus
                />

                <DatePickerModal
                  className="w-full mb-2"
                  placeholder="Vesting Start Date"
                  id="vesting_start_date"
                  date={advisor.vesting_start_date}
                  onDateChange={(e) => updateField('vesting_start_date', e)}
                />

                {team?.uses_effective_date && (
                  <DatePickerModal
                    className="w-full mb-2"
                    placeholder="Effective Date"
                    id="effective_date"
                    date={advisor.vesting_start_date}
                    onDateChange={(e) => updateField('effective_date', e)}
                  />
                )}

                <Typography
                  fontSize="14"
                  className="mb-2"
                  component="h4"
                  fontWeight={600}
                  color="fog"
                >
                  Vesting Period (months)
                </Typography>

                <Select
                  className="w-full mb-2"
                  portal
                  placeholder="Vesting period (in months)"
                  defaultValue={24}
                  onChange={(v) => updateField('vesting_period', v)}
                  options={[12, 24, 36, 48, 72, 96].map((n) => ({
                    value: n,
                  }))}
                />
              </>
            )}

            <CheckBox
              className="mr-2 mt-6"
              label="Add investment details"
              checked={isInvestor}
              onChange={(e) => setIsInvestor(e.target.checked)}
            />

            {isInvestor && (
              <>
                <Typography
                  fontSize="14"
                  className="mb-2 mt-6"
                  component="h4"
                  fontWeight={600}
                  color="fog"
                >
                  Investment Details{' '}
                  <Typography fontSize="12" color="gray">
                    (optional)
                  </Typography>
                </Typography>

                <NumberFormattedInput
                  className="w-full mb-2"
                  placeholder="Investment Amount"
                  value={advisor.amount_invested}
                  onChange={(e) => updateField('amount_invested', e.target.value)}
                  prefix="$"
                  autoFocus
                />

                <NumberFormattedInput
                  className="w-full mb-2"
                  placeholder="Valuation Cap"
                  value={advisor.valuation}
                  onChange={(e) => updateField('valuation', e.target.value)}
                  prefix="$"
                />
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}
