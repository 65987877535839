import React from 'react'

import BulkActions from 'containers/ListIndexView/Components/BulkActions'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import { ISalesCompany, ISalesPeople } from 'containers/ListIndexView/SalesList/type'
import { cabalToast } from 'ui-components/Toast'

interface Props {
  selectedItems: ISalesCompany[] | ISalesPeople[]
}

const SalesListBulkActions: React.FC<Props> = ({ selectedItems }) => {
  const { adminView } = useSalesListContext()

  const handleOnSelect = (action: 'delete' | 'export') => {
    if (selectedItems.length === 0) {
      cabalToast({
        content: 'Select companies to perform bulk actions',
        style: 'error',
      })
      return
    }
    if (action === 'delete') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        //  delete here
      }
    }

    if (action === 'export') {
      const confirmDelete = window.confirm('Are you sure you want to bulk delete?')
      if (confirmDelete) {
        //  delete here
      }
    }
  }

  const bulkMenuItems = [
    {
      label: 'Delete',
      onSelect: () => handleOnSelect('delete'),
    },
    {
      label: 'Export',
      onSelect: () => handleOnSelect('export'),
    },
  ]

  return (
    <>
      {adminView && (
        <BulkActions disabled={selectedItems.length === 0} bulkMenuItems={bulkMenuItems} />
      )}
    </>
  )
}

export default SalesListBulkActions
