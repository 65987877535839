import React, { useEffect, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useResourcesListContext } from 'containers/ListIndexView/ResourcesList'
import ResourcesListBulkActions from 'containers/ListIndexView/ResourcesList/ResourcesListBulkActions'
import ResourcesListCTAs from 'containers/ListIndexView/ResourcesList/ResourcesListCTAs'
import ResourcesListEmptyState from 'containers/ListIndexView/ResourcesList/ResourcesListEmptyState'
import ResourcesListFilters from 'containers/ListIndexView/ResourcesList/ResourcesListFilters'
import ResourcesListHeader from 'containers/ListIndexView/ResourcesList/ResourcesListHeader'
import { IResourcePeople, ResourcesFilterTypes } from 'containers/ListIndexView/ResourcesList/types'
import { StickyHeader } from 'containers/ListIndexView/style'
import Loading from 'global/Loading'

import ResourcePeopleAddWidget from './ResourcePeopleAddWidget'
import ResourcePeopleAdminView from './ResourcePeopleAdminView'
import ResourcePeopleMemberView from './ResourcePeopleMemberView'

interface Props {
  listLoading: boolean
  memberView: boolean
  // adminView: boolean
  resourcesListEmpty: boolean
  sharedListView: boolean
  filters: ResourcesFilterTypes
  setFilters: (filters: ResourcesFilterTypes) => void
  listUuid: string
  tags: string[]
}

const ResourcePeopleList: React.FC<Props> = ({
  filters,
  setFilters,
  listLoading,
  resourcesListEmpty,
  memberView,
  sharedListView,
  listUuid,
  tags,
}) => {
  const [showAddWidget, setShowAddWidget] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState<IResourcePeople[]>([])
  const { adminView, resourcePeople } = useResourcesListContext()
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  return (
    <div>
      <StickyHeader>
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center">
          <ResourcesListHeader />
          <div className="flex gap-6">
            <ResourcesListBulkActions selectedItems={selectedPeople} listUuid={listUuid} />
            <ResourcesListCTAs onShowAddWidget={() => setShowAddWidget(!showAddWidget)} />
          </div>
        </div>
        <ResourcesListFilters
          filters={filters}
          setFilters={setFilters}
          ownedCompanyTags={tags}
          locations={resourcePeople.map((p) => p.data?.location)}
          setSelectedItems={setSelectedPeople}
        />
      </StickyHeader>
      {listLoading && (
        <div className="my-10 flex items-center justify-center">
          <Loading />
        </div>
      )}
      {!listLoading && (
        <>
          <div ref={parent}>
            {showAddWidget && (
              <ResourcePeopleAddWidget
                onClose={() => {
                  setShowAddWidget(false)
                }}
                listUuid={listUuid}
              />
            )}
          </div>

          {resourcesListEmpty && (
            <ResourcesListEmptyState
              onShowAddWidget={() => {
                setShowAddWidget(!showAddWidget)
              }}
              title="No people"
              description="Add people to get started"
              buttonLabel="Add people"
            />
          )}

          {adminView && !resourcesListEmpty && (
            <ResourcePeopleAdminView
              selectedPeople={selectedPeople}
              setSelectedPeople={setSelectedPeople}
            />
          )}

          {(memberView || sharedListView) && !resourcesListEmpty && <ResourcePeopleMemberView />}
        </>
      )}
    </div>
  )
}

export default ResourcePeopleList
