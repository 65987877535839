import React from 'react'
import { useParams } from 'react-router-dom'

import { useTeam } from 'store/hooks'
import { CompanySlugParam, Team } from 'utils/types'
import { useAccessControl } from 'global/AccessControl'

import { CardBg } from '../styles'
import AgreementTemplates from './AgreementTemplates'
import SectionHeader from 'ui-components/SectionHeader'

export interface TeamSettingProps {
  team: Team
  reloadTeam: () => Promise<any>
}

const Company = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(companySlug)
  const { canExportAgreements, canViewEquity } = useAccessControl(companySlug)

  const { public_directory } = team || {}

  if (!team) return <></>

  const teamSettingProps: TeamSettingProps = {
    reloadTeam,
    team,
  }

  return (
    <div className="">
      <div>
        <SectionHeader title="Agreement Templates" />
        <CardBg>
          <AgreementTemplates {...teamSettingProps} />
        </CardBg>
      </div>
    </div>
  )
}

export default Company
