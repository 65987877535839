import React from 'react'

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'

import hotToast, { Toast as ReactHotToast, ToastPosition } from 'react-hot-toast'
import Zoom from 'react-reveal/Zoom'
import styled from 'styled-components'
import tw from 'twin.macro'

import CabalButton, { CabalButtonProps } from 'global/CabalButton'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import Icon from 'ui-components/Icon'

const ToastContainer = styled(Typography)<{ active: boolean }>`
  ${tw`rounded-lg items-center justify-between flex py-2 px-4 gap-3 pointer-events-auto`}

  min-width: 250px;

  background-color: ${({ theme, active }) =>
    active ? theme.colors.light_black : theme.colors.cardBackground};
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.primary)};

  box-shadow: 0px 4px 6px -2px #0000000d;
  box-shadow: 0px 10px 15px -3px #0000001a;
`

const ToastIconContainer = styled.div``

const AvatarContainer = styled.div``

const ToastContentContainer = styled.div`
  ${tw`flex gap-3 flex-1 items-center`}
`

const CtaContainer = styled.div`
  ${tw`flex gap-3`}
`

export interface TaostProps {
  iconName?: 'info' | 'warning' | 'error' | 'success' | 'loading'
  content: React.ReactNode
  icon?: React.ReactNode
  cta?: {
    content: React.ReactNode
    doNotDismissToastOnClick?: boolean
  } & CabalButtonProps
  avatar?: React.ReactNode
  dismissable?: boolean
  toast: ReactHotToast
  passive?: boolean
}

export const Toast: React.VFC<TaostProps> = ({
  icon,
  iconName,
  content,
  avatar,
  cta,
  dismissable = true,
  toast,
  passive = false,
}) => {
  let iconNode: React.ReactNode = null
  const purpleColorName = passive ? 'purple' : 'light_purple'

  if (icon) {
    iconNode = <Typography color={purpleColorName}>{icon}</Typography>
  } else if (iconName) {
    switch (iconName) {
      case 'info':
        iconNode = (
          <Typography color={purpleColorName}>
            <Icon icon={faInfoCircle} />
          </Typography>
        )
        break
      case 'warning':
        iconNode = (
          <Typography color="yellow">
            <Icon icon={faExclamationTriangle} />
          </Typography>
        )
        break
      case 'error':
        iconNode = (
          <Typography color="red">
            <Icon icon={faExclamationCircle} />
          </Typography>
        )
        break
      case 'success':
        iconNode = (
          <Typography color="green">
            <Icon icon={faCheckCircle} />
          </Typography>
        )
        break
      case 'loading':
        iconNode = <Loading strokeWidth={7} size={14} />
        break
    }
  }

  let ctaContent: React.ReactNode = <></>

  if (!!cta) {
    ctaContent = cta.content

    if (cta.variant === 'tertiary') {
      ctaContent = <Typography color={purpleColorName}>{cta.content}</Typography>
    }
  }

  return (
    <ToastContainer data-testid="toast" component="div" fontSize="14" active={!passive}>
      {!!iconNode && <ToastIconContainer>{iconNode}</ToastIconContainer>}
      <ToastContentContainer>
        {!!avatar && <AvatarContainer>{avatar}</AvatarContainer>}
        {content}
      </ToastContentContainer>
      <CtaContainer>
        {!!cta && (
          <CabalButton
            padding="2px 4px"
            {...cta}
            onClick={(event: any) => {
              if (!cta.doNotDismissToastOnClick) {
                hotToast.remove(toast.id)
              }
              cta.onClick?.(event as any)
            }}
          >
            {ctaContent}
          </CabalButton>
        )}
        {dismissable && (
          <Typography
            component="button"
            color="fog"
            data-testid="toast-dismiss"
            onClick={() => {
              hotToast.remove(toast.id)
            }}
          >
            <Icon icon={faXmark} className="w-4 h-4" />
          </Typography>
        )}
      </CtaContainer>
    </ToastContainer>
  )
}
export interface CabalToastProps extends Omit<TaostProps, 'toast' | 'iconName'> {
  style?: TaostProps['iconName']
  duration?: number
  id?: string
  position?: ToastPosition
}

export const cabalToast = ({ duration, id, position, style, ...extra }: CabalToastProps) => {
  return hotToast.custom(
    (t) => (
      <Zoom
        top={position === undefined ? true : position.startsWith('top')}
        bottom={!!position?.startsWith('bottom')}
        duration={500}
      >
        <Toast toast={t} iconName={style} {...extra} />
      </Zoom>
    ),
    {
      id,
      position,
      duration,
    },
  )
}
