import React, { useEffect, useRef } from 'react'

import last from 'lodash/last'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ActivityRow from 'components/Activity/ActivityRow'
import Comment from 'components/CommentsAndActivity/Comment'
import CreateComment from 'components/CommentsAndActivity/CreateComment'
import { Divider } from 'components/SendMessage/styles'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { Activity, Comment as CommentType } from 'utils/types'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: ${({ theme }) => theme.colors.purple};
    }
  }
`

const CommentsAndActivities = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const history = useHistory()
  const [newCommentInternal, setNewCommentInternal] = React.useState<boolean>()
  const [focus, setFocus] = React.useState(false)
  const [commentLoaded, setCommentLoaded] = React.useState(false)
  const [activityLoaded, setActivityLoaded] = React.useState(false)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [showLoading, setShowLoading] = React.useState(true)

  useEffect(() => {
    if (commentLoaded && activityLoaded && scrollRef.current) {
      const scrollHeight = scrollRef.current.scrollHeight
      const height = scrollRef.current.clientHeight
      scrollRef.current.scrollTo(0, scrollHeight - height)
      setShowLoading(false)
    }
  }, [commentLoaded, activityLoaded])

  const teamUuid = team?.uuid

  const { data, isLoading: loadingActivity } = useInfiniteQuery(
    ['getMemberActivity', teamSlug],
    ({ pageParam: page = 1 }) =>
      callApi(api.getMemberActivity, teamSlug, 'team_activities', undefined, {}, page),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      onSuccess: () => {
        setActivityLoaded(true)
      },
    },
  )

  const commentsQuery = useQuery(
    ['getComments', teamUuid],
    () =>
      callApi(api.getComments, {
        attachable_id: teamUuid,
        attachable_type: 'Team',
        team_slug: teamSlug,
      }),
    {
      refetchInterval: 30 * 1000,
      onSuccess: ({ comments }) => {
        setNewCommentInternal(!!last(comments)?.internal)
        setCommentLoaded(true)
      },
    },
  )

  const activitiesData = data?.pages?.flatMap((page) => page.activity) ?? []

  const comments = (commentsQuery.data?.comments || []).map((co) => ({
    ...co,
    type: 'comment',
  }))
  const activities = (activitiesData || []).splice(0, 5).map((a) => ({
    ...a,
    type: 'activity',
  }))
  const mergedActivitiesAndComments = [...comments, ...activities].sort(
    (a, b) => -new Date(b.created_at).getTime() + new Date(a.created_at).getTime(),
  )

  const scrollAndFocus = () => {
    const element = document.getElementById('comment-input')
    const overflowWrapper = document.getElementById('main-overflow-wrapper')

    if (element && overflowWrapper) {
      const scrollPosition = element.getBoundingClientRect().bottom - window.innerHeight / 2

      setFocus(false)

      overflowWrapper.scrollTo({
        top: overflowWrapper.scrollTop + scrollPosition,
        behavior: 'smooth',
      })

      setTimeout(() => {
        setFocus(true)
      }, 500)
    }
  }

  return (
    <Wrapper>
      <Widget
        title={
          <Typography
            className="flex items-center cursor-pointer title"
            component="button"
            fontWeight={600}
            onClick={() => history.push(`/${teamSlug}/activity`)}
          >
            Activity
          </Typography>
        }
        classNames={'group hover:shadow-md'}
      >
        {(loadingActivity || showLoading) && (
          <div className="h-[300px] flex items-center justify-center">
            <Loading />
          </div>
        )}

        <div
          className="-mx-2 transition-opacity"
          style={{
            height: 300,
            overflow: 'auto',
            opacity: showLoading ? '0' : '1',
          }}
          ref={scrollRef}
        >
          {mergedActivitiesAndComments.map((commentOrActivity) =>
            commentOrActivity.type === 'comment' ? (
              <Comment
                comment={commentOrActivity as CommentType}
                key={commentOrActivity.uuid}
                teamSlug={teamSlug}
              />
            ) : (
              <ActivityRow
                key={commentOrActivity.uuid}
                style={{ backgroundColor: 'transparent' }}
                activity={commentOrActivity as Activity}
                splitView={true}
              />
            ),
          )}
        </div>

        <div className="-my-2 -mb-4">
          <Divider />
        </div>
        <div className="px-4" id={'comment-input'}>
          <CreateComment
            canMentionAdvisors={false}
            teamSlug={teamSlug}
            createProps={{ attachable_id: teamUuid, attachable_type: 'Team' }}
            refetchComments={commentsQuery.refetch}
            newCommentInternal={newCommentInternal}
            focus={focus}
          />
        </div>
      </Widget>
    </Wrapper>
  )
}

export default CommentsAndActivities
