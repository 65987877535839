import React, { useEffect } from 'react'

import cx from 'classnames'
import compact from 'lodash/compact'
import Highlighter from 'react-highlight-words'
import { useMutation } from 'react-query'
import styled, { useTheme } from 'styled-components'
import tw, { theme } from 'twin.macro'

import { useAskContextModal } from 'components/AsksContextModal'
import Avatar from 'global/Avatar'
import Loading from 'global/Loading'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useCurrentUserSettings, useTeam } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyBlueprint, ConnectionBlueprint, PersonBlueprint, Team, VoteType } from 'utils/types'

import ComposeButton from './ComposeButton'

export const StyledCard = styled(DataCardV2)<{ animate: boolean }>`
  // &:hover {
  //   .like-icon-internal {
  //     color: ${({ theme }) => theme.colors.purple};
  //   }
  // }

  .like-icon-internal {
    color: ${({ theme }) => theme.colors.purple};
  }

  .highlightedText {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.fog_rain};
    font-weight: 600;
  }

  .like-icon-internal {
    animation: ${({ animate }) => (animate ? 'hithere 1s 1' : 'none')};
  }

  // &:hover .like-button {
  //   color: ${({ theme }) => theme.colors.purple};
  //   opacity: 1;
  // }

  .like-button {
    color: ${({ theme }) => theme.colors.purple};
    opacity: 1;
  }

  @keyframes hithere {
    30% {
      transform: scale(1.2);
    }
    40%,
    60% {
      transform: rotate(-20deg) scale(1.2);
    }
    50% {
      transform: rotate(20deg) scale(1.2);
    }
    70% {
      transform: rotate(0deg) scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`
interface Props {
  person: PersonBlueprint
  connection?: ConnectionBlueprint
  existingVote?: VoteType
  preview?: boolean
  company: CompanyBlueprint
  teamSlug: string
  listUuid: string
  icps: string[]
  connected?: boolean
}

const linkedinSearchUrl = (name: string) => {
  return `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(
    name,
  )}&origin=GLOBAL_SEARCH_HEADER`
}

const ConnectionRow: React.VFC<Props> = ({
  connection,
  person,
  company,
  existingVote = 0,
  teamSlug,
  listUuid,
  connected = false,
}) => {
  const [vote, setVote] = React.useState<VoteType>(existingVote)
  const { settings, updateSetting } = useCurrentUserSettings()
  const [animateLike, setAnimateLike] = React.useState(false)

  const { mutate: sendVote, isLoading: sendingVote } = useMutation(
    ['createVote'],
    (vote: 1 | -1 | 0) =>
      callApi(api.createVote, {
        team_slug: teamSlug,
        votable_type: person.id ? 'GlobalPerson' : 'Company',
        votable_id: (person.id || company.id)!,
        vote,
        company_id: company?.id,
        list_uuid: listUuid,
      }),
    {
      onSuccess: (_, variables) => {
        setVote(variables)
        if (variables == 1 && !settings.like_toast_showed) {
          cabalToast({
            content: `Your offer to introduce ${person.name || person.email} has been logged. ${
              company.team_name
            } will follow up with you via email. `,
            style: 'success',
          })
          updateSetting('like_toast_showed', true)
        }
      },
    },
  )

  useEffect(() => {
    setTimeout(() => {
      setAnimateLike(false)
    }, 1000)
  }, [animateLike])

  return (
    <>
      <StyledCard
        animate={animateLike}
        className="py-0 h-[30px]"
        data-testid="connection-row-person-name"
        title={
          <Typography
            component="button"
            onClick={(e) => {
              e.stopPropagation()
              person.linkedin_url
                ? window.open(person.linkedin_url, '_blank')
                : window.open(linkedinSearchUrl(person.name), '_blank')
            }}
          >
            {person.name || person.email || ''}
          </Typography>
        }
        onClick={() => {
          sendVote(vote === 0 ? 1 : 0)
          setAnimateLike(vote === 0 ? true : false)
        }}
        titleIcon={
          <>
            {person.verified && (
              <Typography fontSize="12" color="purple" lineHeight={1}>
                <i className="fa fa-badge-check fa-fw" />
              </Typography>
            )}
            {connected && (
              <>
                <Tooltip label={`You are connected to ${person.name} on Linkedin`}>
                  <Typography fontSize="12" lineHeight={1} color="fog_rain">
                    1st
                  </Typography>
                </Tooltip>
                <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="">
                  {'·'}
                </Typography>
              </>
            )}
            {person.linkedin_url && (
              <Typography fontSize="12" color="fog_rain" className="cursor-pointer" lineHeight="1">
                <a
                  href={person.linkedin_url}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className={cx('cursor-pointer')}
                  onClick={(e) => e.stopPropagation()}
                >
                  <i className="fab fa-linkedin fa-fw"></i>
                </a>
              </Typography>
            )}
            {!person.linkedin_url && (
              <Typography fontSize="12" color="fog_rain" className="cursor-pointer" lineHeight="1">
                <a
                  href={linkedinSearchUrl(person.name)}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className={cx('cursor-pointer')}
                  onClick={(e) => e.stopPropagation()}
                >
                  <i className="fab fa-linkedin fa-fw"></i>
                </a>
              </Typography>
            )}

            {connection?.source_type === 'Contact' && (
              <>
                <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="">
                  {'·'}
                </Typography>
                <Tooltip label={`You are connected to ${person.name} via Google Contacts`}>
                  <Typography fontSize="12" lineHeight={1} color="fog_rain">
                    <i className="fab fa-google fa-fw" />
                  </Typography>
                </Tooltip>
              </>
            )}
            <Typography fontSize="12" lineHeight={'15px'} color={'fog'} className="">
              {'·'}
            </Typography>
            <div className="flex truncate max-w-[200px]">
              <Typography color="fog_rain" fontSize="12" className="block cursor-pointer truncate">
                {person.headline}
              </Typography>
            </div>
          </>
        }
        cta={
          <div
            className="flex items-center justify-end w-[82px]"
            onClick={() => {
              sendVote(vote === 0 ? 1 : 0)
              setAnimateLike(vote === 0 ? true : false)
            }}
          >
            {vote === 1 && sendingVote && (
              <div className="mr-1">
                <Loading size={12} strokeWidth={5} />
              </div>
            )}
            <Typography
              color={vote === 1 ? 'purple' : 'default_icon'}
              lineHeight="1"
              fontSize="12"
              className="flex items-center"
            >
              <span className="like-button">{vote === 1 ? 'Offered' : 'Offer intro'}</span>
            </Typography>
            <Typography
              color={vote === 1 ? 'purple' : 'default_icon'}
              lineHeight="1"
              fontSize="16"
              className="flex items-center pl-2 like-icon-internal"
              component="p"
            >
              {vote === 1 ? <i className="fas fa-thumbs-up" /> : <i className="far fa-thumbs-up" />}
            </Typography>
          </div>
        }
      />
    </>
  )
}

export default ConnectionRow
