import React, { useState } from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import ChartSection from 'components/ActivityCharts/ChartSection'
import { IActivityDashboard, ISelectedPeriod } from 'components/ActivityCharts/types'
import { useAccessControl } from 'global/AccessControl'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

const HomeCharts = () => {
  const teamSlug = useTeamSlug()
  const history = useHistory()
  const { canViewPortfolio: isVCFirm } = useAccessControl(teamSlug)
  const [selectedPeriod, setSelectedPeriod] = useState<ISelectedPeriod>({
    candidate_created: 'last_90_days',
    candidate_view: 'last_90_days',
    request_intro_v2: 'all_time',
    request_intro_accepted: 'last_90_days',
    view_deal_board: 'last_90_days',
  })

  const [showCumulative, setShowCumulative] = useState({
    candidate_created: true,
    candidate_view: true,
    request_intro_v2: true,
    request_intro_accepted: true,
    view_deal_board: true,
  })

  const getEmptyState = (activity: IActivityDashboard) => {
    if (activity.activity_name === 'request_intro_v2') {
      return {
        title: 'Track your intros',
        subtitle: 'Make, track and measure intros ',
        icon: 'fa-handshake',
        buttonText: 'Start',
        onClick: () => history.push(`./${teamSlug}/intro-center`),
      }
    }

    if (activity.activity_name === 'view_deal_board') {
      return {
        title: 'Set up your Page',
        subtitle: isVCFirm
          ? 'All your firm resources in one place'
          : 'Help your supporters help you',
        icon: 'fa-building',
        buttonText: 'Start',
        onClick: () => history.push(`./${teamSlug}/page`),
      }
    }

    if (activity.activity_name === 'connections_added') {
      return {
        title: 'Get warm intros',
        subtitle: 'Find, track and measure intros ',
        icon: 'fa-handshake',
        buttonText: 'Start',
        onClick: () => history.push(`./${teamSlug}/intro-center`),
      }
    }
  }

  const { data: activityDashboard } = useQuery(
    ['activity-dashboard', teamSlug, selectedPeriod, showCumulative],
    () => callApi(api.getActivityDashboard, teamSlug, selectedPeriod, showCumulative),
    {
      keepPreviousData: true,
    },
  )

  return (
    <div className="grid grid-cols-2 gap-6">
      {activityDashboard
        ?.filter(
          (activity) =>
            activity.activity_name !== 'candidate_created' &&
            activity.activity_name !== 'request_intro_accepted' &&
            activity.activity_name !== 'candidate_view',
        )
        .map((activity) => {
          return (
            <ChartSection
              name={activity.activity_name}
              key={activity.title}
              title={activity.title}
              data={activity}
              onSetSelectedPeriod={(val: string) =>
                setSelectedPeriod({ ...selectedPeriod, [activity.activity_name]: val })
              }
              onSetShowCumulative={(val: boolean) =>
                setShowCumulative({ ...showCumulative, [activity.activity_name]: val })
              }
              currentPeriod={selectedPeriod[activity.activity_name as keyof ISelectedPeriod]}
              emptyState={getEmptyState(activity)}
            />
          )
        })}
    </div>
  )
}

export default HomeCharts
