import React, { useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'

import Loading from 'global/Loading'
import { useCurrentUser, useTeam } from 'store/hooks'

import api from 'utils/api'
import { CompanySlugParam, TeamSettings } from 'utils/types'

import AffinityIntegrationCard from './AffinityIntegrationCard'
import ExchangeIntegrationCard from './ExchangeIntegrationCard'
import ExternalSources from './ExternalSources'
import GcontactIntegrationCard from './GcontactIntegrationCard'
import GmailIntegrationCard from './GmailIntegrationCard'
import HubspotIntegrationCard from './HubspotIntegrationCard'
import LeverIntegrationCard from './LeverIntegrationCard'
import MicrosoftIntegrationCard from './MicrosoftIntegrationCard'
import SalesforceIntegrationCard from './SalesforceIntegrationCard'
import SlackIntegrationCard from './SlackIntegrationCard'

const Integration = () => {
  const [settings, setSettings] = useState<TeamSettings>()
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(companySlug)
  const {
    user: {
      has_synced_gcontacts,
      flags: { enable_gcontacts },
    },
  } = useCurrentUser()

  function loadSettings(response: AxiosResponse<TeamSettings>) {
    const { data } = response
    setSettings({ ...data })
  }

  useEffect(() => {
    api.getTeamSettings().then(loadSettings)
  }, [])

  return (
    <>
      {settings && team ? (
        <div className="mt-4">
          <SlackIntegrationCard reload={loadSettings} settings={settings} teamSlug={companySlug} />
          {enable_gcontacts && <GcontactIntegrationCard />}
          <GmailIntegrationCard />
          <SalesforceIntegrationCard settings={settings} />
          <HubspotIntegrationCard settings={settings} />
          <MicrosoftIntegrationCard />
          <ExchangeIntegrationCard />
          <AffinityIntegrationCard teamSlug={companySlug} />
          {team?.features?.airtable_apps && <ExternalSources teamSlug={companySlug} />}
        </div>
      ) : (
        <div className="mt-6 flex justify-center items-center flex-col">
          <Loading />
        </div>
      )}
    </>
  )
}

export default Integration
