import React, { useEffect } from 'react'

import { useMutation } from 'react-query'

import { useComposer } from 'components/Composer'
import CabalButton from 'global/CabalButton'
import {
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionHeader,
  ModalSectionWrapper,
} from 'global/Modal/styles'
import CKEditor from 'global/TextEditor/ckeditor'
import { useCurrentUser } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { absoluteHtmlContent } from 'utils/html'

import ConnectedAccounts from './ConnectedAccounts'
import Digest from './Digest'
import Signature from './Signature'

interface Props {
  onHide: () => void
}

const SettingsSection: React.FC<Props> = ({ onHide }) => {
  const { user, reloadUser } = useCurrentUser()
  const { compose } = useComposer()

  const [emailSignature, setEmailSignature] = React.useState('')

  const { mutate: updateUser, isLoading: updatingUser } = useMutation(
    ['updateUser'],
    () => callApi(api.updateCurrentProfile, { email_signature: emailSignature }),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Successfully updated' })
        reloadUser()
        onHide()
      },
    },
  )

  useEffect(() => {
    if (user.email_signature) {
      setEmailSignature(user.email_signature)
    }
  }, [user.email_signature])

  return (
    <div>
      <ModalSectionHeader>Email</ModalSectionHeader>
      <ModalSectionWrapper>
        <ModalInputWrapper className="">
          <ModalInputLabel>Connected Accounts</ModalInputLabel>
          <div>
            <ConnectedAccounts />
          </div>
        </ModalInputWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Email Signature</ModalInputLabel>
          <CKEditor
            value={emailSignature || ''}
            onChange={(v) => setEmailSignature(absoluteHtmlContent(v))}
          />
        </ModalInputWrapper>

        <ModalInputWrapper>
          <ModalInputLabel>Digest Schedule</ModalInputLabel>
          <Digest />
        </ModalInputWrapper>
      </ModalSectionWrapper>
      <ModalSectionHeader>Agreements</ModalSectionHeader>
      <ModalSectionWrapper>
        <ModalInputWrapper>
          <ModalInputLabel>Signature</ModalInputLabel>
          <Signature />
        </ModalInputWrapper>
      </ModalSectionWrapper>

      <div className="flex justify-between items-center mt-7 mb-6">
        {user.team?.slug ? (
          <CabalButton
            variant="tertiary"
            disabled={updatingUser}
            padding="0"
            type="button"
            onClick={(e) => {
              e.stopPropagation()
              compose({
                team_slug: user.team?.slug,
                subject: 'Delete Account',
                body: 'Please email help@getcabal.com to delete your account.',
                recipients: { emails: ['help@getcabal.com'] },
              })
            }}
          >
            Delete account
          </CabalButton>
        ) : (
          <CabalButton variant="tertiary" padding="0">
            Email help@getcabal.com to delete account.
          </CabalButton>
        )}
        <CabalButton working={updatingUser} type="submit" onClick={() => updateUser()}>
          Save
        </CabalButton>
      </div>
    </div>
  )
}

export default SettingsSection
