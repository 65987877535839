import React from 'react'

import Avatar from 'global/Avatar'
import Typography from 'global/Typography'

import { Team } from 'utils/types'

interface Props {
  teamAdvised: Team
  children: React.ReactNode
}

const Header: React.FC<Props> = ({ teamAdvised, children }) => {
  return (
    <div className="flex flex-col text-center items-center gap-4 mb-16">
      <Avatar src={teamAdvised.logo} name={teamAdvised.name} size="64px" className="mb-2" />
      <Typography fontSize="24" fontWeight={600}>
        Share your top asks with {teamAdvised.name}
      </Typography>
      <Typography fontSize="16" color="fog_rain">
        {children}
      </Typography>
    </div>
  )
}

export default Header
