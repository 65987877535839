import * as React from 'react'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import CapGroup from './CapGroup'
import { cleanNum } from 'utils/ui'
import Typography from 'global/Typography'
import { CapTableType, EquityTierEntity } from 'utils/types'
import CabalButton from 'global/CabalButton'
import { isNumber } from 'lodash'
import EquityTiers from './EquityTiers'
import { TeamSettingProps } from '.'
import { useQuery } from 'react-query'
import { queryClient } from 'utils/test-utils'

interface Props extends TeamSettingProps {
  capTable: CapTableType
  setCapTable: (t: CapTableType) => void
}

const CapTableSettings: React.VFC<Props> = ({ team, capTable, setCapTable }) => {
  const [working, setWorking] = React.useState(false)
  const [equityTiers, setEquityTiers] = React.useState<EquityTierEntity[]>([])

  const tiersQuery = useQuery(
    ['getEquityTiers', team.slug],
    () => callApi(api.getEquityTiers, team.slug),
    {
      onSuccess: (data) => {
        setEquityTiers(data.equity_tiers)
      },
    },
  )

  const handleSave = () => {
    const { advisor_common, fully_diluted } = capTable || {}

    if (!isNumber(advisor_common) || !isNumber(fully_diluted)) return

    setWorking(true)

    api
      .saveCapTable({
        advisor_common,
        fully_diluted,
      })
      .then(({ data: { cap_table } }) => {
        setCapTable(cap_table)
        tiersQuery.refetch()
        cabalToast({ style: 'success', content: 'Successfully saved advisor equity budget' })
      })
      .finally(() => {
        setWorking(false)
      })
  }

  if (!capTable) return <></>

  const { fully_diluted, advisor_common, advisor_issued } = capTable

  const pool_remaining = cleanNum(advisor_common) - cleanNum(advisor_issued)

  return (
    <>
      <CapGroup
        text="Total Fully Diluted Shares"
        percent={100}
        value={fully_diluted}
        onChange={(e) => setCapTable({ ...capTable, fully_diluted: Number(e.target.value) })}
        data-testid="total-shares"
      />
      <CapGroup
        text="Advisor Program Budget Shares (we recommend 1-2% of Total)"
        percent={percent(advisor_common, fully_diluted)}
        percentOf={'total'}
        value={advisor_common}
        onChange={(e) => setCapTable({ ...capTable, advisor_common: Number(e.target.value) })}
      />
      <CapGroup
        text="Advisor Budget Issued"
        percent={percent(advisor_issued, advisor_common)}
        percentOf={'budget'}
        value={advisor_issued}
      />
      <div className="flex justify-end mt-4">
        <CabalButton
          variant="primary"
          working={working}
          onClick={handleSave}
          data-testid="save-cap-table"
        >
          Save
        </CabalButton>
      </div>
    </>
  )
}

function percent(first: number, second: number) {
  const firstNum = first ? cleanNum(first) : null
  const secondNum = second ? cleanNum(second) : null
  return secondNum && firstNum ? (firstNum / secondNum) * 100.0 : 0
}

export default CapTableSettings
