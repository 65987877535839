import React, { useState } from 'react'

import moment from 'moment'

import { SeeMore } from 'containers/CandidateProfile'
import AddNoteModal from 'containers/RequestCenter/IntroRequestDetails/AddNoteModal'
import { IIntroRequest, IIntroRequestCommentWithUser } from 'containers/RequestCenter/types/ITypes'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

interface IProps {
  introRequestDetails: IIntroRequest
  refetch: () => void
}

const CommentBlock = (commentWithUserInfo: IIntroRequestCommentWithUser) => {
  const NOTE_CUTOFF = 70
  const { comment, creator_user: user } = commentWithUserInfo
  const [isExpanded, setIsExpanded] = useState((comment?.body?.length ?? 0) < NOTE_CUTOFF)
  const commentBody = isExpanded ? comment.body : `${comment?.body?.slice(0, NOTE_CUTOFF) || ''}`
  const commentTime = moment(comment.updated_at || comment.created_at).fromNow()

  return (
    <div className="flex">
      <Avatar
        size={'32'}
        round
        className="flex-shrink-0 mr-2"
        src={user.avatar_url || ''}
        name={user?.name || ''}
      />
      <div className="flex flex-col">
        <div className="flex flex-row items-baseline w-full gap-x-2">
          <Typography fontWeight={600} fontSize="14">
            {user?.name || ''}
          </Typography>
          <Typography color="fog_rain" fontSize="12">
            {commentTime}
          </Typography>
        </div>

        {comment?.body && (
          <p className="leading-5 mb-2">
            <Typography fontSize="12" color="fog" lineHeight={1.2}>
              {commentBody}
            </Typography>

            {comment?.body?.length > NOTE_CUTOFF && (
              <SeeMore
                className="ml-1 cursor-pointer"
                color="fog"
                fontSize="12"
                lineHeight={1.2}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? '...see less' : '...see more'}
              </SeeMore>
            )}
          </p>
        )}
      </div>
    </div>
  )
}

export function Notes({ introRequestDetails, refetch }: IProps) {
  const { comments_creator_users: commentsWithUserInfo } = introRequestDetails
  const { showModal } = useModal()

  const renderAddNoteModal = (resolve: () => void) => (
    <AddNoteModal
      onHide={() => resolve()}
      introRequestData={introRequestDetails}
      refetch={refetch}
    />
  )

  return (
    <Widget
      title="Notes"
      cta={
        <CabalButton
          variant="link"
          onClick={() => showModal(renderAddNoteModal, 'render_note_modal')}
          padding="0"
        >
          Add
        </CabalButton>
      }
    >
      <div className="flex flex-col gap-y-4 pb-2">
        {commentsWithUserInfo.map((commentWithUserInfo) => (
          <>
            <CommentBlock key={commentWithUserInfo.comment.uuid} {...commentWithUserInfo} />
          </>
        ))}
      </div>
    </Widget>
  )
}
