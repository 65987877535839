import * as React from 'react'
import emoji from 'react-easy-emoji'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import SectionHeader from 'ui-components/SectionHeader'

import { TeamSettingProps } from '.'
import CompanyInfoModal from './CompanyInfoModal'

const CompanyInfo: React.FC<TeamSettingProps> = ({ team, reloadTeam }) => {
  const [showModal, setShowModal] = React.useState(false)

  const {
    name = '',
    website = '',
    twitter_url = '',
    description = '',
    linkedin_url = '',
    logo = '',
  } = team || {}

  return (
    <>
      <div className="flex justify-end">
        <CabalButton
          onClick={() => setShowModal(true)}
          data-testid="edit-company-button"
          className={'cursor-pointer'}
          variant="tertiary"
        >
          <i className="far fa-pen"></i>
        </CabalButton>
      </div>

      <div className="flex">
        <div>{name && <Avatar name={name} src={logo} size={'80'} round />}</div>
        <div className="pl-6">
          <Typography color={'primary'} fontWeight={700}>
            {emoji(name)}
          </Typography>

          <Typography color={'gray'} className={'block pt-2'} fontSize={'12'}>
            {description}
          </Typography>

          <div className={'pt-2 flex items-center'}>
            {!!website && (
              <a
                href={website.startsWith('https://') ? website : `https://${website}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography color={'gray'} className="inline-block">
                  <i className="fa fa-globe pr-1"></i>
                </Typography>
              </a>
            )}

            {!!twitter_url && (
              <a href={twitter_url} target="_blank" rel="noreferrer">
                <Typography className={'pl-3'} color={'gray'}>
                  <i className="fab fa-twitter pr-1"></i>
                </Typography>
              </a>
            )}

            {!!linkedin_url && (
              <a href={linkedin_url} target="_blank" rel="noreferrer">
                <Typography className={'pl-3'} color={'gray'}>
                  <i className="fab fa-linkedin pr-1"></i>
                </Typography>
              </a>
            )}
          </div>

          {showModal && (
            <CompanyInfoModal
              team={team}
              loadInfo={reloadTeam}
              show={showModal}
              onHide={() => setShowModal(false)}
              logoUrl={logo}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CompanyInfo
