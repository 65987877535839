import React, { useMemo, useState } from 'react'

import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'

import compact from 'lodash/compact'
import moment from 'moment'
import pluralize from 'pluralize'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import tw from 'twin.macro'

import Avatar from 'global/Avatar'
import Loading from 'global/Loading'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useAdvisor, useAdvisors } from 'store/hooks'
import Icon from 'ui-components/Icon'

import api, { callApi } from 'utils/api'
import { appUrl, isExtension, isTest } from 'utils/env'
import { cn } from 'utils/styles'
import { AdvisorModel } from 'utils/types'

const PillWrapper = styled.label<{ selected: boolean }>`
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme, selected }) => selected && theme.colors.purple};
`

const TooltipGuts = styled(Typography)`
  ${tw`flex flex-col gap-2`}
  max-width: 300px;
`

const Divider = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

type Props = {
  advisor: AdvisorModel
  selected: boolean
  onSelect: (advisorUuid: string) => void
  teamSlug: string
}

const AdvisorName = ({ advisor, teamSlug }: { advisor: AdvisorModel; teamSlug: string }) => {
  return (
    <Typography
      fontWeight={600}
      fontSize={'14'}
      color="primary"
      component={isExtension() ? 'a' : 'link'}
      to={`/${teamSlug}/members/${advisor.uuid}`}
      href={`${appUrl()}/${teamSlug}/members/${advisor.uuid}`}
      className="cursor-pointer hover:underline"
      target="_blank"
    >
      {advisor.name}
    </Typography>
  )
}

const AdvisorPill: React.FC<Props> = ({ advisor, selected, onSelect, teamSlug }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { advisorsMapByUserUuid } = useAdvisors({ teamSlug })
  // Get advisor uuid if userUuid is passed
  const advisorUuid = advisorsMapByUserUuid[advisor.uuid]?.uuid || advisor.uuid
  const { advisor: fullAdvisor } = useAdvisor(teamSlug, advisorUuid)
  const advisorGroups = (fullAdvisor?.groups || []).filter((group) => group.name !== 'All')

  const advisorRequestStatsQuery = useQuery(
    ['getAdvisorRequestStats', advisor.uuid],
    () => callApi(api.getAdvisorRequestStats, advisorUuid, teamSlug),
    {
      enabled: !!advisor && tooltipOpen,
    },
  )
  const advisorRequestStats = advisorRequestStatsQuery.data

  const tooltipGuts = useMemo(() => {
    if (advisorRequestStatsQuery.isLoading) {
      return <Loading />
    }

    const headline = compact([advisor.title, advisor.company_name]).join(' @ ')
    const linkedinUrl = advisor.linkedin_url

    return (
      <TooltipGuts component="div" color="fog_rain" onClick={(e) => e.stopPropagation()}>
        <div>
          <div className="flex items-center gap-2">
            <Avatar
              className="flex-shrink-0"
              name={advisor.name}
              src={advisor.avatar_url}
              size={'32'}
              round
            />
            <div className="flex flex-col text-left truncate">
              <AdvisorName advisor={advisor} teamSlug={teamSlug} />
              {!!headline && (
                <Typography fontSize="12" className="truncate" title={headline}>
                  {headline}
                </Typography>
              )}
            </div>

            {linkedinUrl && (
              <Typography>
                <a
                  href={linkedinUrl}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className="cursor-pointer "
                  onClick={() => window.open(linkedinUrl, '_blank')}
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </Typography>
            )}
          </div>
        </div>
        <Divider />
        <div className="space-y-1">
          {advisorGroups && advisorGroups.length > 0 && (
            <div className="flex items-center">
              <Icon icon={faUsers} className="w-3 h-3 mr-2" />
              <div className="leading-4">
                {advisorGroups.slice(0, 6).map((g, i) =>
                  i === advisorGroups.length - 1 ? (
                    <Typography fontSize="12" key={g.uuid}>
                      {`${g.name} `}
                    </Typography>
                  ) : (
                    <Typography fontSize="12" key={g.uuid} lineHeight={1}>
                      {`${g.name}, `}
                    </Typography>
                  ),
                )}
                {advisorGroups.length > 6 && (
                  <Typography fontSize="12" lineHeight={1}>
                    {`+ ${advisorGroups.length - 6} more`}
                  </Typography>
                )}
              </div>
            </div>
          )}
          {advisorRequestStatsQuery.isLoading && <Loading size={15} />}
          {advisorRequestStats && (
            <>
              <div>
                <Typography fontSize="12">
                  <i className="fas fa-arrows-h fa-fw mr-1"></i>
                  {`${advisorRequestStats.request_count} ${pluralize(
                    'Request',
                    advisorRequestStats.request_count,
                  )} received`}
                </Typography>
              </div>
              <div>
                {advisorRequestStats.last_request_at && (
                  <Tooltip
                    label={
                      <Typography fontSize="12">
                        {moment(advisorRequestStats.last_request_at).format('LT, MMM D Y')}
                      </Typography>
                    }
                  >
                    <Typography fontSize="12">
                      <i className="fas fa-history fa-fw mr-1"></i>
                      Last Request {moment(advisorRequestStats.last_request_at).fromNow()}
                    </Typography>
                  </Tooltip>
                )}
              </div>
            </>
          )}
        </div>
      </TooltipGuts>
    )
  }, [advisor, advisorGroups, advisorRequestStats, advisorRequestStatsQuery.isLoading, teamSlug])

  return (
    <Tooltip
      label={tooltipGuts}
      interactive
      appendTo={window.CABAL_EXTENSION_ROOT ?? document.body}
      delay={[200, 0]}
      onShow={() => setTooltipOpen(true)}
      onHide={() => setTooltipOpen(false)}
    >
      <PillWrapper
        className="rounded-full p-1 pr-2 cursor-pointer"
        selected={selected}
        data-testid={`advisor-pill-${advisor.uuid}`}
      >
        <input
          type="checkbox"
          className={cn({ hidden: !isTest() })}
          checked={selected}
          readOnly
          onChange={() => onSelect(advisor.uuid)}
        />
        <Typography className="flex items-center" lineHeight={1}>
          <Avatar name={advisor.name} src={advisor.avatar_url} size="16px" />
          <Typography
            fontSize="12"
            className="ml-2"
            color={selected ? 'white' : 'primary'}
            lineHeight={1}
          >
            {advisor.name}
          </Typography>
        </Typography>
      </PillWrapper>
    </Tooltip>
  )
}

export default AdvisorPill
