import React, { useState } from 'react'

import { EditListType } from 'components/EditList'
import { CheckBox, Select } from 'global/Input'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import InfoTooltip from 'ui-components/InfoTooltip'

interface IInitializeProps {
  selectedUser: string
  approvalRequired: boolean
}

interface FacilitatorSectionProps {
  title: string
  adminAndMembersValues: Array<{ label: string; value: string }>
  selectedValue?: () => void
  defaultOptions: Array<{ label: string; value: string }>
  facilitatorType: 'talent' | 'sales' | 'resources'
  initializeOptions: IInitializeProps
}

const FacilitatorSection: React.FC<FacilitatorSectionProps> = ({
  title,
  adminAndMembersValues,
  selectedValue,
  defaultOptions,
  facilitatorType,
  initializeOptions,
}) => {
  const options = [...defaultOptions, ...adminAndMembersValues]

  const handleSelected = (selectedUserUuid: string, checked: boolean) => {
    setCheckedValue(checked)
    setSelectedFacilitator(selectedUserUuid)
    selectedValue({ selectedFacilitator: selectedUserUuid, approvalRequired: checked })
  }

  const selectedUser = options.find((user) => user.value === initializeOptions.selectedUser)

  const { user: currentUser } = useCurrentUser()

  const initializeUser = adminAndMembersValues.find((user) =>
    user.label.includes(currentUser.email),
  )

  const [checkedValue, setCheckedValue] = React.useState(
    initializeOptions.approvalRequired || false,
  )

  const [selectedFacilitator, setSelectedFacilitator] = useState(
    selectedUser?.value || initializeUser.value,
  )

  return (
    <>
      <ModalInputWrapper>
        <ModalInputLabel>{title}</ModalInputLabel>
        <Select
          options={options}
          noMinWidth
          className="w-full"
          value={selectedFacilitator || 'dynamic_gatekeeper'}
          onChange={(v) => handleSelected(v, checkedValue)}
        />
      </ModalInputWrapper>

      <ModalInputWrapper>
        <ModalInputLabel>
          Require Facilitator Approval
          <InfoTooltip className="ml-2">
            <Typography lineHeight={1.2} fontSize="13" className="pb-1" component="p">
              If checked, intro requests are first sent to the Facilitator for review.
              <br />
              If unchecked, intro requests will be sent directly to the requested person or company.
            </Typography>
          </InfoTooltip>
        </ModalInputLabel>

        <CheckBox
          checked={checkedValue || false}
          onChange={(e) => handleSelected(selectedFacilitator, e.target.checked)}
        />
      </ModalInputWrapper>
    </>
  )
}

export default FacilitatorSection
