import styled from 'styled-components'
import tw from 'twin.macro'

const ContextCard = styled.div`
  ${tw`p-1.5 rounded ml-1 mr-2`}
  background-color: ${({ theme }) => theme.layout.main_bg_color};
`

const ContextTitle = styled(ContextCard)`
  ${tw`p-1.5 rounded mx-0 mb-2`}
  background-color: ${({ theme }) => theme.colors.table_header};
`
const CardContainer = styled.div<{
  invert?: boolean
  showHoverStyle?: boolean
  positive?: boolean
  loading?: boolean
  animate?: boolean
}>`
  ${tw`py-2 px-6`}
  background-color: ${({ theme, invert }) => (invert ? 'none' : theme.colors.cardBackground)};
  border-top: ${({ theme }) => theme.border};
  transition: all 300ms ease-in-out;
  transition-property: color, background-color, border-color, opacity;

  &:last {
    border-bottom: ${({ theme }) => theme.border};
  }

  &:hover {
    background-color: ${({ theme, showHoverStyle }) => showHoverStyle && theme.layout.main_bg_color};
  }

  &:hover .like-icon {
    color: ${({ theme }) => theme.colors.purple};
  }

  .like-icon {
    animation: ${({ animate }) => (animate ? 'hithere 1s 1' : 'none')};
  }

  &:hover .like-text {
    color: ${({ theme }) => theme.colors.purple};
    opacity: 1;
  }

  .like-text {
    opacity: ${({ positive }) => (positive ? '1' : '0')};
  }
`

const StyledCardBody = styled.div`
  ${tw`flex justify-between`}
  background-color: ${({ theme }) => theme.colors.cardBackground};
`

const StyledCard = styled.div`
  ${tw`flex items-center`}
`

const HeightAdjust = styled.div<{ height: boolean }>`
  height: calc(100vh - ${({ height }) => height && '50px'});
`

export { ContextCard, ContextTitle, CardContainer, HeightAdjust, StyledCard, StyledCardBody }
