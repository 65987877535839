import axios from 'axios'

import {
  AutoCompleteCompany,
  DigestSettings,
  GetSenderUsersResponse,
  LimitedUser,
  ShoutOutBlueprint,
  Team,
  TeamHomeStats,
} from 'utils/types'

export interface GetTeamsResponse {
  companies: Team[]
}

export default {
  getTeams: () => {
    return axios.get<GetTeamsResponse>('/api/teams')
  },

  createTeam: (params: AutoCompleteCompany) => {
    return axios.post<{ team: Team }>('/api/teams', params)
  },

  getTeam: (slug: string) => {
    return axios.get<GetTeamsResponse>(`/api/teams/${slug}`)
  },

  getSenderUsers: (team: string, message_uuid?: string) => {
    return axios.get<GetSenderUsersResponse>(
      `/api/teams/${team}/sender_users?message_uuid=${message_uuid}`,
    )
  },

  getShoutOuts: (team_slug?: string) => {
    return axios.get<{ shout_outs: ShoutOutBlueprint[] }>(`/api/shout_outs`, {
      params: { team_slug },
    })
  },

  getAdvisorShoutOuts: (team_slug: string, uuid: string) => {
    return axios.get<{ shout_outs: ShoutOutBlueprint[] }>(`/api/shout_outs/${uuid}`, {
      params: { team_slug },
    })
  },

  createShoutOut: (props: {
    team_slug: string
    user_uuid: string
    message: string
    source_type?: string
    source_uuid?: string
  }) => {
    return axios.post(`/api/shout_outs`, props)
  },

  crmStatus: (team_slug: string) => {
    return axios.get<{
      syncing_salesforce?: boolean
      syncing_hubspot?: boolean
      salesforce_synced_recently?: boolean
      hubspot_synced_recently?: boolean
      salesforce_connected?: boolean
      hubspot_connected?: boolean
    }>(`/api/teams/${team_slug}/crm_status`)
  },

  sharesStillMatching: (team_slug: string) => {
    return axios.get<Array<{ id: string; name: string; affected_list_slugs: Array<string> }>>(
      `/api/teams/shares_still_matching?team_slug=${team_slug}`,
    )
  },

  connectionImports: (team_slug: string) => {
    return axios.get<Array<{ name: string; email: string }>>(
      `/api/teams/${team_slug}/connection_imports`,
    )
  },

  deleteShoutOuts: (team_slug: string, uuid: string) => {
    return axios.delete(`/api/teams/${team_slug}/shout_outs/${uuid}`)
  },

  requestToJoin: (team_slug: string, group_uuid?: string) => {
    return axios.post('/api/join_requests', { team_slug, group_uuid })
  },

  getJoinRequest: (team_slug: string, uuid: string) => {
    return axios.get<{
      join_request: { user: LimitedUser; group_name?: string; created_at: string }
    }>(`/api/join_requests/${uuid}`, { params: { team_slug } })
  },

  acceptJoinRequest: (team_slug: string, uuid: string) => {
    return axios.get(`/api/join_requests/${uuid}/accept`, { params: { team_slug } })
  },

  getDigestSettings: (team_slug: string) => {
    return axios.get<{ digest_settings: DigestSettings }>(`/api/teams/${team_slug}/digest_settings`)
  },

  setDigestSettings: (team_slug: string, params: DigestSettings) => {
    return axios.post(`/api/teams/${team_slug}/set_digest_settings`, params)
  },

  getGettingStarted: (team_slug: string) => {
    return axios.get(`/api/teams/${team_slug}/getting_started`)
  },

  getShareTeams: (team_slug: string) => {
    return axios.get<{ teams: Team[] }>('/api/teams/sharable_with', { params: { team_slug } })
  },

  getHomeTeams: () => {
    return axios.get('/api/teams/home_index')
  },

  getTeamHomeStats: (team_slug: string) => {
    return axios.get<{ team: TeamHomeStats }>(`/api/teams/${team_slug}/home_stats`)
  },
}
