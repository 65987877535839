import React, { useState } from 'react'

import { Field, FieldProps, Formik } from 'formik'

import CabalButton from 'global/CabalButton'
import { CheckBoxField, MultiSelect, SelectField } from 'global/Input'
import {
  ModalInputLabel,
  ModalInputWrapper,
  ModalSectionHeader,
  ModalSectionWrapper,
} from 'global/Modal/styles'
import { useAdvisors, useTeam } from 'store/hooks'

import { cn } from 'utils/styles'
import { InvestorCompany } from 'utils/types/investor'

interface Props {
  onHide: () => void
  companyData: InvestorCompany
  setCompanyData: (companyData: InvestorCompany) => void
  updateCompanyMutation: any
  teamSlug: string
  initialOwners?: string[]
  deleteInvestorCompanyMutation: any
  isDeleting: boolean
}

const ContactsSettings: React.FC<Props> = ({
  onHide,
  companyData,
  setCompanyData,
  teamSlug,
  updateCompanyMutation,
  initialOwners,
  deleteInvestorCompanyMutation,
  isDeleting,
}) => {
  const { advisors } = useAdvisors({ teamSlug })
  const { team } = useTeam(teamSlug)
  const [initialValues] = useState(companyData)

  const [owners, setOwners] = useState<string[]>(companyData?.owners?.map((o) => o.uuid ?? o) || [])

  return (
    <div>
      <Formik
        initialValues={initialValues ?? {}}
        onSubmit={(values) => {
          const ownerUuids: { [key: string]: boolean } = {}

          initialOwners?.forEach((o) => {
            ownerUuids[o] = false
          })

          owners.forEach((o) => {
            ownerUuids[o] = true
          })

          updateCompanyMutation.mutate({ portfolioCompany: values, ownerUuids })
        }}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            }}
          >
            <>
              <ModalSectionWrapper>
                <ModalSectionHeader>{team?.name}</ModalSectionHeader>

                <ModalInputWrapper>
                  <ModalInputLabel>Talent Coordinator</ModalInputLabel>
                  <Field name="talent_lead">
                    {(props: FieldProps) => (
                      <SelectField
                        placeholder="Select teammate"
                        fieldProps={props}
                        defaultValue={team?.talent_coordinator}
                        options={
                          team?.admins_and_members.map((f) => ({
                            label: f.name,
                            value: f.uuid,
                          })) || []
                        }
                        onChange={(opt) => {
                          props.form.setFieldValue('talent_lead', opt)
                          setCompanyData({ ...companyData, talent_lead: opt })
                        }}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
                <ModalInputWrapper>
                  <ModalInputLabel>Owners</ModalInputLabel>
                  <MultiSelect<string>
                    className="w-full"
                    placeholder="Owners"
                    data-testid="edit-company-owners-input"
                    options={
                      team?.admins_and_members.map((f) => ({
                        label: f.name,
                        value: f.uuid,
                      })) || []
                    }
                    value={owners}
                    onChange={(v) => {
                      setOwners(v)
                      setCompanyData({ ...companyData, owners: v })
                    }}
                    isLoading={!team?.admins_and_members}
                  />
                </ModalInputWrapper>
              </ModalSectionWrapper>
              <ModalSectionWrapper>
                <ModalSectionHeader>{companyData?.company_name}</ModalSectionHeader>

                <ModalInputWrapper>
                  <ModalInputLabel>Talent Coordinator</ModalInputLabel>
                  <Field name="talent_poc.email">
                    {(props: FieldProps) => (
                      <SelectField
                        placeholder="Select talent coordinator"
                        fieldProps={props}
                        options={
                          advisors?.map((f) => ({
                            label: f.email,
                            value: f.email,
                          })) || []
                        }
                        onChange={(opt) => {
                          props.form.setFieldValue('talent_poc.email', opt)
                          setCompanyData({ ...companyData, talent_poc: { email: opt } })
                        }}
                      />
                    )}
                  </Field>
                </ModalInputWrapper>
              </ModalSectionWrapper>
            </>

            <div
              className={cn('flex justify-between items-center mt-7 mb-6', {
                'justify-end': !companyData,
              })}
            >
              {!!companyData && (
                <CabalButton
                  variant="tertiary"
                  type="button"
                  disabled={isDeleting}
                  padding="0"
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteInvestorCompanyMutation()
                  }}
                >
                  Delete company
                </CabalButton>
              )}

              <CabalButton type="submit" working={updateCompanyMutation.isLoading}>
                Save
              </CabalButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default ContactsSettings
