import React, { useEffect, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useInfiniteQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'

import { renderFilters } from 'components/Filters'
import AddCandidate from 'containers/AddCandidate'
import CandidateDataTags from 'containers/CompanyPage/SharedListsSection/CandidateDataTags'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import {
  COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  LEVEL_OPTIONS,
  OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  OPPORTUNITY_TYPE_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
  STAGE_WITH_OPEN_TO_ALL_OPTIONS,
} from 'containers/ListIndexView/constants'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUserSettings, useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'
import GridWrapper from 'ui-components/GridWrapper'

import api, { callApi } from 'utils/api'
import { useSlug } from 'utils/hooks/useSlug'
import { cn } from 'utils/styles'
import { CandidateProfile } from 'utils/types'

export const TALENT_FILTERS = [
  {
    key: 'query',
    type: 'search',
    icon: 'far fa-search',
    labelStr: 'Search',
    label: 'Search',
    skipLabelInAppliedPill: true,
    noPopover: true,
  },
  {
    key: 'function',
    type: 'dropdown[]',
    label: 'Function',
    options: ROLE_FUNCTION_OPTIONS,
  },
  {
    key: 'level',
    type: 'dropdown[]',
    label: 'Level',
    options: LEVEL_OPTIONS,
  },
  {
    key: 'location',
    type: 'location[]',
    label: 'Location',
  },
  {
    key: 'stage_preference',
    type: 'dropdown[]',
    label: 'Stage',
    options: STAGE_WITH_OPEN_TO_ALL_OPTIONS,
  },
  {
    key: 'opportunity_type',
    type: 'dropdown[]',
    label: 'Job type',
    options: OPPORTUNITY_TYPE_OPTIONS,
  },
  {
    key: 'ideal_company_size',
    type: 'dropdown[]',
    label: 'Company Size',
    options: COMPANY_SIZE_WITH_OPEN_TO_ALL_OPTIONS,
  },
  {
    key: 'office_preference',
    type: 'dropdown[]',
    label: 'Office',
    options: OFFICE_WITH_OPEN_TO_ALL_OPTIONS,
  },
]

const CandidateCardV2 = ({
  candidateProfile,
  view,
}: {
  candidateProfile: CandidateProfile
  view: 'list' | 'grid'
}) => {
  const history = useHistory()
  const teamSlug = useTeamSlug()

  return (
    <DataCardV2
      onClick={() => history.push(`/${teamSlug}/candidates/${candidateProfile.uuid}`)}
      title={
        <>
          {candidateProfile?.global_person?.name}
          {candidateProfile.linkedin_url && (
            <Typography
              component="button"
              onClick={(e) => {
                e.stopPropagation()
                window.open(candidateProfile.linkedin_url, '_blank', 'noopener noreferrer')
              }}
              className="ml-2"
              fontSize="14"
              color="fog"
              lineHeight={1}
            >
              <i className="fab fa-linkedin" />
            </Typography>
          )}
        </>
      }
      avatar={
        candidateProfile.global_person?.image_url ? (
          <Avatar
            src={candidateProfile.global_person?.image_url}
            size={view === 'grid' ? '72' : '32'}
          />
        ) : (
          <i className="far fa-user" />
        )
      }
      className={cn({
        'border-b-[1px] dark:border-[#2d3748] border-[#E3E5E8] rounded-none hover:rounded':
          view === 'list',
      })}
      description={candidateProfile.title}
      descriptionBottom={
        <div className="flex flex-col items-start gap-2.5">
          {candidateProfile.company && (
            <Typography lineHeight={1}>
              <i className="far fa-building" /> {candidateProfile.company}
            </Typography>
          )}

          {candidateProfile.location && (
            <Typography lineHeight={1}>
              <i className="far fa-location-dot" /> {candidateProfile.location}
            </Typography>
          )}

          <CandidateDataTags company={candidateProfile} />
        </div>
      }
      view={view}
    />
  )
}

export default function MembersList() {
  const { teamSlug } = useSlug()
  const [candidateProfileModalOpen, setCandidateProfileModalOpen] = React.useState(false)
  const { settings, updateSetting } = useCurrentUserSettings()
  const [ totalCountText, setTotalCountText ] = useState('')
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const [view, setView] = useState<'list' | 'grid'>(settings.list_view_prefrence || 'list')

  useEffect(() => {
    if (settings.list_view_prefrence !== view) {
      updateSetting('list_view_prefrence', view)
    }
  }, [view])

  const [filtersValue, setFiltersValue] = useQueryParams({
    query: StringParam,
    function: ArrayParam,
    level: ArrayParam,
    location: ArrayParam,
    function_preference: ArrayParam,
    level_preference: ArrayParam,
    stage_preference: ArrayParam,
    opportunity_type: ArrayParam,
    ideal_company_size: ArrayParam,
    office_preference: ArrayParam,
  })

  const candidateProfilesQuery = useInfiniteQuery(
    [teamSlug, 'candidateProfiles', filtersValue],
    ({ pageParam: page = 1 }) => callApi(api.fetchCandidateProfiles, filtersValue, page, teamSlug),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
    },
  )

  useEffect(() => {
    if (candidateProfilesQuery.data?.pages[0].pagination.total_count) {
      setTotalCountText(
        `${candidateProfilesQuery.data?.pages[0].pagination.total_count} items`,
      )
    }
  }, [candidateProfilesQuery?.data?.pages, setTotalCountText])

  const candidateProfiles = React.useMemo(
    () => candidateProfilesQuery.data?.pages.flatMap((page) => page.results),
    [candidateProfilesQuery.data],
  )

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: TALENT_FILTERS,
    value: filtersValue as {
      query?: string
      function?: string[]
      level?: string[]
      location?: string[]
      function_preference?: string[]
      level_preference?: string[]
      stage_preference?: string[]
      opportunity_type?: string[]
      ideal_company_size?: string[]
      office_preference?: string[]
    },
    onChange: (newValues) => {
      setFiltersValue(newValues)
    },
    withPrefix: true,
  })

  const cardRow = (view: 'list' | 'grid') =>
    candidateProfiles?.map((candidateProfile) => (
      <CandidateCardV2
        view={view}
        key={candidateProfile.uuid}
        candidateProfile={candidateProfile}
      />
    ))

  const showAddPerson = candidateProfileModalOpen

  const noCandidates = !candidateProfilesQuery.isLoading && candidateProfiles?.length === 0

  return (
    <PageWrapper title="Candidates">
      <PageHeader
        actions={{
          share: () => {
            alert('share')
          },
          others: (
            <div className="inline-block">
              <CabalButton
                variant="link"
                leftIcon={<i className="far fa-plus" />}
                onClick={() => {
                  setCandidateProfileModalOpen(true)
                }}
              >
                Add Person
              </CabalButton>
              <CabalButton
                variant="link"
                className="w-[80px]"
                leftIcon={
                  view === 'list' ? (
                    <i className="far fa-grid fa-fw" />
                  ) : (
                    <i className="far fa-equals fa-fw" />
                  )
                }
                onClick={() => setView(view === 'list' ? 'grid' : 'list')}
              >
                {view === 'list' ? 'Grid' : 'List'}
              </CabalButton>
            </div>
          ),
        }}
        backUrl={`/${teamSlug}/lists`}
        renderFilters={
          <div>
            <>
              <div className="flex flex-wrap gap-2">{renderedFilters}</div>
              <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>
            </>
          </div>
        }
        data={{ title: 'All Candidates', description_parts:[{id:'total-count-all-candidates',type:'string',label: totalCountText}]  }}
      />

      <div ref={parent}>
        {showAddPerson && (
          <div className={`mb-4`}>
            <AddCandidate
              teamSlug={teamSlug!}
              listUuid={'all-candidates'}
              reload={() => candidateProfilesQuery.refetch()}
              onClose={() => setCandidateProfileModalOpen(false)}
              isTalent={true}
            />
          </div>
        )}
      </div>

      {noCandidates && filtersValue.query !== '' && !showAddPerson && (
        <div className="flex flex-col items-center justify-center mt-10 gap-4">
          <EmptyState
            icon={<i className="fa-light fa-user-magnifying-glass" />}
            heading="No Candidates"
            body={'Add candidates to get started'}
            cta={
              <CabalButton
                variant="primary"
                leftIcon={<i className="far fa-plus" />}
                onClick={() => setCandidateProfileModalOpen(true)}
                className="mt-2"
              >
                Add Candidates
              </CabalButton>
            }
          />
        </div>
      )}

      {noCandidates && filtersValue.query === '' && (
        <div className="flex flex-col items-center justify-center mt-10 gap-4">
          <Typography color="fog" fontSize="24">
            <i className="fal fa-user-magnifying-glass" />
          </Typography>
          <Typography fontSize="16">No Candidates</Typography>
          <Typography fontSize="12" color="fog" lineHeight={1.4}>
            Add or invite candidates to share with <br /> portfolio leaders and automate intros.
          </Typography>

          <CabalButton
            variant="primary"
            leftIcon={<i className="far fa-plus" />}
            onClick={() => setCandidateProfileModalOpen(true)}
            className="mt-2"
          >
            Add Candidates
          </CabalButton>
        </div>
      )}

      {view === 'grid' && <GridWrapper>{cardRow('grid')}</GridWrapper>}

      {view === 'list' && <>{cardRow('list')}</>}

      {candidateProfilesQuery.hasNextPage && (
        <div className="flex justify-center	my-6">
          <CabalButton
            disabled={candidateProfilesQuery.isFetchingNextPage}
            working={candidateProfilesQuery.isFetchingNextPage}
            onClick={() => candidateProfilesQuery.fetchNextPage()}
          >
            {'Load more'}
          </CabalButton>
        </div>
      )}
    </PageWrapper>
  )
}
