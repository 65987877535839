import React from 'react'
import { useParams } from 'react-router-dom'
import { useTeam } from 'store/hooks'
import { CompanySlugParam, Team } from 'utils/types'
import { useAccessControl } from 'global/AccessControl'
import { CardBg } from '../styles'
import CompanyInfo from './CompanyInfo'
import Legal from './Legal'
import Features from './Features'
import SectionHeader from 'ui-components/SectionHeader'
import Community from './Community'

export interface TeamSettingProps {
  team: Team
  reloadTeam: () => Promise<any>
}

const Company = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(companySlug)
  const { canExportAgreements, canViewEquity } = useAccessControl(companySlug)

  const { public_directory } = team || {}

  if (!team) return <></>

  const teamSettingProps: TeamSettingProps = {
    reloadTeam,
    team,
  }

  return (
    <div className="">
      <div>
        <SectionHeader hideTopBorder={true} title="Company Info" />
        <CardBg>
          <CompanyInfo {...teamSettingProps} />
        </CardBg>

        {public_directory && (
          <>
            <SectionHeader title="Directory" />

            <CardBg>
              <Community {...teamSettingProps} />
            </CardBg>
          </>
        )}
        <SectionHeader title="Features" />
        <CardBg>
          <Features />
        </CardBg>
      </div>
    </div>
  )
}

export default Company
