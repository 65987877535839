import React from 'react'

import { Props as SelectProps, StylesConfig } from 'react-select'
import { useTheme } from 'styled-components'

import { SelectOption, StyledAsyncSelect } from 'global/Input/Select'
import Typography from 'global/Typography'

import useColorMode from 'utils/hooks/useColorMode'

export default function CityInput(props: {
  value: string | null
  onChange: (val: string | null) => void
  error?: string | undefined
  onBlur?: SelectProps['onBlur']
}) {
  const theme = useTheme()

  const { isMobile } = useColorMode()

  const autocompleteService = new (window as any).google.maps.places.AutocompleteService()

  const showErrorBorder = false
  const removeBorder = false
  const disabled = false

  const selectStyles: StylesConfig<SelectOption<Value>, boolean> = {
    control: (base) => ({
      ...base,
      color: theme.colors.primary,
      backgroundColor: theme.layout.main_bg_color,
      borderRadius: '0.25rem',
      borderColor: showErrorBorder ? theme.colors.border_danger : theme.colors.border,
      borderWidth: removeBorder ? undefined : '1px',
      minHeight: 0,
      // Removes weird border around container
      boxShadow: undefined,
      ':hover': {
        backgroundColor: undefined,
        borderColor: showErrorBorder ? theme.colors.border_danger : theme.colors.border,
      },
    }),
    input: (base) => ({
      ...base,
      padding: '0',
      margin: 0,
      marginLeft: '0.25rem',
      color: theme.colors.primary,
      fontSize: '12px',
    }),
    menuList: (base) => ({
      ...base,
      color: theme.colors.primary,
      backgroundColor: theme.popup.background,
      padding: 0,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? theme.popup.select
        : state.isFocused
        ? theme.popup.select
        : theme.popup.background,
      color: theme.colors.primary,
      fontSize: '0.75rem',
      display: 'flex',
      justifyContent: 'flex-start',
      // needed to prevent space when using react-window fixed-size-list
      height: '100%',
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: 0,
      color: theme.colors.primary,
      paddingRight: '0.125rem',
      fontSize: '0.75rem',
    }),
    multiValue: (base) => ({
      ...base,
      color: theme.colors.primary,
      backgroundColor: theme.buttons.default.bg_color,
      margin: '0.125rem 0.25rem 0.125rem 0',
      borderRadius: '0.3rem',
      maxWidth: isMobile ? '200px' : '100%',
      fontSize: '12px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0.5rem',
      paddingLeft: '0.5rem',
      maxHeight: '110px',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      overflow: 'auto',
    }),
    multiValueRemove: (base, state) => {
      if (state?.data?.isFixed) {
        return { ...base, display: 'none' }
      }

      return {
        ...base,
        color: theme.colors.primary,
        cursor: 'pointer',
        padding: '0 0.1rem',
        ':hover': {
          backgroundColor: theme.colors.purple,
        },
      }
    },
    singleValue: (base) => ({
      ...base,
      color: disabled ? theme.colors.fog : theme.colors.primary,
      fontSize: '0.75rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: '3px',
      fontWeight: 'lighter',
      color: theme.colors.rain_fog,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: '3px',
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: '3px',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 120,
      backgroundColor: theme.popup.background,
    }),
    placeholder: (base) => ({
      ...base,
      marginLeft: '0.25rem',
      fontSize: '12px',
      color: theme.colors.placeholder,
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '0.25rem',
      minWidth: '200px',
      overflow: 'auto',
      backgroundColor: theme.popup.background,
    }),
    container: (base) => ({
      ...base,
      minWidth: '250px',
      cursor: disabled ? 'not-allowed' : 'pointer',
      pointerEvents: disabled ? 'all' : base.pointerEvents,
    }),
  }

  return (
    <div className="flex flex-col items-stretch">
      <StyledAsyncSelect
        removeFocus={false}
        styles={selectStyles}
        value={props.value}
        onChange={(e) => props.onChange(e as any)}
        showErrorBorder={Boolean(props.error)}
        onBlur={(e) => {
          props.onBlur?.(e)
        }}
        loadOptions={async (input) => {
          const options = await new Promise((resolve) => {
            autocompleteService.getPlacePredictions(
              {
                input: input,
                types: ['(cities)'],
              },
              (predictions: any[] | null, status: any) => {
                resolve(
                  predictions?.map((prediction) => ({
                    value: prediction.structured_formatting.main_text,
                    label: prediction.description,
                  })) ?? [],
                )
              },
            )
          })

          return options as any
        }}
        placeholder="Enter a city"
        className="location-input"
      />

      <div className="flex justify-start pt-1">
        <Typography color="border_danger" fontSize="11">
          {props.error}
        </Typography>
      </div>
    </div>
  )
}
