import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useInfiniteQuery, useMutation } from 'react-query'
import { ArrayParam, BooleanParam, StringParam } from 'use-query-params'

import {
  IResourceCompany,
  IResourceDiscount,
  IResourcePeople,
  ResourcesFilterTypes,
  ResourcesListContextProps,
} from 'containers/ListIndexView/ResourcesList/types'
import { useAccessControl } from 'global/AccessControl'
import PageWrapper from 'global/PageWrapper'
import { useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import companyList from 'utils/api/companyList'
import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import { CompanyListBlueprint } from 'utils/types'

import ResourceCompanyList from './ResourceCompanyList'
import ResourceDiscountList from './ResourceDiscountList'
import ResourcePeopleList from './ResourcePeopleList'

interface Props {
  companyListData: CompanyListBlueprint
  refetchCompanyList: () => void
}

export const ResourcesListContext = createContext<ResourcesListContextProps | undefined>(undefined)

export const useResourcesListContext = () => {
  const context = useContext(ResourcesListContext)
  if (context === undefined) {
    throw new Error('useResourcesListContext must be used within a ResourcesListProvider')
  }
  return context
}

const ResourcesList: React.FC<Props> = ({ companyListData, refetchCompanyList }) => {
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)

  const [view, setView] = useState<'list' | 'grid'>('grid')

  const queryParamConfigMap = useMemo(() => {
    return {
      search: ArrayParam,
      member_view: BooleanParam,
      tags: ArrayParam,
      location: ArrayParam,
      sort: StringParam,
    }
  }, [])
  const [tags, setTags] = useState<string[]>([])

  const [filters, setFilters_] = useQueryParamsWithSettings(queryParamConfigMap)

  const setFilters = (updatedFilters: ResourcesFilterTypes) => {
    setFilters_(updatedFilters)
  }

  const {
    isLoading: resourceListIsLoading,
    data,
    refetch: refetchResourceList,
  } = useInfiniteQuery(
    [
      teamSlug,
      'opportunityData',
      companyListData?.uuid,
      companyListData?.status?.stale,
      companyListData?.item_count,
      filters,
    ],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getCompanies,
        teamSlug, // team slug
        page, // page
        {
          ...filters,
          sources: [companyListData?.uuid],
        }, // filters
      ),
    {
      cacheTime: 0,

      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      keepPreviousData: true,
    },
  )

  const { mutate: createOrModifyListItem } = useMutation(
    ({ resourceUuid, resource }: { resourceUuid?: string; resource: any }) =>
      api.addElementToList(companyListData.uuid, resource, resourceUuid),
    {
      onSuccess: () => {
        refetchResourceList()
        refetchTags()
        cabalToast({ style: 'success', content: 'Changes Saved' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to add item' })
      },
    },
  )

  const resource: IResourcePeople[] | IResourceCompany[] | IResourceDiscount[] = useMemo(() => {
    let companiesWithResults: any = []
    // get all the companies with results
    if (data?.pages?.[0]?.sources?.includes(companyListData?.uuid) || !companyListData?.uuid) {
      companiesWithResults = data?.pages?.flatMap((page) => {
        return page.company_ids.map((id) => page.company_records[id])
      })
    }
    return companiesWithResults
  }, [data, companyList])

  const adminView = !!isAdminOrEmployee && companyListData.owned && !filters.member_view
  const resourcesListEmpty = resource.length === 0
  const memberView = !adminView
  const sharedListView = !companyListData.owned

  const { mutate: refetchTags, data: allTags } = useMutation(() =>
    api.getResourceListTags(companyListData.uuid),
  )

  useEffect(() => {
    if (allTags) {
      setTags(allTags.data.tags)
    } else {
      refetchTags()
    }
  }, [allTags, data, refetchTags])

  const contextValue = useMemo(() => {
    return {
      companyListData,
      refetchCompanyList,
      adminView,
      resourcePeople: resource,
      resourceCompany: resource,
      resource: resource,
      refetchResourceList,
      view,
      setView,
      refetchTags,
      tags,
      createOrModifyListItem,
    }
  }, [
    companyListData,
    refetchCompanyList,
    adminView,
    view,
    setView,
    resource,
    refetchResourceList,
    refetchTags,
    tags,
    createOrModifyListItem
  ])

  const isResourcePeopleList = companyListData.list_type === 'people'
  const isResourceCompanyList = companyListData.list_type === 'companies'
  const isResourceDiscountList = companyListData.list_type === 'discounts'

  return (
    <ResourcesListContext.Provider value={contextValue}>
      <PageWrapper title={companyListData.name}>
        {isResourcePeopleList && (
          <ResourcePeopleList
            filters={filters}
            setFilters={setFilters}
            listLoading={resourceListIsLoading}
            resourcesListEmpty={resourcesListEmpty}
            memberView={memberView}
            sharedListView={sharedListView}
            listUuid={companyListData.uuid}
            tags={tags}
          />
        )}
        {isResourceCompanyList && (
          <ResourceCompanyList
            filters={filters}
            setFilters={setFilters}
            listLoading={resourceListIsLoading}
            resourcesListEmpty={resourcesListEmpty}
            memberView={memberView}
            sharedListView={sharedListView}
          />
        )}
        {isResourceDiscountList && (
          <ResourceDiscountList
            filters={filters}
            setFilters={setFilters}
            listLoading={resourceListIsLoading}
            resourcesListEmpty={resourcesListEmpty}
            memberView={memberView}
            sharedListView={sharedListView}
          />
        )}
      </PageWrapper>
    </ResourcesListContext.Provider>
  )
}

export default ResourcesList
