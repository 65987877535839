import React from 'react'

import DropDownMenu, { DropdownMenuItem } from 'global/DropDownMenu'
import Typography from 'global/Typography'

interface Props {
  bulkMenuItems: (DropdownMenuItem | null)[]
  disabled?: boolean
}

const BulkActions: React.FC<Props> = ({ bulkMenuItems, disabled = false }) => {
  return (
    <div>
      <DropDownMenu
        trigger={
          <Typography
            className="flex gap-2 items-center cursor-pointer"
            color="purple"
            fontSize="12"
          >
            Bulk Actions
            <i className="far fa-chevron-down" />
          </Typography>
        }
        disabled={disabled}
        menuItems={bulkMenuItems}
      />
    </div>
  )
}

export default BulkActions
