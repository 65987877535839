import React from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import memoizeOne from 'memoize-one'
import { useQuery } from 'react-query'

import { FiltersValueType, renderFilters } from 'components/Filters'
import { getFormattedStage } from 'containers/CompanyPage/SharedListsSection/PortfolioDataTags'
import { useJobsListContext } from 'containers/ListIndexView/TalentList/JobsList'
import { IJob, JobsFilterTypes } from 'containers/ListIndexView/TalentList/JobsList/types'
import {
  COMPANY_SIZE_OPTIONS,
  OFFICE_OPTIONS,
  ROLE_FUNCTION_OPTIONS,
} from 'containers/ListIndexView/constants'
import { useAccessControl } from 'global/AccessControl'
import { CheckBox } from 'global/Input'
import Typography from 'global/Typography'
import { useTeam, useTeamSlug } from 'store/hooks'
import Switch from 'ui-components/Switch'

import api, { callApi } from 'utils/api'
import { GetInvestorTagsResponse } from 'utils/types/investor'

interface Props {
  disabled?: boolean
  filters: any
  setFilters: (filters: JobsFilterTypes) => void
  jobTags?: GetInvestorTagsResponse
  setSelectedJobs: (selectedJobs: IJob[]) => void
  investorTags?: GetInvestorTagsResponse
}

const upcastFilters = memoizeOne((filters: JobsFilterTypes) => {
  const result: FiltersValueType = {}

  const search = filters.search
  if (!!search?.length) {
    result.search = search
  }

  const portfolio_company_uuids = filters.portfolio_company_uuids
  if (!!portfolio_company_uuids?.length) {
    result.portfolio_company_uuids = portfolio_company_uuids
  }

  const tags = filters.tags
  if (!!tags?.length) {
    result.tags = tags
  }

  const stages = filters.stages
  if (!!stages?.length) {
    result.stages = stages
  }

  const sizes = filters.sizes
  if (!!sizes?.length) {
    result.sizes = sizes
  }

  const office_locations = filters.office_locations
  if (!!office_locations?.length) {
    result.office_locations = office_locations
  }

  const functions = filters.functions
  if (!!functions?.length) {
    result.functions = functions
  }

  const sort = filters.sort
  if (!!sort) {
    result.sort = sort
  }

  return result
}, isEqual)

const downcastFilters = memoizeOne((filters: FiltersValueType) => {
  const result: JobsFilterTypes = {}

  const search = filters['search'] as string[] | null
  if (!!search?.length) {
    result.search = search
  }

  const tags = filters['tags'] as string[] | null
  if (!!tags?.length) {
    result.tags = tags
  }

  const portfolio_company_uuids = filters['portfolio_company_uuids'] as string[] | null
  if (!!portfolio_company_uuids?.length) {
    result.portfolio_company_uuids = portfolio_company_uuids
  }

  const stages = filters['stages'] as string[] | null
  if (!!stages?.length) {
    result.stages = stages
  }

  const sizes = filters['sizes'] as string[] | null
  if (!!sizes?.length) {
    result.sizes = sizes
  }

  const office_locations = filters['office_locations'] as string[] | null
  if (!!office_locations?.length) {
    result.office_locations = office_locations
  }

  const functions = filters['functions'] as string[] | null
  if (!!functions?.length) {
    result.functions = functions
  }

  const sort = filters['sort'] as string | null
  if (!!sort) {
    result.sort = sort
  }

  return result
}, isEqual)

const JobsListFilters: React.FC<Props> = ({
  disabled,
  filters,
  setFilters,
  jobTags,
  setSelectedJobs,
  investorTags,
}) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { listItems, companyListData, view, setView } = useJobsListContext()
  const jobs = listItems.map((item) => item.job)

  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const tagOptions = jobTags?.tags || []
  const stageOptions = investorTags?.stages || []

  const adminView = !!isAdminOrEmployee && !!companyListData.owned
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const { data: jobCompanies } = useQuery(
    ['jobCompanies', companyListData.uuid, team?.slug],
    () => callApi(api.jobCompanies, companyListData.uuid, team?.slug!),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!companyListData.uuid,
    },
  )

  const [renderedFilters, appliedFilters] = renderFilters({
    filters: [
      {
        key: 'search',
        type: 'search',
        icon: 'far fa-search',
        labelStr: 'Search',
        label: 'Search',
        skipLabelInAppliedPill: true,
        noPopover: true,
      },
      {
        key: 'portfolio_company_uuids',
        type: 'dropdown[]',
        label: 'Companies',
        options: jobCompanies?.map((company: any) => ({
          label: company.company_name,
          value: company.uuid,
        })),
      },
      {
        key: 'tags',
        type: 'dropdown[]',
        label: 'Tags',
        options: tagOptions.map((s: string) => ({ label: s, value: s })),
      },
      {
        key: 'stages',
        type: 'dropdown[]',
        label: 'Stage',
        options: stageOptions.map((s: string) => ({ label: getFormattedStage(s), value: s })),
      },
      {
        key: 'sizes',
        type: 'dropdown[]',
        label: 'Size',
        options: COMPANY_SIZE_OPTIONS,
      },
      {
        key: 'functions',
        type: 'dropdown[]',
        label: 'Function',
        options: ROLE_FUNCTION_OPTIONS,
      },
      {
        key: 'office_locations',
        type: 'dropdown[]',
        label: 'Office',
        options: OFFICE_OPTIONS,
      },
      {
        key: 'sort',
        type: 'dropdown',
        label: (
          <Typography>
            <i className="far fa-sort fa-sm mr-1" />
            Sort
          </Typography>
        ),
        labelStr: 'Sort',
        options: [
          { label: 'Title (A-Z)', value: 'investor_company_jobs.title-asc' },
          { label: 'Title (Z-A)', value: 'investor_company_jobs.title-desc' },

          { label: 'Portfolio Company (A-Z)', value: 'investor_companies.company_name-asc' },
          { label: 'Portfolio Company (Z-A)', value: 'investor_companies.company_name-desc' },

          { label: 'Date Added (Newest)', value: 'investor_company_jobs.created_at-desc' },
          { label: 'Date Added (Oldest)', value: 'investor_company_jobs.created_at-asc' },
        ],
      },
    ].filter(Boolean),
    value: upcastFilters(filters),
    onChange: (f) => setFilters(downcastFilters(f)),
  })

  return (
    <div
      ref={parent}
      className={cx('flex-col w-full my-3', {
        'pointer-events-none opacity-50': disabled,
        'px-2.5': adminView,
      })}
    >
      <div className="flex flex-col sm:flex-row justify-between items-start">
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-wrap gap-2.5">
            {adminView && (
              <CheckBox
                onChange={(e) => {
                  setSelectedJobs(e.target.checked ? jobs : [])
                  if (e.target.checked && view === 'grid') {
                    setView('list')
                  }
                }}
              />
            )}
            {renderedFilters}
          </div>
          {adminView && (
            <div className="flex items-center gap-2">
              <Typography fontSize="12" color="fog">
                Member View
              </Typography>
              <Switch
                checked={filters.member_view}
                onCheckedChange={(e) => setFilters({ ...filters, member_view: e })}
              />
            </div>
          )}
        </div>
      </div>
      {appliedFilters.length > 0 && (
        <div className="flex gap-1 flex-wrap mt-2">{appliedFilters}</div>
      )}
    </div>
  )
}

export default JobsListFilters
