import React from 'react'
import { useParams } from 'react-router-dom'
import { cabalToast } from 'ui-components/Toast'

import { useAppDispatch, useTeam } from 'store/hooks'
import { CompanySlugParam, Team } from 'utils/types'
import { CardBg } from '../styles'
import { CheckBox, Label, MultiSelect } from 'global/Input'
import Typography from 'global/Typography'
import CabalButton from 'global/CabalButton'
import api from 'utils/api'
import MessageVisibility from './MessageVisibility'
import FooterToggle from './FooterToggle'
import SectionHeader from 'ui-components/SectionHeader'

export interface TeamSettingProps {
  team: Team0
  reloadTeam: () => Promise<any>
  updateTeam: (team: Partial<Team>) => void
  updatingTeam: boolean
}

const Company = () => {
  const { company_slug: companySlug } = useParams<CompanySlugParam>()
  const dispatch = useAppDispatch()

  const { team, reloadTeam } = useTeam(companySlug)

  const [updatedTeam, setUpdatedTeam] = React.useState<Team>()
  const [working, setWorking] = React.useState(false)

  React.useEffect(() => {
    if (team) setUpdatedTeam(team)
  }, [team])

  const handleSubmit = async () => {
    if (!updatedTeam) return
    setWorking(true)

    try {
      await api.updateTeam(updatedTeam.slug, {
        team: {
          track_message_clicks: updatedTeam.track_message_clicks,
          track_message_opens: updatedTeam.track_message_opens,
        },
      })
      await reloadTeam()

      cabalToast({ style: 'success', content: 'Successfully updated Company' })
    } catch (e) {}

    setWorking(false)
  }

  if (!updatedTeam) {
    return <div />
  }

  return (
    <div className="">
      <SectionHeader title="Tracking" hideTopBorder={true} />

      <CardBg>
        <label className="block cursor-pointer">
          <CheckBox
            checked={updatedTeam.track_message_clicks}
            onChange={(e) =>
              setUpdatedTeam({ ...updatedTeam, track_message_clicks: e.currentTarget.checked })
            }
            label="Track message clicks"
          />
        </label>
        <label className="block cursor-pointer">
          <CheckBox
            checked={updatedTeam.track_message_opens}
            onChange={(e) =>
              setUpdatedTeam({ ...updatedTeam, track_message_opens: e.currentTarget.checked })
            }
            label="Track message opens"
          />
        </label>
        <div className="flex justify-end">
          <CabalButton
            variant="primary"
            working={working}
            onClick={handleSubmit}
            data-testid="save-public-dir"
          >
            Save
          </CabalButton>
        </div>
      </CardBg>

      <SectionHeader title="Privacy" />
      <CardBg>
        <MessageVisibility />
      </CardBg>

      <SectionHeader title="Message Footer" />

      <CardBg>
        <FooterToggle />
      </CardBg>
    </div>
  )
}

export default Company
