import React, { useState } from 'react'

import { pick } from 'lodash'
import { useMutation, useQuery } from 'react-query'
import { useSetState } from 'react-use'

import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { CheckBox, Label, Select, TextArea } from 'global/Input'
import { useCurrentUser, useTeam, useTeamSlug, useUpdateTeam } from 'store/hooks'
import SectionHeader from 'ui-components/SectionHeader'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { DEFAULT_CANDIDATE_ONBOARDING_MESSAGE } from 'utils/constants/onboarding'
import { Team } from 'utils/types'

import { CardBg } from './styles'

const Investors: React.VFC = () => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { updateTeamAsync, isUpdatingTeam } = useUpdateTeam(teamSlug)
  const [updatedTeam, setUpdatedTeam] = useSetState(team)
  const { user, reloadUser } = useCurrentUser()
  const { canManageCompany } = useAccessControl(teamSlug)

  const [allowSendAsForIntros, setAllowSendAsForIntros] = useState(user.allow_send_as_for_intros)

  const updateTeam = () => {
    updateTeamAsync(
      pick<Team, keyof Team>(updatedTeam, ['portfolio_list_id', 'show_old_portfolio_on_page']),
    ).then(() => cabalToast({ style: 'success', content: 'Successfully updated' }))
  }

  const handleTalentSubmit = () => {
    updateUser()
    // OPTIMIZE: save once
    saveTalentCoordinator()
    saveCandidateWelcomeMessage()
    saveCandidateDefaultList()
    cabalToast({ style: 'success', content: 'Successfully updated' })
  }

  const { mutate: updateUser } = useMutation(
    ['updateUser'],
    () => callApi(api.updateCurrentProfile, { allow_send_as_for_intros: allowSendAsForIntros }),
    {
      onSuccess: () => {
        reloadUser()
      },
    },
  )

  const saveTalentCoordinator = () => {
    updateTeamAsync(pick<Team, keyof Team>(updatedTeam, 'talent_coordinator'))
  }

  const saveCandidateWelcomeMessage = () => {
    updateTeamAsync(pick<Team, keyof Team>(updatedTeam, 'talent_onboarding_message'))
  }

  const saveCandidateDefaultList = () => {
    updateTeamAsync(pick<Team, keyof Team>(updatedTeam, 'default_talent_list_id'))
  }

  const { data: listOptionsData, isLoading: loadingLists } = useQuery(
    ['getCompanyListFilterOptions'],
    () => callApi(api.getCompanyListFilterOptions),
  )
  const listOptions = listOptionsData?.lists || []

  const { data: talentsListData, isLoading: loadingTalentsList } = useQuery(
    ['getTalentLists'],
    () => callApi(api.getTalentLists, teamSlug),
  )
  const talentsList = talentsListData?.company_lists.filter((list) => !list.is_all_candidates) || []

  return (
    <div className="pb-5">
      {canManageCompany && (
        <>
          <SectionHeader title="Portfolio" />
          <CardBg>
            <div className="flex justify-between items-center">
              <Label label="Dynamic portfolio list" className="w-full">
                <Select<string>
                  className="mb-3 w-full"
                  options={listOptions}
                  value={loadingLists ? undefined : updatedTeam.portfolio_list_id || undefined}
                  onChange={(list) => setUpdatedTeam({ portfolio_list_id: list })}
                />
              </Label>
            </div>
            <div className="flex justify-end mt-5">
              <CabalButton
                variant="primary"
                disabled={isUpdatingTeam}
                working={isUpdatingTeam}
                onClick={updateTeam}
                data-testid="save-public-dir"
              >
                Save
              </CabalButton>
            </div>
          </CardBg>
        </>
      )}
      <SectionHeader hideTopBorder={true} title="Talent" />
      <CardBg>
        <div className="flex justify-between items-center">
          <CheckBox
            checked={allowSendAsForIntros}
            onChange={(e) => setAllowSendAsForIntros(e.currentTarget.checked)}
            label={`Allow Cabal to send talent introductions on my behalf`}
          />
        </div>
        {canManageCompany && (
          <>
            <div className="flex justify-between items-center mt-5">
              <Label label="Talent Coordinator" className="w-full">
                <Select<string>
                  className="mb-3 w-full"
                  options={
                    team?.admins_and_members.map((f) => ({
                      label: f.name,
                      value: f.uuid,
                    })) || []
                  }
                  value={updatedTeam.talent_coordinator || ''}
                  onChange={(u) => setUpdatedTeam({ talent_coordinator: u })}
                />
              </Label>
            </div>
            <div className="flex justify-between items-center mt-5">
              <Label label="Candidate welcome message" className="w-full">
                <TextArea
                  value={updatedTeam.talent_onboarding_message}
                  placeholder={DEFAULT_CANDIDATE_ONBOARDING_MESSAGE}
                  rows={5}
                  className="w-full"
                  onChange={(e) => setUpdatedTeam({ talent_onboarding_message: e.target.value })}
                />
              </Label>
            </div>
            <div className="flex justify-between items-center mt-5">
              <Label label="Candidate default list" className="w-full">
                <Select<string>
                  className="mb-3 w-full"
                  placeholder="Leave it blank to let the system choose for you"
                  options={
                    talentsList
                      ? talentsList.map((talentList) => ({
                          value: talentList.uuid,
                          label: talentList.name,
                        }))
                      : []
                  }
                  value={
                    loadingTalentsList ? undefined : updatedTeam.default_talent_list_id || undefined
                  }
                  onChange={(talentList) => setUpdatedTeam({ default_talent_list_id: talentList })}
                />
              </Label>
            </div>
            <div className="flex justify-end mt-5">
              <CabalButton
                variant="primary"
                disabled={isUpdatingTeam}
                working={isUpdatingTeam}
                onClick={handleTalentSubmit}
                data-testid="save-public-dir"
              >
                Save
              </CabalButton>
            </div>
          </>
        )}
      </CardBg>
    </div>
  )
}

export default Investors
