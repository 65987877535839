import React from 'react'

import Typography from 'global/Typography'

import { AdvisorModel } from 'utils/types'

import AdvisorPill from './AdvisorPill'

interface Props {
  teamSlug: string
  teamName: string
  personName: string
  advisors: AdvisorModel[]
  selectedAdvisorUuids: string[]
  onChangeAdvisorUuids: (uuids: string[]) => void
  onBack?: () => void
}

const SelectAdvisors: React.FC<Props> = ({
  teamSlug,
  teamName,
  personName,
  advisors,
  selectedAdvisorUuids,
  onChangeAdvisorUuids,
}) => {
  const handleSelection = (user_uuid: string) => {
    if (selectedAdvisorUuids.includes(user_uuid)) {
      onChangeAdvisorUuids(selectedAdvisorUuids.filter((uuid) => uuid !== user_uuid))
    } else {
      onChangeAdvisorUuids([...selectedAdvisorUuids, user_uuid])
    }
  }

  return (
    <>
      <div className="flex-initial flex flex-col gap-2">
        <Typography fontSize="14" fontWeight={500} className="flex items-center">
          Get intro from:
        </Typography>
      </div>
      <div className="flex-grow overflow-auto">
        <div className="flex flex-wrap gap-2">
          {advisors.map((advisor) => (
            <div key={advisor.uuid}>
              <AdvisorPill
                advisor={advisor}
                selected={selectedAdvisorUuids.includes(advisor.uuid)}
                onSelect={handleSelection}
                teamSlug={teamSlug}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SelectAdvisors
