import React, { useMemo } from 'react'

import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import ChartEmptyState, { IChartEmptyStateProps } from 'components/ActivityCharts/ChartEmptyState'
import LineChart from 'components/ActivityCharts/LineChart'
import { IActivityDashboard } from 'components/ActivityCharts/types'
import DropDownMenu from 'global/DropDownMenu'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import { cn } from 'utils/styles'

const SectionWrapper = styled.div`
  border: ${({ theme }) => theme.border};
`

interface Props {
  name: string
  title: string
  data: IActivityDashboard
  onSetSelectedPeriod: (val: string) => void
  currentPeriod: string
  onSetShowCumulative: (val: boolean) => void
  emptyState?: IChartEmptyStateProps
}

const getPeriodLabel = (period: string): string => {
  return period
    ?.split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

const ChartSection: React.FC<Props> = ({
  name,
  title,
  data,
  onSetSelectedPeriod,
  currentPeriod,
  onSetShowCumulative,
  emptyState,
}) => {
  const teamSlug = useTeamSlug()
  const history = useHistory()
  const trendColor = data.trend == 0 ? 'gray' : data.trend > 0 ? 'green' : 'red'
  const trendArrow =
    data.trend == 0
      ? 'fa-arrow-right'
      : data.trend > 0
      ? 'fa-arrow-up-right'
      : 'fa-arrow-down-right'

  const MemoizedChartSection = useMemo(() => {
    return <LineChart title={title} labels={data.labels} data={data.data} />
  }, [data.data, data.labels, title])

  const showEmptyState =
    data.total === 0 &&
    emptyState &&
    (currentPeriod === 'last_90_days' || currentPeriod === 'all_time')

  const headerActions = {
    view_deal_board: () => history.push(`/${teamSlug}/page`),
    request_intro_v2: () => history.push(`/${teamSlug}/intro-center`),
    request_intro_accepted: () => history.push(`/${teamSlug}/intro-center`),
  }

  const headerAction = headerActions[name as keyof typeof headerActions] || null

  return (
    <SectionWrapper className={'relative rounded-md'}>
      <div
        className={cn('p-5 flex flex-col gap-1', {
          'opacity-20': showEmptyState,
        })}
      >
        <div className="flex justify-between items-center">
          <Typography
            fontSize="14"
            color="fog"
            className={cn({
              'cursor-pointer': headerAction,
            })}
            onClick={() => (headerAction ? headerAction() : () => {})}
          >
            <i className={`far ${data.icon} fa-sm mr-2`} />
            {title}
          </Typography>
          <DropDownMenu
            trigger={
              <Typography fontSize="12" color="fog">
                <i className="fa-solid fa-ellipsis-vertical px-2 pl-2"></i>
              </Typography>
            }
            menuItems={[
              {
                label: 'Show cumulative',
                onSelect: () => onSetShowCumulative(true),
              },
              {
                label: 'Show new',
                onSelect: () => onSetShowCumulative(false),
              },
            ]}
          />
        </div>
        <Typography fontWeight={600} fontSize="20">
          {new Intl.NumberFormat().format(data.total)}
        </Typography>
        <div className="flex justify-between items-center">
          <DropDownMenu
            trigger={
              <Typography fontSize="12" color="fog">
                {getPeriodLabel(currentPeriod)}
                <i className="far fa-chevron-down fa-xs ml-1" />
              </Typography>
            }
            menuItems={[
              {
                label: 'All time',
                onSelect: () => onSetSelectedPeriod('all_time'),
              },
              {
                label: 'Last 365 days',
                onSelect: () => onSetSelectedPeriod('last_365_days'),
              },
              {
                label: 'Last 90 days',
                onSelect: () => onSetSelectedPeriod('last_90_days'),
              },
              {
                label: 'Last 30 days',
                onSelect: () => onSetSelectedPeriod('last_30_days'),
              },
              {
                label: 'Last 7 days',
                onSelect: () => onSetSelectedPeriod('last_7_days'),
              },
              {
                label: 'Month to date',
                onSelect: () => onSetSelectedPeriod('month_to_date'),
              },
              {
                label: 'Year to date',
                onSelect: () => onSetSelectedPeriod('year_to_date'),
              },
            ]}
          />

          <div className="flex gap-2">
            <Typography fontSize="12">{Math.abs(data.trend)}</Typography>
            <Typography fontSize="12" color={trendColor}>
              <i className={`far ${trendArrow}`} />
            </Typography>
          </div>
        </div>

        {MemoizedChartSection}
      </div>
      {showEmptyState && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-10">
          <ChartEmptyState
            icon={<i className={`far ${emptyState.icon}`} />}
            title={emptyState.title}
            subtitle={emptyState.subtitle}
            buttonText={emptyState.buttonText}
            onClick={emptyState.onClick}
          />
        </div>
      )}
    </SectionWrapper>
  )
}

export default ChartSection
