import Typography from 'global/Typography'
import styled from 'styled-components'
import tw from 'twin.macro'

const Divider = styled.div`
  ${tw`my-5`}
  border-bottom: ${({ theme }) => theme.border};
`
const OptionButtonWrapper = styled.div`
  ${tw`text-sm cursor-pointer shadow focus:outline-none focus:ring`}

  color: ${({ theme }) => theme.colors.purple};
  border-radius: 0.25rem;
  background: rgba(92, 105, 209, 0.16);
  padding: 0.125rem 0.25rem;
`
const SendTest = styled.div`
  ${tw`flex justify-center cursor-pointer mr-4`}
  background-color: ${({ theme }) => theme.colors.light_yellow};
  border-radius: 0.25rem;
  padding: 0.25rem 0.375rem;
`
const PropWrapper = styled.div`
  ${tw`cursor-pointer`}
  &:hover {
    background-color: ${({ theme }) => theme.buttons.secondary.bg_hover};
  }
  color: ${({ theme }) => theme.buttons.secondary.text_color};
  background-color: ${({ theme }) => theme.buttons.secondary.bg_color};
  border-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 0.8125rem;
  font-weight: 600;
`

const CcButton = styled(Typography)`
  ${tw`block px-3 align-middle ml-1 md:ml-2 rounded focus:ring h-full`}

  background-color: ${({ theme }) => theme.layout.main_bg_color};
  color: ${({ theme }) => theme.colors.fog};

  &:hover {
    color: ${({ theme }) => theme.colors.rain};
  }
`

export { Divider, OptionButtonWrapper, PropWrapper, SendTest, CcButton }
