import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'
import CabalButton from 'global/CabalButton'
import { DatePickerModal, Label } from 'global/Input'

import { TeamSettingProps } from '.'

const CSV_EXPORT_AGREEMENTS_PATH = '/api/agreements/export.csv'

const AgreementsExport: React.VFC<TeamSettingProps> = ({ team }) => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const getExportUrl = (exportPath: string) => {
    const url = new URL(window.location.origin)

    url.pathname = exportPath
    url.search = new URLSearchParams({
      team_slug: team.slug,
      start_date: startDate,
      end_date: endDate,
    }).toString()

    return url.toString()
  }

  return (
    <>
      <Typography className="block" color="fog_rain" fontSize="14" fontWeight={400}>
        Export your company&apos;s advisor grants. The export does not include investor grants and
        only agreements signed in Cabal are exported.
      </Typography>

      <div className="my-4 grid grid-cols-2 gap-4">
        <div>
          <DatePickerModal
            className="w-full mb-2"
            placeholder="Start date"
            label="Start date (optional)"
            id="start_date"
            labeled
            date={startDate}
            maxDate={moment().toDate()}
            onDateChange={(value) => setStartDate(value)}
          />
        </div>

        <div>
          <DatePickerModal
            className="w-full mb-2"
            placeholder="End date"
            label="End date (optional)"
            id="end_date"
            date={endDate}
            maxDate={moment().add(1, 'day').toDate()}
            onDateChange={(value) => setEndDate(value)}
          />
        </div>
      </div>

      <BorderedDiv>
        <Typography className="block flex-grow" color="fog_rain" fontSize="14" fontWeight={400}>
          Download a CSV with details of your companys advisor grants.
          <br />
          To upload this to Carta, copy and paste all cells into Carta&apos;s import file.
        </Typography>

        <div className="flex-shrink-0">
          <CabalButton component="a" href={getExportUrl(CSV_EXPORT_AGREEMENTS_PATH)}>
            Download CSV
          </CabalButton>
        </div>
      </BorderedDiv>
    </>
  )
}

const BorderedDiv = styled.div`
  ${tw`flex p-4 mb-4 space-x-4 items-center`};
  border: ${({ theme }) => theme.border};
`

export default AgreementsExport
