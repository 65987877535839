import React, { useEffect, useState } from 'react'

import { Field, FieldProps, Form, Formik } from 'formik'
import { array, object, string } from 'yup'

import { Footer } from 'containers/CandidateProfileModal'
import { SelectWrapper } from 'containers/CandidateProfileModal/PersonalInfo'
import EnrichFromLinkedin from 'containers/ListIndexView/Components/EnrichFromLinkedin'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { MultiSelect, TextInputField, UploadButton } from 'global/Input'
import CityInput from 'global/Input/CityInput'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalInputWrapper } from 'global/Modal/styles'
import Typography from 'global/Typography'

import resourceList from 'utils/api/resourceList'

interface ResourcePerson {
  first_name: string
  last_name: string
  email: string
  linkedin_url: string
  avatar: string
  location: string
  title: string
  company: string
  about: string
  tags?: string[]
}
interface Props {
  onHide: () => void
  itemUuid?: string
  onSave: (personUuid: string, resourcePerson: any) => void
  tags: string[]
}

const AddResourcePersonModal = ({ onHide, itemUuid, onSave, tags }: Props) => {
  const [step, setStep] = useState(0)
  const [globalPersonUuid, setGlobalPersonUuid] = useState<string>('')

  const [resourcePerson, setResourcePerson] = useState<ResourcePerson>({
    first_name: '',
    last_name: '',
    email: '',
    linkedin_url: '',
    avatar: '',
    location: '',
    title: '',
    company: '',
    about: '',
    tags: [],
  })

  useEffect(() => {
    if (itemUuid) {
      resourceList.getItem(itemUuid).then((result) => {
        setResourcePerson({
          first_name: result.data.first_name,
          last_name: result.data.last_name,
          email: result.data.email,
          linkedin_url: result.data.profile_url,
          avatar: result.data.avatar,
          location: result.data.location || '',
          title: result.data.position,
          company: result.data.company_name,
          about: result.data.about,
          tags: result.data.tags ?? [],
        })
        setGlobalPersonUuid(result.data.global_person_uuid)
        setStep(1)
      })
    }
  }, [itemUuid])

  const postEnrichment = (data: any) => {
    setResourcePerson({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      linkedin_url: data.linkedin_url,
      avatar: data.profile_pic_url,
      location: data.city || '',
      title: data.experiences[0]?.title ?? '',
      company: data.experiences[0]?.company ?? '',
      about: '',
      tags: [],
    })
    setStep(1)
  }

  const profileSchema = object({
    first_name: string().required('This field is required'),
    last_name: string().required('This field is required'),
    email: string().required('This field is required').email('Invalid email'),
    linkedin_url: string(),
    company: string().required('This field is required'),
    location: string(),
    title: string().required('This field is required'),
    tags: array(),
    about: string(),
  })

  return (
    <Modal header="Add Person" show={true} onHide={onHide}>
      {step === 0 && !itemUuid && <EnrichFromLinkedin postEnrichment={postEnrichment} />}
      {step === 1 && (
        <Formik
          initialValues={resourcePerson}
          validationSchema={profileSchema}
          onSubmit={(values) => {
            onSave(globalPersonUuid, values)
            onHide()
          }}
        >
          {({ handleSubmit,setFieldValue }) => (
            <Form
              onSubmit={(e) => {
                handleSubmit(e)
              }}
            >
              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Photo</div>
                </ModalInputLabel>
                <div className="flex">
                  <UploadButton
                    allowedExtensions={['png', 'jpg', 'jpeg']}
                    onUpload={(uuid) => {
                      const newAvatar = `/api/uploads/${uuid}`
                      setResourcePerson({ ...resourcePerson, avatar: newAvatar })
                      setFieldValue('avatar', newAvatar)
                    }}
                    showPencil={true}
                    text=""
                    triggerProps={{
                      variant: 'tertiary',
                      leftIcon: (
                        <Typography className="flex items-start justify-start">
                          <Avatar src={resourcePerson.avatar} size={'32'} />
                          <p className="pt-4 pl-3">Edit</p>
                        </Typography>
                      ),
                      padding: '0',
                    }}
                  />
                </div>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">First Name</div>
                </ModalInputLabel>
                <Field name="first_name">
                  {(props: FieldProps) => (
                    <>
                      <TextInputField
                        placeholder="Enter first name"
                        error={props.meta.touched ? props.meta.error : undefined}
                        {...props.field}
                      />
                    </>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Last Name</div>
                </ModalInputLabel>
                <Field name="last_name">
                  {(props: FieldProps) => (
                    <>
                      <TextInputField
                        placeholder="Enter last name"
                        clearable={false}
                        required
                        error={props.meta.touched ? props.meta.error : undefined}
                        {...props.field}
                      />
                    </>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Email</div>
                </ModalInputLabel>
                <Field name="email">
                  {(props: FieldProps) => (
                    <TextInputField
                      placeholder="Enter email"
                      error={props.meta.touched ? props.meta.error : undefined}
                      {...props.field}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">LinkedIn URL</div>
                </ModalInputLabel>
                <Field name="linkedin_url">
                  {(props: FieldProps) => {
                    const linkedinPattern = new RegExp(
                      '^(http(s)?:\\/\\/)?([w]{3}.)?linkedin\\.com\\/in\\/.+$',
                    )
                    const isLinkedinUrl = linkedinPattern.test(resourcePerson.linkedin_url)
                    return (
                      <TextInputField
                        {...props.field}
                        error={props.meta.touched ? props.meta.error : undefined}
                        placeholder="LinkedIn URL"
                        disabled={isLinkedinUrl}
                      />
                    );
                  }}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Company</div>
                </ModalInputLabel>
                <Field name="company">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      error={props.meta.touched ? props.meta.error : undefined}
                      placeholder="Current company"
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Title</div>
                </ModalInputLabel>
                <Field name="title">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      error={props.meta.touched ? props.meta.error : undefined}
                      placeholder="Title"
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Location</div>
                </ModalInputLabel>
                <Field name="location">
                  {(props: FieldProps) => (
                    <SelectWrapper>
                      <CityInput
                        {...props.field}
                        onBlur={() => {
                          props.form.setFieldTouched(props.field.name, true)
                        }}
                        onChange={(opt) => {
                          props.form.setFieldValue(props.field.name, opt?.value)
                        }}
                        value={
                          props.field.value
                            ? { value: props.field.value, label: props.field.value }
                            : null
                        }
                        error={props.meta.touched ? props.meta.error : undefined}
                      />
                    </SelectWrapper>
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper>
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">Select Tags</div>
                </ModalInputLabel>
                <Field name="tags">
                  {(props: FieldProps) => (
                    <MultiSelect
                      value={resourcePerson.tags ?? []}
                      options={tags?.map((tag) => ({ label: tag, value: tag })) ?? []}
                      creatable
                      onChange={(selectedTags) => {
                        setResourcePerson({ ...resourcePerson, tags: selectedTags })
                        props.form.setFieldValue(props.field.name, selectedTags)
                      }}
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <ModalInputWrapper className="py-2">
                <ModalInputLabel>
                  <div className="flex items-center justify-start h-full">About</div>
                </ModalInputLabel>
                <Field name="about">
                  {(props: FieldProps) => (
                    <TextInputField
                      {...props.field}
                      error={props.meta.touched ? props.meta.error : undefined}
                      placeholder="About"
                      component="textarea"
                    />
                  )}
                </Field>
              </ModalInputWrapper>

              <div>
                <Footer className="-mx-6 flex justify-center mt-4 pt-6 pb-5">
                  <CabalButton type="submit" variant="primary" className="w-1/4">
                    Save
                  </CabalButton>
                </Footer>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default AddResourcePersonModal
