import React, { useEffect } from 'react'

import cx from 'classnames'
import Cookies from 'js-cookie'
import styled from 'styled-components'
import tw from 'twin.macro'

import useCrmStatus from 'components/CrmStatus'
import { Login } from 'containers/LoginPage'
import UserDropDown from 'containers/NavHome/UserDropDown'
import IndeterminantTeamPage from 'containers/ViewCompany/IndeterminantTeamPage'
import Loading from 'global/Loading'
import { useCurrentUser, useTeam } from 'store/hooks'

import { useSlug } from 'utils/hooks/useSlug'

import Header from './Header'
import ListDetails from './ListDetails'
import NewTeam from './NewTeam'

const Container = styled.div`
  ${tw`flex flex-col gap-4 overflow-auto h-screen`}
`

type State = 'login' | 'new-team' | 'list-details'

const MemberAddList: React.FC = () => {
  const { teamSlug } = useSlug()
  const { team: teamAdvised } = useTeam(teamSlug)
  const { isLoggedIn, user } = useCurrentUser()
  const { isLoading: isLoadingUserTeam } = useTeam(user.team?.slug)
  useCrmStatus(user.team?.slug)

  useEffect(() => {
    if (!isLoggedIn) {
      Cookies.set('return_to', window.location.href)
    } else {
      Cookies.remove('return_to')
    }
  }, [isLoggedIn])

  let state: State
  let headerText: React.ReactNode

  if (!isLoggedIn) {
    state = 'login'
    headerText = (
      <>
        Get {teamAdvised?.name}’s help with sales referrals and more. <br />
        Please sign in to continue.
      </>
    )
  } else if (!user.team) {
    state = 'new-team'
    headerText = <>Next, tell us about your company</>
  } else {
    state = 'list-details'
    headerText = <>How would you like to share your pipeline?</>
  }

  if (!teamAdvised || isLoadingUserTeam)
    return (
      <div className="h-screen w-screen relative">
        <Loading className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
      </div>
    )

  return (
    <Container>
      {isLoggedIn && (
        <div className="flex w-full justify-end p-6">
          <UserDropDown />
        </div>
      )}
      <div className={cx('p-16 flex flex-col items-center', { 'pt-0': isLoggedIn })}>
        <Header teamAdvised={teamAdvised}>{headerText}</Header>

        {state === 'login' && (
          <div className="w-96">
            <Login />
          </div>
        )}
        {state === 'new-team' && (
          <div className="w-96">
            <NewTeam teamAdvised={teamAdvised} nextStep={() => {}} />
          </div>
        )}
        {state === 'list-details' && <ListDetails teamAdvised={teamAdvised} nextStep={() => {}} />}
      </div>
    </Container>
  )
}

export default MemberAddList
