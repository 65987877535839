import React, { createContext, useContext, useMemo, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useInfiniteQuery, useQuery } from 'react-query'
import { ArrayParam, BooleanParam, StringParam } from 'use-query-params'

import JobsAddWidget from 'containers/ListIndexView/TalentList/JobsList/JobsAddWidget'
import JobsListAdminView from 'containers/ListIndexView/TalentList/JobsList/JobsListAdminView'
import JobsListBulkActions from 'containers/ListIndexView/TalentList/JobsList/JobsListBulkActions'
import JobsListCTAs from 'containers/ListIndexView/TalentList/JobsList/JobsListCTAs'
import JobsListEmptyState from 'containers/ListIndexView/TalentList/JobsList/JobsListEmptyState'
import JobsListFilters from 'containers/ListIndexView/TalentList/JobsList/JobsListFilters'
import JobsListHeader from 'containers/ListIndexView/TalentList/JobsList/JobsListHeader'
import JobsListMemberView from 'containers/ListIndexView/TalentList/JobsList/JobsListMemberView'
import {
  IJob,
  JobsFilterTypes,
  JobsListContextProps,
} from 'containers/ListIndexView/TalentList/JobsList/types'
import { StickyHeader } from 'containers/ListIndexView/style'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'
import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import { CompanyListBlueprint } from 'utils/types'

export const JobsListContext = createContext<JobsListContextProps | undefined>(undefined)

export const useJobsListContext = () => {
  const context = useContext(JobsListContext)
  if (context === undefined) {
    throw new Error('useJobsListContext must be used within a JobsListProvider')
  }
  return context
}

interface Props {
  companyListData: CompanyListBlueprint
  refetchCompanyList: () => void
}

const JobsList: React.FC<Props> = ({ companyListData, refetchCompanyList }) => {
  const defaultView = 'grid'
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const [view, setView] = useState<'grid' | 'list'>(defaultView)
  const [selectedJobs, setSelectedJobs] = useState<IJob[]>([])
  const [showAddWidget, setShowAddWidget] = useState(false)

  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const queryParamConfigMap = useMemo(() => {
    return {
      search: ArrayParam,
      portfolio_company_uuids: ArrayParam,
      tags: ArrayParam,
      stages: ArrayParam,
      sizes: ArrayParam,
      office_locations: ArrayParam,
      functions: ArrayParam,
      sort: StringParam,
      member_view: BooleanParam,
    }
  }, [])

  const [filters, setFilters_] = useQueryParamsWithSettings(queryParamConfigMap)

  const { data: jobTags } = useQuery(
    ['getJobTags', companyListData.owning_team.slug],
    () => callApi(api.getJobTags, companyListData.owning_team.slug!, companyListData?.uuid),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!companyListData.owning_team.slug,
    },
  )

  const { data: investorTags } = useQuery(
    ['getInvestorTags', companyListData.owning_team.slug],
    () => callApi(api.getInvestorTags, companyListData.owning_team.slug!),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!companyListData.owning_team.slug,
    },
  )

  const {
    isLoading: companyDataIsLoading,
    data,
    refetch: refetchListItems,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      teamSlug,
      'opportunityData',
      companyListData?.uuid,
      companyListData?.status?.stale,
      companyListData?.item_count,
      filters,
    ],
    ({ pageParam: page = 1 }) =>
      callApi(api.getJobsV2, teamSlug, companyListData?.uuid, page, filters),
    {
      cacheTime: 0,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      keepPreviousData: true,
    },
  )

  const listItems: IJob[] = useMemo(() => {
    return (
      data?.pages?.flatMap((page) => {
        return page.items || []
      }) || []
    )
  }, [data])

  const setFilters = (updatedFilters: JobsFilterTypes) => {
    setFilters_(updatedFilters)
  }

  const jobsListEmpty = listItems?.length === 0
  const adminView = !!isAdminOrEmployee && !!companyListData.owned && !filters.member_view
  const memberView = !isAdminOrEmployee || !!filters.member_view
  const sharedListView = !companyListData.owned

  const contextValue = useMemo(() => {
    return {
      companyListData,
      refetchCompanyList,
      listItems,
      refetchListItems,
      adminView,
      view,
      setView,
    }
  }, [companyListData, refetchCompanyList, listItems, refetchListItems, adminView, view, setView])

  return (
    <JobsListContext.Provider value={contextValue}>
      <PageWrapper title="Jobs List">
        <StickyHeader>
          <div className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center">
            <JobsListHeader />

            <div className="flex gap-6">
              <JobsListBulkActions selectedJobs={selectedJobs} jobTags={jobTags} />
              <JobsListCTAs onShowAddWidget={() => setShowAddWidget(!showAddWidget)} />
            </div>
          </div>

          <JobsListFilters
            filters={filters}
            setFilters={setFilters}
            jobTags={jobTags}
            setSelectedJobs={setSelectedJobs}
            investorTags={investorTags}
          />
        </StickyHeader>

        {companyDataIsLoading && (
          <div className="my-10 flex items-center justify-center">
            <Loading />
          </div>
        )}
        {!companyDataIsLoading && (
          <span>
            {/* Add widget */}
            <div ref={parent}>
              {showAddWidget && <JobsAddWidget onClose={() => setShowAddWidget(false)} />}
            </div>

            {/* Common empty state for all views */}
            {JobsListEmptyState && jobsListEmpty && (
              <JobsListEmptyState onShowAddWidget={() => setShowAddWidget(!showAddWidget)} />
            )}

            {/* If admin */}
            {adminView && listItems && (
              <JobsListAdminView selectedJobs={selectedJobs} setSelectedJobs={setSelectedJobs} />
            )}

            {/* If member or shared w company instance */}
            {(memberView || sharedListView) && listItems && <JobsListMemberView />}

            {/* Common View More for all views */}
            {hasNextPage && (
              <div className="flex items-center justify-center my-8">
                <CabalButton
                  className="w-1/5"
                  working={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                >
                  View More
                </CabalButton>
              </div>
            )}
          </span>
        )}
      </PageWrapper>
    </JobsListContext.Provider>
  )
}

export default JobsList
