import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { EditListModal } from 'components/EditList'
import ListSharingSettings from 'components/EditList/EditListAccess/ListSharingSettings'
import CTAs from 'containers/ListIndexView/Components/CTAs'
import { useJobsListContext } from 'containers/ListIndexView/TalentList/JobsList'
import { useAccessControl } from 'global/AccessControl'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

interface Props {
  onShowAddWidget: () => void
}

interface CTA_OPTION {
  label: React.ReactNode
  onClick: () => void
}

const JobsListCTAs: React.FC<Props> = ({ onShowAddWidget }) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()
  const history = useHistory()
  const { companyListData, view, setView } = useJobsListContext()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const adminView = !!isAdminOrEmployee && !!companyListData.owned

  const { companyListData: companyList, refetchCompanyList } = useJobsListContext()
  const renderEditListModal = useCallback<RenderModal>(
    (resolve) => (
      <EditListModal
        teamSlug={teamSlug}
        onHide={() => resolve(false)}
        reload={refetchCompanyList}
        listUuid={companyList?.uuid}
        onDelete={() => history.push(`/${teamSlug}/lists`)}
        ctaType={companyList?.cta_type}
        enableGatekeeper={companyList?.enable_gatekeeper}
        gatekeeper_id={companyList?.gatekeeper_id}
        dynamicGatekeeper={companyList?.dynamic_gatekeeper}
      />
    ),
    [teamSlug, companyList],
  )

  const renderShareListModal: RenderModal = (resolve) => (
    <ListSharingSettings
      teamSlug={teamSlug}
      resolve={() => resolve(false)}
      uuid={companyList?.uuid}
      reload={refetchCompanyList}
      header={`Share ${companyList?.name}`}
    />
  )

  const options = [
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-plus" />
          Add
        </Typography>
      ),
      onClick: () => onShowAddWidget(),
    },
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-pencil" />
          Edit
        </Typography>
      ),
      onClick: () => showModal(renderEditListModal, 'render_edit_list_modal'),
    },
    {
      label: (
        <Typography className="flex gap-2 items-center">
          {view === 'list' ? (
            <>
              <i className="far fa-grid" />
              Grid
            </>
          ) : (
            <>
              <i className="far fa-list" />
              List
            </>
          )}
        </Typography>
      ),
      onClick: () => setView(view === 'list' ? 'grid' : 'list'),
    },
    adminView && {
      label: (
        <Typography className="flex gap-2 items-center">
          <i className="far fa-arrow-up-from-bracket" />
          Share
        </Typography>
      ),
      onClick: () => showModal(renderShareListModal, 'render_share_list_modal'),
    },
  ]

  return <CTAs CTAOptions={options.filter(Boolean) as CTA_OPTION[]} />
}

export default JobsListCTAs
