import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import Element from '@ckeditor/ckeditor5-engine/src/view/element'
import { priorities } from '@ckeditor/ckeditor5-utils'
import EventInfo from '@ckeditor/ckeditor5-utils/src/eventinfo'

import axios from 'axios'
import { getContrast } from 'polished'
import toast from 'react-hot-toast'

import { cabalToast } from 'ui-components/Toast'

interface CabalPasteHandlerProps {
  light_bg: string
  dark_bg: string
  light_text: string
  dark_text: string
  unset_color: boolean
}

export default class CabalPasteHandler extends Plugin {
  init() {
    this.editor.editing.view.document.on(
      'clipboardInput',
      (async (evt: EventInfo, data: any, method: string, target: Element) => {
        const {
          light_bg,
          dark_bg,
          light_text,
          dark_text,
          unset_color = false,
        } = this.editor.config.get('paste_handler') as CabalPasteHandlerProps

        const dataTransfer: DataTransfer = (data as any).dataTransfer
        const content = dataTransfer.getData('text/html')

        if (!content) {
          return
        }

        const dummyEl = document.createElement('div')
        dummyEl.innerHTML = content
        let edited = false

        const imgNodeList = dummyEl.querySelectorAll('img')
        const cabalUploadBaseUrl = `${location.protocol}//${location.host}/api/uploads/`
        const imageUrls: string[] = []
        for (let i = 0; i < imgNodeList.length; i++) {
          const el = imgNodeList[i]
          if (!el.src || el.src.startsWith('data:image') || el.src.startsWith(cabalUploadBaseUrl))
            continue
          imageUrls.push(el.src)
        }

        if (imageUrls.length > 0) {
          evt.stop()
          this.lockEditor()

          const promises: Promise<void>[] = []

          for (let i = 0; i < imgNodeList.length; i++) {
            const img = imgNodeList[i]
            if (img.src) {
              promises.push(
                new Promise(async (resolve, reject) => {
                  try {
                    const src = img.src
                    const response = (
                      await axios.post('/api/uploads/image', {
                        src,
                      })
                    ).data
                    const uuid = response.uuid

                    if (uuid) {
                      dummyEl.querySelectorAll('img')[i].src = `${cabalUploadBaseUrl}${uuid}`
                      dummyEl.querySelectorAll('img')[i].alt = response.name
                    }
                  } catch (e) {
                    dummyEl.querySelectorAll('img')[i].remove()
                    cabalToast({
                      content:
                        'We could not upload the image you pasted. Please drag and drop the images.',
                      style: 'error',
                      id: 'image-download-failed,',
                    })
                  }

                  resolve()
                }),
              )
            }
          }

          await Promise.all(promises)

          edited = true
        }

        const els = dummyEl.querySelectorAll<HTMLElement>('*[style]')
        for (let i = 0; i < els.length; i++) {
          const el = els[i]
          if (!el.style.color && !el.style.backgroundColor) continue

          let lightContrast = 0
          let darkContrast = 0

          const textColor = el.style.color === 'unset' ? undefined : el.style.color
          const bgColor =
            el.style.backgroundColor === 'unset' ? undefined : el.style.backgroundColor

          try {
            if (textColor) {
              lightContrast = getContrast(textColor, light_bg)
              darkContrast = getContrast(textColor, dark_bg)
            }
          } catch (e) {}

          if (
            lightContrast &&
            darkContrast &&
            (lightContrast <= 4 || darkContrast <= 2) &&
            !el.classList.contains('cabal-primary')
          ) {
            evt.stop()
            edited = true
            if (unset_color) {
              el.style.color = 'unset'
            } else {
              el.classList.add('cabal-primary')
            }
          }

          try {
            if (bgColor) {
              lightContrast = getContrast(textColor || light_text, el.style.backgroundColor)
              darkContrast = getContrast(textColor || dark_text, el.style.backgroundColor)
            }
          } catch (e) {}

          if (lightContrast && darkContrast && (lightContrast <= 4 || darkContrast <= 2)) {
            evt.stop()
            edited = true
            el.style.backgroundColor = 'unset'
          }
        }

        if (edited) {
          this.unlockEditor()
          const dataTransfer = new DataTransfer()
          dataTransfer.setData('text/html', dummyEl.innerHTML)
          this.editor.editing.view.document.fire(
            'clipboardInput',
            {
              dataTransfer,
            },
            method,
            target,
          )
        }
      }) as any,
      { priority: priorities.get('highest') + 100 },
    )
  }

  lockEditor() {
    this.editor.enableReadOnlyMode('cabal-paste-handler')

    cabalToast({
      style: 'loading',
      content: 'Pasting content',
      id: 'pasting-images',
      duration: Infinity,
    })
  }

  unlockEditor() {
    this.editor.disableReadOnlyMode('cabal-paste-handler')
    toast.remove('pasting-images')
  }
}
