import React from 'react'

import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import TeamDigestSettings from 'containers/Settings/Company/TeamDigestSettings'
import { useAccessControl } from 'global/AccessControl'
import { Select } from 'global/Input'
import Typography from 'global/Typography'
import { useTeam } from 'store/hooks'
import SectionHeader from 'ui-components/SectionHeader'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanySlugParam, EmailTemplate, Team } from 'utils/types'

import { CardBg } from '../styles'
import EmployeeViewSettings from './EmployeeView'
import MemberViewSettings from './MemberView'

const AskSettings: React.VFC = () => {
  const { company_slug } = useParams<CompanySlugParam>()
  const { team, reloadTeam } = useTeam(company_slug)
  const { canViewMessageTemplates } = useAccessControl(company_slug)

  const updateTeamMutation = useMutation(
    ['updateTeam'],
    (val: Partial<Team>) => callApi(api.updateTeam, company_slug, { team: val }),
    {
      onSuccess: () => {
        reloadTeam()
        cabalToast({ style: 'success', content: 'Changes saved' })
      },
    },
  )

  const updateTemplateMutation = useMutation(
    ['upsertTemplate'],
    (template: EmailTemplate) => callApi(api.updateTemplate, template),
    {
      onSuccess: () => {
        getTemplatesQuery.refetch()
        cabalToast({ style: 'success', content: 'Changes saved' })
      },
    },
  )

  const getTemplatesQuery = useQuery(
    ['getTemplates', company_slug],
    () => callApi(api.getTemplates, company_slug),
    { enabled: canViewMessageTemplates },
  )

  const defaultMessageOptions = () => {
    const admins = team?.admins_and_members.filter((a) => a.team_role === 'admin')

    return (
      <div>
        <Typography fontWeight={600} component="h1" className="mb-1.5">
          Default From field for Asks
        </Typography>
        <Select
          options={
            admins?.map((adv) => ({
              label: adv.name,
              value: adv.uuid,
            })) || []
          }
          value={team?.default_from_user_id || ''}
          onChange={(v) =>
            updateTeamMutation.mutate({
              default_from_user_id: v || undefined,
            })
          }
          placeholder="Select default from "
          isLoading={updateTeamMutation.isLoading}
        />
      </div>
    )
  }

  return (
    <div className="">
      <SectionHeader hideTopBorder={true} title="Page Settings" />

      <CardBg>
        <MemberViewSettings
          updateTeamMutation={updateTeamMutation}
          updateTemplateMutation={updateTemplateMutation}
          getTemplatesQuery={getTemplatesQuery}
          companySlug={company_slug}
        />
      </CardBg>

      <SectionHeader title="Digest Settings" />
      <CardBg>
        <TeamDigestSettings />
      </CardBg>
      <SectionHeader title="Employee View" />

      <CardBg>
        <EmployeeViewSettings
          updateTemplateMutation={updateTemplateMutation}
          getTemplatesQuery={getTemplatesQuery}
        />
      </CardBg>
      <SectionHeader title="Draft on Behalf" />
      <CardBg>{defaultMessageOptions()}</CardBg>

      {/* <Setup /> */}
    </div>
  )
}

export default AskSettings
