import React, { useImperativeHandle, useRef, useState } from 'react'

import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import SendMessage, { SendMessageProps } from 'components/SendMessage'
import { SendMessageRef } from 'components/SendMessage/index.class'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import { ClassicCKEditor } from 'global/TextEditor/ckeditor/base'
import { useAdvisors, useCurrentUser } from 'store/hooks'

import useColorMode from 'utils/hooks/useColorMode'
import { EmailSnippet, MessageModel } from 'utils/types'

import { useComposeQuery } from './useComposeQuery'

interface Props {
  onHide?: (redirect?: boolean) => void
  teamSlug: string
  composerUuid: string
  onSubmit?: (m: MessageModel) => void
  sendMessageProps?: Partial<SendMessageProps>
  onAfterDelete?: () => void
}

export interface ComposerRef {
  setPresenceListContainer: (p: HTMLDivElement | null) => void
  setBody: (b: string) => void
  handleSelectSnippet: (s: EmailSnippet) => void
}

const ComposerWrap = styled.div`
  ${tw`flex flex-col flex-1 relative gap-2`}

  color: ${({ theme }) => theme.colors.primary};
  max-height: 100%;
`

export const SendMessageWrap = styled.div`
  /* ${tw`p-2 sm:p-6 rounded-lg`} */
  ${tw`p-3`}

  background-color: ${({ theme }) => theme.colors.composer_bg};
`

const START_SUB = `Getting started with Cabal 🌙: test message to myself`
const START_BODY = `
Hi {first_name},
<br>
I’m looking forward to using Cabal for:
<ul>
<li>Sending more consistent investor updates</li>
<li>Getting my investors and advisors to help me sell, recruit, and amplify my message</li>
<li>Building a modern advisor program</li>
<br>
It’s great that messages I send from Cabal come directly from my email address and are delivered to each recipient as individual messages. This way I can personalize asks and updates at scale.
<br>
I can see how my investors and advisors can help me here: getcabal.com/asks
`

const Composer = React.forwardRef<ComposerRef, Props>((props, ref) => {
  const { onHide, teamSlug, onSubmit, sendMessageProps, onAfterDelete } = props
  const {
    message,
    refetchMessage: refetch,
    gettingMessage,
    creatingMessage,
  } = useComposeQuery(props.teamSlug, props.composerUuid)
  const { advisors } = useAdvisors({ teamSlug })
  const { canMessageAdvisors } = useAccessControl(teamSlug)
  const sendMessageRef = useRef<SendMessageRef | null>(null)
  const ckEditorRef = useRef<ClassicCKEditor>()
  const { user, reloadUser } = useCurrentUser()

  const [presenceListContainer, setPresenceListContainer] = useState<HTMLDivElement>()
  const { isMobile } = useColorMode()

  useImperativeHandle(
    ref,
    () => ({
      setPresenceListContainer: (elm) => {
        elm && setPresenceListContainer(elm)
      },
      setBody: (b) => sendMessageRef.current?.setBody(b),
      handleSelectSnippet: (s) => sendMessageRef.current?.handleSelectSnippet(s),
    }),
    [],
  )

  const location = useLocation<{ getStarted: boolean }>()
  const getStarted = location.state?.getStarted

  const handleSubmit = (m: MessageModel) => {
    refetch?.()
    onSubmit?.(m)
    onHide?.(false)
  }

  const handleDraftSave = () => {
    refetch?.()
  }

  const mainContent = (
    <ComposerWrap className="composer-wrap">
      {message && (
        <SendMessage
          ref={sendMessageRef}
          ckEditorRef={ckEditorRef}
          message={message}
          companySlug={teamSlug}
          allowButtons
          allowVideo
          allowSaveAsDraft
          allowTemplates={isMobile ? false : true}
          allowSnippets={isMobile ? false : true}
          allowMessageTitle={false}
          defaultRecipients={message.recipients}
          allowCc
          title={''}
          defaultBody={getStarted ? START_BODY : message.body}
          defaultSubject={getStarted ? START_SUB : message.subject}
          onSubmit={handleSubmit}
          onSaveDraft={handleDraftSave}
          presenceListContainer={presenceListContainer}
          fromRequest={!!message.from_request}
          afterDelete={() => {
            onAfterDelete?.()
            onHide?.()
            refetch?.()
          }}
          hideModal={onHide}
          {...sendMessageProps}
          onClose={() => {
            onHide?.()
            sendMessageProps?.onClose?.()
          }}
        />
      )}
    </ComposerWrap>
  )

  const notFound = !gettingMessage && !message && !creatingMessage
  const loading =
    creatingMessage || (gettingMessage && !message) || (canMessageAdvisors && !advisors)

  if (notFound) {
    return (
      <div>
        <div className="text-center mb-4">Draft message not found</div>
        <CabalButton onClick={() => onHide?.()}>Close</CabalButton>
      </div>
    )
  }

  if (loading) return <Loading className="my-10" />

  return mainContent
})

export default Composer
