import React, { useEffect, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { sanitize } from 'dompurify'
import { useHistory, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import { SeeMore } from 'containers/CandidateProfile'
import DetailPageAboutSection from 'containers/ListIndexView/Components/DetailPageAboutSection'
import DetailPageDetailsSection from 'containers/ListIndexView/Components/DetailPageDetailsSection'
import DetailPageFilters from 'containers/ListIndexView/Components/DetailPageFilters'
import DetailPageHeader from 'containers/ListIndexView/Components/DetailPageHeader'
import DetailPagePrefrenceSection from 'containers/ListIndexView/TalentList/JobsList/JobsDetailPage.tsx/DetailPagePrefrenceSection'
import JobsEditOrAddModal from 'containers/ListIndexView/TalentList/JobsList/JobsEditOrAddModal'
import { IJob } from 'containers/ListIndexView/TalentList/JobsList/types'
import { FacilitateIntroModal } from 'containers/Portfolio/MemberView/FacilitateIntroModal'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import { CompanyListBlueprint } from 'utils/types'

import ActivityWidget from './ActivityWidget'
import Filters from './Filters'

interface Props {
  job: IJob
  companyList: CompanyListBlueprint
  refetchJob: () => void
}

const JobsDetailPage: React.FC<Props> = ({ job, companyList, refetchJob }) => {
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const { showModal } = useModal()
  const history = useHistory()
  const showIntro = useSearchParam('intro')

  const descriptionLength = job.description?.length || 0
  const [isExpanded, setIsExpanded] = useState(descriptionLength < 400 || false)
  const description = isExpanded ? job.description : `${job.description?.slice(0, 400)}`
  const [memberView, setMemberView] = useState(false)

  const [parent] = useAutoAnimate()
  const [filter, setFilter] = useState<string>('')
  const showAbout = filter === 'about' || filter === ''
  const showDetail = filter === 'detail' || filter === ''

  const { list_id } = useParams<{ list_id: string }>()

  useEffect(() => {
    if (!isAdminOrEmployee && showIntro) {
      openRequestIntroModal()
      history.replace({ search: '' })
    }
  }, [])

  const parseJobUrl = (url: string) => {
    if (!url) return ''

    if (!url.match(/^[a-zA-Z]+:\/\//)) {
      url = `https://${url}`
    }

    try {
      const parsedUrl = new URL(url)
      return parsedUrl.href
    } catch {
      // If URL parsing fails, it might be because the protocol is missing
      return url
    }
  }

  const filterOptions = [
    { label: 'All', value: '' },
    { label: 'About', value: 'about', icon: 'far fa-user' },
    { label: 'Details', value: 'detail', icon: 'far fa-list-ol' },
  ]

  const openRequestIntroModal = () => {
    showModal(
      (resolve) => (
        <FacilitateIntroModal
          requestable={job}
          companyList={companyList}
          resolve={resolve}
          gatekeeperId={companyList?.gatekeeper_id}
          facilitator={companyList?.owning_team}
          includeInitialNote={false}
          networkingOnly={false}
        />
      ),
      'facilitate-intro-modal',
    )
  }

  return (
    <PageWrapper title={job.title}>
      <DetailPageHeader
        name={job.title}
        logo={
          job.investor_company
            ? `https://logo.clearbit.com/${job.investor_company.domain}`
            : undefined
        }
        description={job.investor_company.company_name}
        cta={
          <>
            {(!isAdminOrEmployee || memberView) && (
              <CabalButton variant="primary" onClick={() => openRequestIntroModal()}>
                Request Intro
              </CabalButton>
            )}
            {isAdminOrEmployee && !memberView && (
              <div>
                <CabalButton
                  leftIcon={<i className="far fa-pen"></i>}
                  onClick={() =>
                    showModal(
                      (resolve) => (
                        <JobsEditOrAddModal
                          onHide={resolve}
                          job_uuids={job.uuid!}
                          teamSlug={teamSlug}
                          refetchListItems={() => refetchJob()}
                          listUuids={job.list_uuids}
                          currentListUuid={companyList.uuid}
                          company={job.investor_company}
                          showListDropdown={true}
                        />
                      ),
                      'edit-job-modal',
                    )
                  }
                  variant="link"
                >
                  Edit
                </CabalButton>
              </div>
            )}
          </>
        }
      />

      <DetailPageFilters
        filterOptions={filterOptions}
        filter={filter}
        onSetFilter={setFilter}
        memberView={memberView}
        setMemberView={setMemberView}
      />
      <div className="mt-10 flex gap-4">
        <div ref={parent} className="flex flex-col flex-1 gap-8 max-w-[630px]">
          {showAbout && (
            <DetailPageAboutSection
              description={
                <div>
                  <p className="leading-5 mb-2">
                    <Typography
                      component="span"
                      color="fog"
                      fontSize="12"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(
                          description?.replace(/<p>/g, '<span>').replace(/<\/p>/g, '</span>') || '',
                        ),
                      }}
                    />

                    {(job.description?.length ?? 0) > 400 && (
                      <SeeMore
                        className="ml-1 cursor-pointer"
                        color="fog"
                        fontSize="12"
                        lineHeight={1.2}
                        onClick={() => setIsExpanded(!isExpanded)}
                      >
                        {isExpanded ? '...see less' : '...see more'}
                      </SeeMore>
                    )}
                  </p>
                  {job.url && (
                    <div className="flex mt-4 w-full">
                      <CabalButton
                        variant="tertiary"
                        onClick={() => window.open(parseJobUrl(job.url), '_blank', 'noreferrer')}
                        leftIcon={<i className="far fa-arrow-up-right-from-square"></i>}
                        padding="8px 0"
                      >
                        View more
                      </CabalButton>
                    </div>
                  )}
                </div>
              }
            />
          )}

          {showDetail && (
            <DetailPageDetailsSection
              location={job.location}
              office={job.office_location}
              tags={job.tags}
            />
          )}
        </div>
        <div className="w-80 flex-shrink-0 flex flex-col gap-4">
          <ActivityWidget advisorUuid={''} />
        </div>
      </div>
    </PageWrapper>
  )
}

export default JobsDetailPage
