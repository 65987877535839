import * as React from 'react'

import cx from 'classnames'
import styled, { StyledComponentPropsWithRef } from 'styled-components'
import tw from 'twin.macro'

import Typography from 'global/Typography'

type Theme = 'dark' | 'light-dark' | 'light'
type LabelPosition = 'left' | 'top'

export interface TextAreaProps {
  labeled?: boolean
  theme?: Theme
  label?: string | React.ReactNode
  labelPosition?: LabelPosition
  smallSize?: boolean
  inlineEditor?: boolean
  isFocused?: boolean
}

const StyledTextArea = styled.textarea<{
  themeKey: Theme
  smallSize?: boolean
  isFocused?: boolean
}>`
  ${tw`py-3 text-base rounded`}
  border-color: ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.layout.main_bg_color};
  font-size: 12px;
  padding: ${({ smallSize }) => (smallSize ? '2px' : 'default')};
  line-height: 1.2;
  outline: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.rain_fog};
  }
`

const StyledInlineTextArea = styled(StyledTextArea)`
  ${tw`rounded-full`}

  resize: none;
  background-color: ${({ theme }) => theme.inline_offers.text_area};
  font-size: 16px;
  border: ${({ theme, isFocused }) => (isFocused ? '1px solid transparent' : theme.border)};
  height: ${({ isFocused }) => (isFocused ? '7em' : '46px')};
  border-radius: ${({ isFocused }) => (isFocused ? '4px' : '46px')};
  transition: height 0.15s linear, border-radius 0.3s linear, border 0.15s linear;
`

const Label = styled.label<{
  themeKey: Theme
  labelPosition: LabelPosition
}>`
  ${tw`block`}
  ${({ labelPosition }) =>
    labelPosition !== 'top' ? tw`py-2 px-3 rounded flex items-center` : null}
  color: ${({ themeKey, theme }) =>
    themeKey === 'light' ? theme.colors.black : theme.colors.white};
  background-color: ${({ themeKey, labelPosition, theme }) => {
    if (labelPosition === 'top') return 'unset'
    if (themeKey === 'dark') {
      return theme.layout.main_bg_color // gray-900
    } else if (themeKey === 'light-dark') {
      return theme.colors.cardBackground // gray-800
    } else {
      return theme.colors.white
    }
  }};
`

export const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaProps & StyledComponentPropsWithRef<'textarea'>
>(
  (
    {
      className,
      label,
      labeled = false,
      labelPosition = 'top',
      theme = 'dark',
      smallSize = false,
      inlineEditor = false,
      isFocused = false,
      value,
      onChange,
      ...extraProps
    },
    ref,
  ) => {
    if (labeled && !label) label = extraProps.placeholder
    const hasLabelOnLeft = label && labelPosition === 'left'
    const hasLabelOnTop = label && labelPosition === 'top'
    if (!value && onChange) {
      value = ''
    }

    const TextArea = inlineEditor ? StyledInlineTextArea : StyledTextArea

    const input = (
      <TextArea
        value={value}
        onChange={onChange}
        smallSize={smallSize}
        isFocused={isFocused}
        className={cx(
          'inline appearance-none border-none',
          'px-3 leading-tight focus:outline-none',
          !label && className,
          {
            'w-full': !!label,
            'py-2': !hasLabelOnLeft,
            border: !hasLabelOnLeft && !inlineEditor,
            'focus:ring-2 focus:ring-blue-300': !hasLabelOnLeft && !inlineEditor,
          },
        )}
        themeKey={theme}
        {...extraProps}
        ref={ref}
      />
    )

    if (label) {
      return (
        <Label labelPosition={labelPosition} themeKey={theme} className={className}>
          <Typography
            color="admin_label"
            fontSize="12"
            className={cx({ 'mb-2 block': hasLabelOnTop })}
          >
            {label}
          </Typography>
          {input}
        </Label>
      )
    }

    return input
  },
)

TextArea.displayName = 'TextArea'
