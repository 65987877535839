import React from 'react'

import CabalButton from 'global/CabalButton'
import { CheckBox, Label, MultiSelect } from 'global/Input'
import Typography from 'global/Typography'
import { useAdvisors } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { Team } from 'utils/types'

import { TeamSettingProps } from '.'

const Community: React.VFC<TeamSettingProps> = ({ team, reloadTeam }) => {
  const { advisors, reloadAdvisors } = useAdvisors({
    teamSlug: team?.slug || '',
  })
  const [working, setWorking] = React.useState(false)
  const [updatedTeam, setUpdatedTeam] = React.useState<Team>()
  const [selectedAdvisors, setSelectedAdvisors] = React.useState<string[]>()

  React.useEffect(() => {
    if (team) setUpdatedTeam(team)
  }, [team])

  React.useEffect(() => {
    setSelectedAdvisors(advisors?.filter((a) => a.hide_from_directory)?.map<string>((a) => a.uuid))
  }, [advisors])

  const handleSubmit = async () => {
    if (!updatedTeam) return
    setWorking(true)

    try {
      await api.updateTeam(updatedTeam.slug, {
        team: {
          enable_public_directory: updatedTeam.enable_public_directory,
        },
      })
      await reloadTeam()

      if (advisors && updatedTeam.enable_public_directory) {
        const addedAdvisors = advisors
          .filter((a) => a.hide_from_directory === false && selectedAdvisors?.includes(a.uuid))
          .map((a) => a.uuid)
        const removedAdvisors = advisors
          .filter((a) => a.hide_from_directory === true && !selectedAdvisors?.includes(a.uuid))
          .map((a) => a.uuid)

        if (addedAdvisors.length > 0)
          await api.bulkUpdateAdvisors({
            team_slug: updatedTeam.slug,
            key: 'hide_from_directory',
            advisor_uuids: addedAdvisors,
            value: true,
          })

        if (removedAdvisors.length > 0)
          await api.bulkUpdateAdvisors({
            team_slug: updatedTeam.slug,
            key: 'hide_from_directory',
            advisor_uuids: removedAdvisors,
            value: false,
          })

        await reloadAdvisors()
      }
      cabalToast({ style: 'success', content: 'Successfully updated Company' })
    } catch (e) {}

    setWorking(false)
  }

  if (!updatedTeam) return <></>

  return (
    <>
      <label>
        <CheckBox
          checked={updatedTeam.enable_public_directory}
          onChange={(e) =>
            setUpdatedTeam({ ...updatedTeam, enable_public_directory: e.currentTarget.checked })
          }
          data-testid="public-dir-checkbox"
          label="Allow members to see each other"
        />
      </label>
      {updatedTeam.enable_public_directory && advisors && (
        <div className="my-4">
          <Label>Members hidden from other members</Label>
          <MultiSelect<string>
            options={advisors.map((a) => ({
              label: a.name,
              value: a.uuid,
            }))}
            placeholder="Select members..."
            value={selectedAdvisors}
            onChange={(sa) => sa && setSelectedAdvisors(sa)}
            portal
            isClearable={false}
            data-testid="hide-member-select"
          />
        </div>
      )}
      <div className="flex justify-end">
        <CabalButton
          variant="primary"
          working={working}
          onClick={handleSubmit}
          data-testid="save-public-dir"
        >
          Save
        </CabalButton>
      </div>
    </>
  )
}

export default Community
