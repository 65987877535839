import * as React from 'react'

import * as moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import { UploadButton } from 'global/Input'
import Typography from 'global/Typography'
import { useAdvisor, useCurrentUser } from 'store/hooks'
import { DataCard, StatusProps } from 'ui-components/DataCard'
import DataCardV2 from 'ui-components/DataCardV2'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { AdvisorModel, AgreementModel, Team } from 'utils/types'
import { numWithCommas } from 'utils/ui'

import SendAgreementModal from './SendAgreementModal'

interface Props {
  advisor: AdvisorModel
  newAgreement?: AgreementModel
  team: Team
}

const Agreements: React.FC<Props> = ({ advisor, team }) => {
  const [showModalForAgreement, setShowModalForAgreement] = React.useState<
    null | undefined | AgreementModel
  >(null)

  const { canSendAgreement, canViewAgreements } = team.permissions || {}
  const history = useHistory()

  const { reloadAdvisor } = useAdvisor(team.slug, advisor.uuid)
  const { user } = useCurrentUser()

  const { data: getAgreementsData, refetch: loadAgreements } = useQuery(
    ['getAgreements', team.slug, advisor.uuid],
    () => callApi(api.getAgreements, team.slug, advisor.uuid),
  )
  const { mutate: sendReminder, isLoading: sendingReminder } = useMutation(
    ['remindAgreement', team.slug, advisor.uuid],
    (agreement_uuid: string) => callApi(api.remindAgreement, agreement_uuid),
    {
      onSuccess: () => cabalToast({ style: 'success', content: 'Reminder sent!' }),
    },
  )
  const {
    mutate: deleteAgreement,
    isLoading: deletingAgreement,
    variables: deletingAgreementVariables,
  } = useMutation(
    ['deleteAgreement', team.slug, advisor.uuid],
    (agreement_uuid: string) => callApi(api.deleteAgreement, agreement_uuid),
    {
      onSuccess: ({ success }) => {
        if (success) {
          cabalToast({ style: 'success', content: 'The agreement was deleted' })
          loadAgreements()
          reloadAdvisor()
        } else {
          cabalToast({ style: 'error', content: 'There was an error deleting the agreement' })
        }
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'There was an error deleting the agreement' })
      },
    },
  )
  const { agreements, agreement_sends: agreementSends } = getAgreementsData || {}

  const onDelete = (agreement: AgreementModel) => {
    if (
      confirm(
        `Are you sure you want to delete this ${
          agreement.status === 'not_sent' ? 'pending ' : ''
        }agreement?`,
      )
    ) {
      deleteAgreement(agreement.uuid)
    }
  }

  const uploadAgreement = (uuid: string) => {
    api.uploadAgreement(team.slug, advisor.uuid, uuid).then(() => {
      loadAgreements()
    })
  }

  if (!team || !canSendAgreement || !canViewAgreements) {
    return <div />
  }

  return (
    <div>
      {showModalForAgreement !== null && (
        <SendAgreementModal
          loadAgreements={loadAgreements}
          teamSlug={team.slug}
          onDone={() => setShowModalForAgreement(null)}
          agreement={showModalForAgreement}
          advisor={advisor}
        />
      )}

      {agreements && agreements.length > 0 && (
        <>
          <div className="flex flex-row justify-end items-center mb-2 space-x-2">
            <CabalButton
              variant="secondary"
              onClick={() =>
                history.push(`/${team.slug}/agreements/new`, {
                  advisor_uuids: [advisor.uuid],
                })
              }
              leftIcon={<i className="far fa-signature"></i>}
            >
              New Agreement
            </CabalButton>
            <UploadButton
              allowedExtensions={['pdf']}
              onUpload={uploadAgreement}
              text="Upload existing"
              triggerProps={{
                variant: 'secondary',
                leftIcon: null,
              }}
            />
          </div>
        </>
      )}

      {agreements?.map((agreement) => {
        const title = agreement.agreement_template.name || agreement.file || 'Agreement'

        // if (agreement.advisor_signed_at) {
        //   statusText += ` was signed on ${moment(agreement.advisor_signed_at * 1000).format('LL')}`
        // } else if (agreement.sent_to_advisor_at) {
        //   statusText += ` was sent on ${moment(agreement.sent_to_advisor_at * 1000).format('LL')}`
        // } else if (agreement.status === 'uploaded') {
        //   statusText += ` was uploaded on ${moment(agreement.created_at).format('LL')}`
        // } else {
        //   statusText += ` was not sent`
        // }

        const description = []

        if (agreement.sender.name) {
          description.push(`Added by ${agreement.sender.name}`)
        }

        if (agreement.equity.granted_shares) {
          description.push(`${numWithCommas(agreement.equity.granted_shares)} shares`)
        }

        if (agreement.advisor_signed_at) {
          description.push(`Signed on ${moment(agreement.advisor_signed_at * 1000).format('LL')}`)
        }

        let status: StatusProps

        if (agreement.status === 'sent') {
          status = { text: 'Sent', color: 'yellow' }
        } else if (agreement.status === 'signed') {
          status = { text: 'Signed', color: 'green' }
        } else if (agreement.status === 'not_sent') {
          status = { text: 'Not sent', color: 'red' }
        } else if (agreement.status === 'uploaded') {
          status = { text: 'Uploaded', color: 'fog' }
        } else {
          status = { text: 'Pending', color: 'fog' }
        }

        const menuOpt = [
          {
            label: agreement.status === 'sent' ? 'Cancel Agreement' : 'Delete Agreement',
            onSelect: () => onDelete(agreement),
            dataTestId: 'delete-agreement-btn',
          },
        ]

        if (agreement.status === 'sent')
          menuOpt.push({
            label: 'Send reminder',
            onSelect: () => sendReminder(agreement.uuid),
            dataTestId: 'reminder-agreement-btn',
          })

        return (
          <DataCard
            key={agreement.uuid}
            title={title}
            description={description}
            status={status}
            iconCTA={[
              {
                icon: 'fa-file-pdf',
                link: `/api/agreements/${agreement.uuid}/view_signed_document`,
                tooltipLabel: 'View pdf',
                dataTestId: 'view-pdf-btn',
              },
            ]}
            menuItems={menuOpt}
            onCardClick={() =>
              window.open(`/api/agreements/${agreement.uuid}/view_signed_document`)
            }
          />
        )
      })}

      {agreements?.length === 0 ? (
        <div>
          <EmptyState
            icon={<i className="far fa-signature-slash"></i>}
            heading="No agreements"
            body="Send advisor, contractor, or investor agreements"
          />
          <div className="flex flex-col items-center">
            <CabalButton
              variant="primary"
              onClick={() =>
                history.push(`/${team.slug}/agreements/new`, {
                  advisor_uuids: [advisor.uuid],
                })
              }
              leftIcon={<i className="far fa-signature"></i>}
              className="ml-2"
            >
              New Agreement
            </CabalButton>
            <div className="mt-4"></div>
            <UploadButton
              allowedExtensions={['pdf']}
              onUpload={uploadAgreement}
              text="Upload existing"
              triggerProps={{
                variant: 'secondary',
                leftIcon: null,
              }}
            />
          </div>
        </div>
      ) : null}
      {agreementSends?.map((a) => {
        const title = a.agreement_template.name || 'Agreement'

        return (
          <DataCard
            key={a.uuid}
            title={title}
            description={`Created by ${a.creator_user.name} on behalf of ${a.sender_user.name}`}
            status={{ text: 'Waiting to be sent', color: 'yellow' }}
            secondaryCTA={{
              text: 'Send Agreement',
              onClick: () => history.push(`/${team.slug}/agreements/new/${a.uuid}`),
              dataTestId: 'send-agreement-btn',
            }}
          />
        )
      })}
    </div>
  )
}

export default Agreements
