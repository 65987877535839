import React from 'react'

import AddWidget from 'containers/ListIndexView/Components/AddWidget'

import AddIndividualSection from './AddIndividualSection'

interface Props {
  onClose: () => void
  listUuid: string
}

const ResourcePeopleAddWidget = ({ onClose, listUuid }: Props) => {
  const tabs = [
    {
      key: 'add_individually',
      label: 'Add Individually',
      iconClass: 'far fa-plus',
      component: <AddIndividualSection setOnClose={onClose} listUuid={listUuid} />,
    }
  ]

  return <AddWidget onClose={onClose} tabs={tabs} title="Add person" />
}

export default ResourcePeopleAddWidget
