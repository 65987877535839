import React from 'react'

import {
  LEVEL_MAP,
  OPPORTUNITY_TYPE_MAP,
  ROLE_FUNCTION_MAP,
  STAGE_WITH_OPEN_TO_ALL_MAP,
} from 'containers/ListIndexView/constants'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

interface Props {
  whatYoureLookingFor: string | null | undefined
  functionPreference: string[] | null | undefined
  levelPreference: string[] | null | undefined
  stagePreference: string[] | null | undefined
  opportunityType: string[] | null | undefined
  idealCompanySize: string[] | null | undefined
}

const Preferences: React.FC<Props> = ({
  whatYoureLookingFor,
  functionPreference,
  levelPreference,
  stagePreference,
  opportunityType,
  idealCompanySize,
}) => {
  return (
    <Widget title={`Preferences`}>
      <div className="flex flex-col pb-4">
        <Typography fontSize="12" className="" color="fog">
          {whatYoureLookingFor}
        </Typography>
      </div>
      <div className="grid md:grid-cols-2 gap-4">
        {functionPreference && functionPreference.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Function
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {functionPreference?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {ROLE_FUNCTION_MAP[func]}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
        {levelPreference && levelPreference?.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Level
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {levelPreference?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {LEVEL_MAP[func.toLowerCase()]}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
        {stagePreference && stagePreference?.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Stage
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {stagePreference?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {STAGE_WITH_OPEN_TO_ALL_MAP[func.toLowerCase()]}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
        {opportunityType && opportunityType?.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Job type
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {opportunityType?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {OPPORTUNITY_TYPE_MAP[func.toLowerCase()]}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
        {idealCompanySize && idealCompanySize?.length > 0 && (
          <div className="flex flex-col">
            <Typography fontSize="14" fontWeight={600}>
              Company size
            </Typography>

            <Typography fontSize="12" className="flex flex-wrap gap-1 mt-1" color="fog">
              {idealCompanySize?.map((func, i) => (
                <Pill variant="gray" rounded key={i}>
                  {func.charAt(0).toUpperCase() + func.slice(1)}
                </Pill>
              )) || 'N/A'}
            </Typography>
          </div>
        )}
      </div>
    </Widget>
  )
}

export default Preferences
