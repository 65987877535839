import React from 'react'

import CabalButton from 'global/CabalButton'
import { Select, TextArea, TextInput } from 'global/Input'
import Typography from 'global/Typography'
import SectionHeader from 'ui-components/SectionHeader'
import { cabalToast } from 'ui-components/Toast'

import api from 'utils/api'
import { US_STATE_NAMES } from 'utils/constants/ui'
import { Team } from 'utils/types'

import { TeamSettingProps } from '.'

const Legal: React.VFC<TeamSettingProps> = ({ team, reloadTeam }) => {
  const [working, setWorking] = React.useState(false)
  const [updatedTeam, setUpdatedTeam] = React.useState<Team>()

  React.useEffect(() => {
    if (team) setUpdatedTeam(team)
  }, [team])

  const handleSubmit = () => {
    if (!updatedTeam) return
    setWorking(true)

    api
      .updateTeam(updatedTeam!.slug, {
        team: {
          legal_name: updatedTeam?.legal_name,
          state_of_incorporation: updatedTeam?.state_of_incorporation,
          address: updatedTeam?.address,
        },
      })
      .then(async () => {
        await reloadTeam()
        cabalToast({ style: 'success', content: 'Successfully updated Company' })
      })
      .finally(() => setWorking(false))
  }

  if (!updatedTeam) return <></>

  return (
    <>
      <div className="">
        <TextInput
          value={updatedTeam.legal_name}
          placeholder="Legal Name"
          color="admin_label"
          labeled
          className="w-full mb-4"
          data-testid="legal-name"
          onChange={(e) => setUpdatedTeam({ ...updatedTeam, legal_name: e.target.value })}
        />
        <div className="mb-base">
          <Typography color="admin_label" fontSize="12" className="mb-2 block">
            State of Incorporation
          </Typography>
          <Select
            value={updatedTeam.state_of_incorporation}
            placeholder={`State of Incorporation`}
            className="w-full"
            onChange={(v) => v && setUpdatedTeam({ ...updatedTeam, state_of_incorporation: v })}
            options={US_STATE_NAMES.map((s) => ({
              value: s,
            }))}
          />
        </div>
        <TextArea
          value={updatedTeam.address}
          placeholder="Address"
          rows={4}
          labeled
          className="w-full mb-4"
          onChange={(e) => setUpdatedTeam({ ...updatedTeam, address: e.target.value })}
        />
        <div className="flex justify-end">
          <CabalButton
            variant="primary"
            working={working}
            onClick={handleSubmit}
            data-testid="save-legal"
          >
            Save
          </CabalButton>
        </div>
      </div>
    </>
  )
}

export default Legal
