import React from 'react'

import { useParams } from 'react-router-dom'

import { Notes } from 'containers/RequestCenter/IntroRequestDetails/Notes'
import { Timeline } from 'containers/RequestCenter/IntroRequestDetails/Timeline'
import { useFetchIntroRequestDetails } from 'containers/RequestCenter/queries/FetchIntroRequestDetails'
// import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'

import Details from './Details'
import Header from './Header'

export function Index() {
  const { uuid } = useParams<{ uuid: string }>()
  const { refetch, data, isLoading, isError } = useFetchIntroRequestDetails({
    uuid,
    enabled: true,
  })

  if (isError) {
    return (
      <div className="flex justify-center items-center mt-44 w-full">
        <Typography fontWeight={800} fontSize="24">
          You are not authorized to view this page
        </Typography>
      </div>
    )
  }

  if (!data || isLoading) {
    return <Loading />
  }

  const { intro_request_details: introRequestDetails } = data.data

  return (
    <PageWrapper title={'Intro detail page'}>
      <Header introRequestDetails={introRequestDetails} refetch={refetch} />

      <div className="mt-10 flex gap-4">
        <div className="flex flex-col flex-1 gap-8 max-w-[630px]">
          <Timeline introRequestDetails={introRequestDetails} />
        </div>
        <div className="w-80 flex-shrink-0 flex flex-col gap-4">
          <Details introRequestDetails={introRequestDetails} />
          <Notes introRequestDetails={introRequestDetails} refetch={refetch} />
        </div>
      </div>
    </PageWrapper>
  )
}
