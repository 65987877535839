import React, { useState } from 'react'

import { motion } from 'framer-motion'
import styled from 'styled-components'

import AddTalentIndividually from 'containers/AddCandidate/AddTalentIndividually'
import SendInvite from 'containers/AddCandidate/SendInvite'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import Widget from 'ui-components/Widget'

import { cn } from 'utils/styles'

const TabWrapper = styled.div`
  border-bottom: ${({ theme }) => theme.border};
`

export function WidgetTab(props: {
  active?: boolean
  children: React.ReactNode
  onClick: () => void
}) {
  const { active, children, onClick } = props
  return (
    <div className={cn('cursor-pointer', { selected: active })} onClick={onClick}>
      <Typography
        fontSize="12"
        className="mb-1"
        color={active ? 'purple' : 'fog'}
        fontWeight={active ? 600 : 400}
        component="p"
      >
        {children}
      </Typography>
      {props.active && <motion.div className="border-b-2 border-[#8A97FF]" layoutId="underline" />}
    </div>
  )
}

interface Props {
  teamSlug: string
  listUuid: string
  reload: () => void
  onClose: () => void
  isTalent?: boolean
}

const AddCandidate: React.FC<Props> = ({ teamSlug, listUuid, reload, onClose, isTalent }) => {
  const [tab, setTab] = useState<'add_individually' | 'send_invite'>('add_individually')

  return (
    <Widget
      title="Add people"
      cta={
        onClose && (
          <CabalButton
            variant="tertiary"
            onClick={(e) => {
              e.preventDefault()
              onClose()
            }}
            padding={'0'}
            leftIcon={<i className="far fa-times" />}
          ></CabalButton>
        )
      }
    >
      {isTalent && (
        <TabWrapper className="flex gap-4 mt-6">
          <WidgetTab active={tab === 'add_individually'} onClick={() => setTab('add_individually')}>
            <i className="far fa-plus mr-1 fa-sm" /> Add Individually
          </WidgetTab>
          <WidgetTab active={tab === 'send_invite'} onClick={() => setTab('send_invite')}>
            <i className="far fa-link mr-1 fa-sm" /> Send Invite
          </WidgetTab>
        </TabWrapper>
      )}

      <div className="mt-4">
        {tab === 'add_individually' && (
          <AddTalentIndividually
            teamSlug={teamSlug}
            listUuid={listUuid}
            onClose={onClose}
            reload={reload}
            isTalentList={isTalent}
          />
        )}
        {tab === 'send_invite' && (
          <SendInvite
            isTalentList={isTalent}
            listUuid={listUuid}
            teamSlug={teamSlug}
            onClose={onClose}
          />
        )}
      </div>
    </Widget>
  )
}

export default AddCandidate
