import React, { useMemo, useState } from 'react'

import { theme } from 'twin.macro'

import { useCompanySearch } from 'components/Winsite/queries/CompanySearch'
import MemberConnections from 'containers/Home/YourConnections/MemberConnections'
import PortfolioCompanyEditOrAddModal from 'containers/ListIndexView/PortfolioList/PortfolioCompanyEditOrAddModal'
import { CompanySelectProps } from 'containers/ListIndexView/PortfolioList/types'
import { useSalesListContext } from 'containers/ListIndexView/SalesList'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { SearchInput } from 'global/Input'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'

import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

import AddPersonModal from './AddPersonModal'

interface Props {
  setOnClose: () => void
}

const AddIndividualSection: React.FC<Props> = ({ setOnClose }) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)
  const { showModal } = useModal()
  const teamSlug = useTeamSlug()
  const logo_url = 'https://logo.clearbit.com/'

  const { isLoading, data: companies } = useCompanySearch(debouncedQuery, true)
  const results: CompanySelectProps[] = useMemo(() => companies?.data, [companies])
  const { companyListData: companyList } = useSalesListContext()

  // Need a similar api for sales
  // const { mutate: addCompany } = useMutation(
  //   (company: CompanySelectProps) => callApi(api.portfolioAddCompany, company, companyList.uuid),
  //   {
  //     onSuccess: () => {
  //       sleep(1000).then(() => {
  //         refetchPortfolioCompaniesList()
  //         setOnClose()
  //         cabalToast({
  //           content: 'Company added successfully!',
  //           style: 'success',
  //         })
  //       })
  //     },
  //   },
  // )

  return (
    <div className="mt-4">
      <SearchInput
        onChange={(c) => setQuery(c.target.value)}
        placeholder={`Search for companies`}
        value={query}
        autoFocus
      />

      {debouncedQuery !== '' && (
        <>
          <div className="my-4 px-3">
            <CabalButton
              variant="link"
              onClick={() => {
                showModal(
                  (resolve) => <AddPersonModal onHide={resolve} name={debouncedQuery} />,
                  'add-person-modal',
                )
                setOnClose()
              }}
              leftIcon={<i className="far fa-plus fa-xs" />}
              padding="0"
              size="large"
            >
              <span>{`Add company "${debouncedQuery}"`}</span>
            </CabalButton>
          </div>
          {isLoading && (
            <div className="flex mt-4 w-full justify-center">
              <Loading />
            </div>
          )}
          <MemberConnections
            debouncedQuery={[debouncedQuery]}
            personRowCta={'Add'}
            onPersonRowCtaClick={(p) => {
              console.log('Add clicked :', p)
            }}
            searchAll
          />
        </>
      )}
    </div>
  )
}

export default AddIndividualSection
