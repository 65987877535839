import React from 'react'

import styled from 'styled-components'
import tw from 'twin.macro'

import ActivityWidget from 'containers/CompanyHomeV2/ActivityWidget'
import Faqs from 'containers/CompanyHomeV2/Faqs'
import HomeCharts from 'containers/CompanyHomeV2/HomeCharts'
// import ForYouWidget from 'containers/CompanyHomeV2/ForYouWidget'
// import GettingStartedWidget from 'containers/CompanyHomeV2/GettingStartedWidget'
import InviteMembers from 'containers/CompanyHomeV2/InviteMembers'
import Metrics from 'containers/CompanyHomeV2/Metrics'
import ConnectionsWidget from 'containers/CompanyPage/ConnectionsWidget'
import { TextArea } from 'global/Input'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'

import { useSlug } from 'utils/hooks/useSlug'

export const StyledTextArea = styled(TextArea)`
  ${tw`w-full`}
`

const CompanyHomeV2 = () => {
  const { isLoading: slugLoading, teamSlug } = useSlug()
  const { team, isLoading: teamLoading } = useTeam(teamSlug)
  const [connectionsShared, setConnectionsShared] = React.useState(false)
  const { user } = useCurrentUser()
  // const showGettingStartedWidget = team?.features?.show_getting_started_widget

  const isLoading = slugLoading || teamLoading

  if (isLoading) {
    return (
      <div className="my-10">
        <Loading />
      </div>
    )
  }

  const getGreeting = () => {
    let msg = ''

    const today = new Date()
    const curHr = today.getHours()

    if (curHr < 12) {
      msg += `Good morning, ${user.first_name} `
    } else if (curHr < 18) {
      msg += `Good afternoon, ${user.first_name} `
    } else {
      msg += `Good evening, ${user.first_name} `
    }

    return msg
  }

  return (
    <PageWrapper title={`${team?.name} home`}>
      <div className="mt-8">
        <Typography fontSize="28" fontWeight={600} component="p" lineHeight={1}>
          {getGreeting()}
        </Typography>
      </div>
      <div className="mt-6">
        <HomeCharts />
      </div>

      <div className="mt-8 flex justify-between">
        <div className="flex flex-col flex-1 gap-8 max-w-[630px]">
          {/* {showGettingStartedWidget && (
            <GettingStartedWidget teamSlug={teamSlug!} connectionsShared={connectionsShared} />
          )} */}
          {/* <ForYouWidget /> */}
          <Metrics team={team!} />
          <ActivityWidget />
        </div>
        <div className="w-80 flex-shrink-0 flex gap-8 flex-col">
          <ConnectionsWidget location="dashboard" setConnectionsShared={setConnectionsShared} />
          {/* {connectionsShared && <InviteMembers />} */}
          <Faqs teamSlug={teamSlug!} />
        </div>
      </div>
    </PageWrapper>
  )
}

export default CompanyHomeV2
