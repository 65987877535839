import React, { useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import SalesCompanyAdminView from 'containers/ListIndexView/SalesList/SalesCompanyList/SalesCompanyAdminView'
import SalesListBulkActions from 'containers/ListIndexView/SalesList/SalesListBulkActions'
import SalesListCTAs from 'containers/ListIndexView/SalesList/SalesListCTAs'
import SalesListEmptyState from 'containers/ListIndexView/SalesList/SalesListEmptyState'
import SalesListFilters from 'containers/ListIndexView/SalesList/SalesListFilters'
import SalesListHeader from 'containers/ListIndexView/SalesList/SalesListHeader'
import SalesListMemberView from 'containers/ListIndexView/SalesList/SalesListMemberView'
import SalesPeopleAddWidget from 'containers/ListIndexView/SalesList/SalesPeopleList/SalesPeopleAddWidget'
import SalesPeopleAdminView from 'containers/ListIndexView/SalesList/SalesPeopleList/SalesPeopleAdminView'
import SalesPeopleMemberView from 'containers/ListIndexView/SalesList/SalesPeopleList/SalesPeopleMemberView'
import { ISalesPeople, SalesFilterTypes } from 'containers/ListIndexView/SalesList/type'
import { StickyHeader } from 'containers/ListIndexView/style'
import Loading from 'global/Loading'

interface Props {
  listLoading: boolean
  memberView: boolean
  adminView: boolean
  salesListEmpty: boolean
  sharedListView: boolean
  filters: SalesFilterTypes
  setFilters: (filters: SalesFilterTypes) => void
}

const SalesPeopleList: React.FC<Props> = ({
  listLoading,
  memberView,
  adminView,
  salesListEmpty,
  sharedListView,
  filters,
  setFilters,
}) => {
  const [showAddWidget, setShowAddWidget] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState<ISalesPeople[]>([])
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  return (
    <div>
      This is the sales people list
      <StickyHeader>
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center">
          <SalesListHeader />

          <div className="flex gap-6">
            <SalesListBulkActions selectedItems={selectedPeople} />
            <SalesListCTAs onShowAddWidget={() => setShowAddWidget(!showAddWidget)} />
          </div>
        </div>

        <SalesListFilters
          filters={filters}
          setFilters={setFilters}
          ownedCompanyTags={[]}
          setSelectedItems={setSelectedPeople}
        />
      </StickyHeader>
      {listLoading && (
        <div className="my-10 flex items-center justify-center">
          <Loading />
        </div>
      )}
      {!listLoading && (
        <>
          {/* Need new add widget */}
          <div ref={parent}>
            {showAddWidget && <SalesPeopleAddWidget onClose={() => setShowAddWidget(false)} />}
          </div>

          {/* Empty state */}
          {salesListEmpty && <SalesListEmptyState onShowAddWidget={() => {}} />}

          {/* Admin View : need new Admin View */}
          {adminView && !salesListEmpty && (
            <SalesPeopleAdminView
              selectedPeople={selectedPeople}
              setSelectedPeople={setSelectedPeople}
            />
          )}

          {/* Member View  : maybe need new member view? */}
          {(memberView || sharedListView) && !salesListEmpty && <SalesPeopleMemberView />}
        </>
      )}
    </div>
  )
}

export default SalesPeopleList
