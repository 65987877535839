import React, { useMemo } from 'react'

import { useInfiniteQuery } from 'react-query'

import MemberRow from 'components/CompanyHome/ConnectionSection/MemberRow'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import api, { callApi } from 'utils/api'

interface Props {
  debouncedQuery: string[]
  isHome?: boolean
  searchAll?: boolean
  isUserDashboard?: boolean
  personRowCta?: string
  onPersonRowCtaClick?: (person: { uuid: string }) => void
  isLoading?: boolean
}

const MemberConnections: React.FC<Props> = ({
  debouncedQuery,
  isHome,
  searchAll,
  isUserDashboard,
  personRowCta,
  onPersonRowCtaClick,
  isLoading: isLoadingProp,
}) => {
  const teamSlug = useTeamSlug()
  const highlightWords = useMemo(
    () =>
      debouncedQuery
        .flatMap((q) => q.split(' '))
        .map((q) => q.toLowerCase())
        .map((q) => q.trim())
        .filter((q) => q.length > 0),
    [debouncedQuery],
  )

  const {
    data: rawData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['connections', debouncedQuery, teamSlug, isHome],
    ({ pageParam: page }) =>
      callApi(api.searchConnections, teamSlug, {
        query: debouncedQuery,
        scope: isHome || searchAll ? 'all' : 'mine',
        page,
        per_page: isHome ? 3 : 20,
      }),
    {
      keepPreviousData: true,
      cacheTime: Infinity,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
    },
  )

  const people =
    rawData?.pages?.flatMap((page) => {
      return page?.people?.map((person) => {
        return {
          ...person,
          ssid: page.ssid,
        }
      })
    }) || []

  return (
    <div>
      {isLoading && (
        <div className="flex flex-col items-center justify-center my-20">
          <Loading />
        </div>
      )}

      {!isLoading &&
        people.map(({ person, vote, connection, ssid }) => (
          <MemberRow
            vote={vote || 0}
            hideOfferButton={isUserDashboard}
            teamSlug={teamSlug}
            key={person.uuid}
            ssid={ssid}
            connection={connection}
            isLoading={isLoadingProp}
            person={person}
            highlightWords={highlightWords}
            personRowCta={personRowCta}
            onPersonRowCtaClick={onPersonRowCtaClick}
          />
        ))}

      {hasNextPage && (
        <div className="flex justify-center">
          <CabalButton
            variant="link"
            onClick={() => fetchNextPage()}
            working={isFetchingNextPage}
            rightIcon={<i className="fal fa-chevron-down" />}
          >
            Load more
          </CabalButton>
        </div>
      )}

      {!isLoading && people?.length === 0 && debouncedQuery[0] !== '' && (
        <div className="flex flex-col items-center justify-center my-20">
          <Typography color="fog" fontSize="24">
            <i className="fal fa-search" />
          </Typography>
          <Typography fontSize="14" fontWeight={600}>
            No results found for {`"${debouncedQuery}"`}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default MemberConnections
