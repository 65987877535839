import React from 'react'

import { UseQueryResult } from 'react-query'

import { Select } from 'global/Input'
import Typography from 'global/Typography'

import { EmailTemplate, MutationFunc } from 'utils/types'

interface Props {
  updateTemplateMutation: MutationFunc<EmailTemplate>
  getTemplatesQuery: UseQueryResult<{
    templates: EmailTemplate[]
  }>
}

const EmployeeViewSettings: React.VFC<Props> = ({ updateTemplateMutation, getTemplatesQuery }) => {
  const templates = getTemplatesQuery.data?.templates

  const defaultTemplate = templates?.find((t) => t.default_for === 'employee_intro_template')

  const handleTemplateSelection = (v: string) => {
    const selected: EmailTemplate | undefined = templates?.find((t) => t.uuid === v)
    if (selected) {
      selected.public_to_team = true
      selected.default_for = 'employee_intro_template'
      updateTemplateMutation.mutate(selected)
    }
  }

  return (
    <div>
      <Typography fontWeight={600} component="h1" className="mb-1.5">
        Default Message template
      </Typography>
      <Select
        options={
          templates?.map((t) => ({
            label: t.name,
            value: t.uuid,
          })) || []
        }
        value={defaultTemplate?.uuid}
        onChange={(v) => v && handleTemplateSelection(v)}
        placeholder="Select default template "
        isLoading={updateTemplateMutation.isLoading}
        className="mb-4"
      />
    </div>
  )
}

export default EmployeeViewSettings
