import React from 'react'

import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import MessageParsed from 'components/MessageParsed'
import CandidateProfile from 'containers/CandidateProfile'
import PostWidget from 'containers/ItemDetailPage/PostWidget'
import DiscountDetailPage from 'containers/ListIndexView/ResourcesList/ResourceDiscountList/DiscountDetailPage'
import JobsDetailPage from 'containers/ListIndexView/TalentList/JobsList/JobsDetailPage.tsx'
import PortfolioProfile from 'containers/PortfolioProfile'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import { useTeam, useTeamSlug } from 'store/hooks'
import Widget from 'ui-components/Widget'

import api, { callApi } from 'utils/api'
import { CompaniesFiltersType } from 'utils/types'

import CommentsAndActivity from './CommentsAndActivity'
import Connections from './Connections'
import PageHeader, { PageHeaderDataProps } from './PageHeader'

export const WidthWrapper = styled.div`
  max-width: 660px;
  flex-grow: 1;
`

const ViewItem = () => {
  const teamSlug = useTeamSlug()
  const { list_id, item_id } = useParams<{ item_id: string }>()
  const [filters, setFilters] = React.useState<CompaniesFiltersType>()
  const { team } = useTeam(teamSlug)
  const params = new URLSearchParams(window.location.search)
  const listUuid = params.get('list_uuid') || null

  const {
    data: itemData,
    isFetched,
    error: itemError,
    refetch: refetchItem,
  } = useQuery(
    ['getItem', item_id, list_id],
    () => callApi(api.getCompanyItem, teamSlug, item_id, false, list_id),
    {
      enabled: !!item_id,
    },
  )

  const blockItem = {
    item_id,
    company: {
      id: item_id,
      uuid: item_id,
    },
  }

  const item = itemData?.item
  const itemHome = itemData?.item_home
  let companies: any[] = []
  let people: any[] = []

  if (itemHome) {
    Object.values(itemHome.lists).forEach((list: any) => {
      companies = companies.concat(list.items_with_matches)
    })
    const company_people_ids = uniq(companies.flatMap((company: any) => company.global_people_ids))
    people = company_people_ids.map((id: string) => itemHome.people[id])
  }

  if (!isFetched) {
    return <Loading className="my-20" />
  }

  const titleBlock: PageHeaderDataProps = {
    title: item?.name,
    avatar_url: item?.logo_url || item?.candidate_profile?.global_person?.image_url || '',
    description_parts: compact([
      !!team && {
        label: team.name,
        type: 'text',
        id: '7',
      },
      !!itemData?.list_name && {
        label: itemData.list_name,
        type: 'text',
        id: '6',
      },
      !!item?.stage_name?.length && {
        id: '3',
        type: 'text',
        label: item.stage_name?.join(', '),
      },
      !!item?.domain && { label: item.domain, type: 'text', id: '4' },
      !!item?.candidate_profile?.headline && {
        label: item.candidate_profile.headline,
        type: 'text',
        id: '5',
      },
    ]),
  }

  if (itemError?.response?.status == 403) {
    return (
      <EmptyState
        icon={<i className="fa fa-lock" />}
        heading="You don't have access to this item"
      />
    )
  } else if (itemError?.response?.status == 404 || !item) {
    return <EmptyState icon={<i className="fa fa-lock" />} heading="This item was not found" />
  }

  if (
    item.company_list?.list_type === 'discounts' &&
    item.company_list?.list_category === 'resources'
  ) {
    return (
      <DiscountDetailPage
        listUuid={item.company_list.uuid}
        discount={{
          discount_uuid: item.discount_uuid,
          uuid: item.uuid,
          name: item.name,
          description: item.description,
          tags: item.tags,
          cta_url: item.cta_url,
          cta_text: item.cta_text,
          logo_url: item.logo_url,
        }}
      />
    )
  }

  const getAttachableType = () => {
    if (item.item_type == 'mail' || item.item_type == 'GlobalPerson') {
      return 'CompanyListItem'
    } else {
      return 'Company'
    }
  }

  const showJob =
    item?.company_list?.list_category == 'talent' && item?.company_list?.list_type == 'jobs'

  return (
    <>
      {!!item.investor_company_uuids && (
        <PortfolioProfile
          investor_company_uuids={item.investor_company_uuids}
          blockItem={blockItem}
          filters={filters}
          setFilters={setFilters}
        />
      )}
      {item.item_type == 'GlobalPerson' && <CandidateProfile uuid={item.candidate_profile?.uuid} />}
      {showJob && (
        <JobsDetailPage job={item.job} companyList={item.company_list} refetchJob={refetchItem} />
      )}
      {item.item_type != 'GlobalPerson' && !item.investor_company_uuids && !showJob && (
        <PageWrapper title={item.name} crumbs={[]}>
          <div className="mx-2">
            <PageHeader
              filters={filters}
              data={titleBlock}
              actions={{
                comments: false,
                share: false,
              }}
            />
          </div>

          <div className="flex mt-3">
            <>
              {item.item_type == 'mail' && (
                <Widget className="p-4 pt-0">
                  <MessageParsed body={item.message_body} className="p-2" />
                </Widget>
              )}
              {(item.item_type == 'company' || item.item_type == 'persona') && (
                <WidthWrapper className="">
                  <div className="p-2 pt-0">
                    <Connections
                      teamSlug={teamSlug}
                      filters={filters}
                      blockItem={blockItem}
                      setFilters={setFilters}
                      companyListName={item.name}
                      listUuid={listUuid}
                    />
                  </div>
                </WidthWrapper>
              )}
              {item.item_type == 'post' && <PostWidget teamSlug={teamSlug} item={item} />}
              <div className="p-2 pt-0">
                <div className="w-80 flex-shrink-0">
                  {item.item_type != 'post' && (
                    <CommentsAndActivity
                      teamSlug={teamSlug}
                      attachableId={item_id}
                      attachableType={getAttachableType()}
                    />
                  )}
                </div>
              </div>
            </>
          </div>
        </PageWrapper>
      )}
    </>
  )
}

export default ViewItem
