import React, { useRef, useState } from 'react'

import { DialogContent, DialogOverlay } from '@reach/dialog'
import '@reach/dialog/styles.css'

import styled from 'styled-components/macro'
import tw from 'twin.macro'

import { SendMessageProps } from 'components/SendMessage'

import {
  ButtonModel,
  EmailSnippet,
  MessageModel,
  MessagePersonalizationBlueprint,
} from 'utils/types'

import Composer, { ComposerRef } from '../Composer'
import ComposerSidebar from '../Sidebar'
import { useComposeQuery } from '../useComposeQuery'
import EditPersonalization from './EditPersonalization'

const StyledDialogContent = styled(DialogContent)`
  -webkit-font-smoothing: antialiased;
  ${tw`rounded-lg relative p-0 flex overflow-auto`}

  /* background-color: ${({ theme }) => theme.colors.shadow}; */
  background-color: ${({ theme }) => theme.layout.main_bg_color};

  color: ${({ theme }) => theme.colors.primary};
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  margin: 50px;

  @media (max-width: 640px) {
    width: calc(100vw - 12px);
    height: calc(100vh - 12px);

    margin: 6.25px;
  }
`

const StyledDialogOverlay = styled(DialogOverlay)`
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
`

const ComposerWrapper = styled.div<{ hidden: boolean }>`
  ${tw`flex-col flex-1 relative p-3`}
  ${({ hidden }) => (hidden ? tw`hidden` : tw`flex`)}

  width: 100%;

  background-color: ${({ theme }) => theme.colors.composer_bg};
`

const SidebarWrapper = styled.div`
  ${tw`h-full relative w-[350px] overflow-y-scroll hidden lg:block`}
`

interface Props {
  composerUuid: string
  teamSlug: string
  onHide: (redirect?: boolean) => void
  onSubmit?: (message: MessageModel) => void
  buttons?: ButtonModel[]
  sendMessageProps?: Partial<SendMessageProps>
  onReconnect: () => void
  onAfterDelete?: () => void
}

const ComposeModal: React.VFC<Props> = (props) => {
  const composerRef = useRef<ComposerRef | null>(null)
  const { message } = useComposeQuery(props.teamSlug, props.composerUuid)

  const [currentPersonalization, setCurrentPersonalization] =
    useState<MessagePersonalizationBlueprint>()

  const handleSelectSnippet = React.useCallback(
    (snippet: EmailSnippet) => {
      composerRef.current?.handleSelectSnippet?.(snippet)
    },
    [composerRef],
  )

  return (
    <StyledDialogOverlay
      className="DialogOverlay"
      isOpen={true}
      dangerouslyBypassFocusLock={true}
      dangerouslyBypassScrollLock={true}
      data-testid="composer-modal"
    >
      <StyledDialogContent className="compose-modal">
        {!!currentPersonalization && (
          <EditPersonalization
            teamSlug={props.teamSlug}
            goBackToComposer={() => setCurrentPersonalization(undefined)}
            message={message}
            personalization={currentPersonalization}
          />
        )}
        <ComposerWrapper hidden={!!currentPersonalization}>
          <Composer
            composerUuid={props.composerUuid}
            ref={composerRef}
            teamSlug={props.teamSlug}
            onSubmit={props.onSubmit}
            onHide={props.onHide}
            onAfterDelete={props.onAfterDelete}
            sendMessageProps={{
              allowScheduledSend: true,
              ...props.sendMessageProps,
            }}
          />
        </ComposerWrapper>
        <SidebarWrapper className="">
          {message && (
            <ComposerSidebar
              composerUuid={props.composerUuid}
              teamSlug={props.teamSlug}
              setPresenceListContainer={(elm) => composerRef.current?.setPresenceListContainer(elm)}
              setBody={(b) => composerRef.current?.setBody(b)}
              onHide={props.onHide}
              handleSelectSnippet={handleSelectSnippet}
              currentPersonalization={currentPersonalization}
              setCurrentPersonalization={setCurrentPersonalization}
            />
          )}
        </SidebarWrapper>
      </StyledDialogContent>
    </StyledDialogOverlay>
  )
}

export default ComposeModal
