import styled from 'styled-components'
import tw from 'twin.macro'

export const TopNavWrapper = styled.div`
  ${tw`flex justify-between items-center py-2 z-50`}
  grid-area: topnav;

  /* position: sticky;
  top: 0px; */

  /* height: 56px; */
  border-bottom: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
`

export const AppWrapper = styled.div<{
  showTeamNav: boolean
  showPaywall: boolean
}>`
  display: grid;
  overflow: hidden;
  height: var(--app-height);
  width: 100vw;
  grid-template-columns: 1fr;

  ${({ showTeamNav, showPaywall }) =>
    showPaywall
      ? `
    grid-template-rows: 50px 42px 69px auto;
    grid-template-areas:
    'topnav'
    'paywall'
    'subnav'
    'body';
    `
      : showTeamNav
      ? `
    grid-template-rows: 50px 69px auto;
    grid-template-areas:
    'topnav'
    'subnav'
    'body';
    `
      : `
    grid-template-rows: 50px auto;
    grid-template-areas:
    'topnav'
    'body';
    `}
`

export const OverflowWrapper = styled.div`
  ${tw`flex flex-col items-center w-screen`}
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
  overflow-x: hidden;
  overflow-y: auto;
  grid-area: body;
`

export const MainContentWrap = styled.div<{
  restrictWidth: boolean
  hideBackground: boolean
}>`
  ${tw`w-full`}
  flex-grow: 1;
  /* background-color: ${({ theme, hideBackground }) =>
    !hideBackground && theme.layout.main_content_bg_color}; */
  ${({ restrictWidth, theme }) => restrictWidth && `width: ${theme.breakpoints.restricted}`};

  ${({ restrictWidth, theme }) =>
    !restrictWidth &&
    `overflow-y: hidden;
    max-height: calc(100vh - 50px);`};

  @media (max-width: 990px) {
    width: 100vw !important;
  }
`

export const LoginContentWrap = styled.div`
  ${tw`w-screen flex h-full items-center justify-center`}
  background: radial-gradient(46.7% 76.86% at 50% 57.14%,  ${({ theme }) =>
    `${theme.layout.bg_gradient_start} 0%, ${theme.layout.bg_gradient_end} 100%`});
`

export const FooterWrapper = styled.div`
  &:hover {
    color: ${({ theme }) => theme.side_nav.active_item_text_color};
  }

  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
`
