import React from 'react'

import styled from 'styled-components'

import DropDownMenu from 'global/DropDownMenu'
import Typography from 'global/Typography'
import { UpdatedDropDownProps } from 'ui-components/DataCardV2'

interface Props {
  avatar?: React.ReactNode
  dropDownMenu?: UpdatedDropDownProps
  topRightData?: React.ReactNode
}

const DefaultAvatar = styled(Typography)`
  background-color: ${({ theme }) => theme.colors.mist};
`

const CardHeader: React.FC<Props> = ({ avatar, dropDownMenu, topRightData }) => {
  return (
    <div className="flex justify-between items-start w-full mb-5">
      {avatar && (
        <div className="">
          <DefaultAvatar
            color="fog"
            className="flex items-center justify-center rounded-full w-[72px] h-[72px]"
          >
            {avatar}
          </DefaultAvatar>
        </div>
      )}

      <div onClick={(e) => e.stopPropagation()}>
        {dropDownMenu && (
          <DropDownMenu
            menuItems={dropDownMenu.menuItems}
            trigger={
              dropDownMenu.trigger ?? (
                <div className="px-1">
                  <Typography color={'fog'} data-testid="datacard-dropdown-menu-trigger">
                    <i className="far fa-ellipsis-v"></i>
                  </Typography>
                </div>
              )
            }
          />
        )}
        {topRightData && <div>{topRightData}</div>}
      </div>
    </div>
  )
}

export default CardHeader
