import { FileLoader } from '@ckeditor/ckeditor5-upload/src/filerepository'

import axios from 'axios'
import S3Upload from 'react-s3-uploader/s3upload'

import api from 'utils/api'

export class ImageUploadAdapter {
  loader: FileLoader

  constructor(loader: FileLoader) {
    this.loader = loader
  }

  async _onUploadFinish(data: { signedUrl: string }, file: File) {
    const url = data.signedUrl

    const {
      data: { uuid },
    } = await axios.post(`/api/uploads`, {
      url,
      name: file.name,
      size: file.size,
      mime_type: file.type,
    })

    return `${location.protocol}//${location.host}/api/uploads/${uuid}?cached=1`
  }

  async upload() {
    return this.loader.file.then(
      (file: File | null) =>
        new Promise<{ default: string }>((resolve, reject) => {
          if (!file) throw 'no file to upload'

          if (window.QA) {
            api.createQaUpload(file).then(({ data }) => {
              resolve({ default: `${location.protocol}//${location.host}${data.api_url}?cached=1` })
            })
            return
          }

          new S3Upload({
            files: [file],
            signingUrl: `/api/uploads/new`,
            signingUrlMethod: 'GET',
            contentDisposition: 'attachment',
            onProgress: (percent) => {
              this.loader.uploadTotal = 100
              this.loader.uploaded = percent < 90 ? percent : 90
            },
            onError: (err) => {
              reject(err)
            },
            onFinishS3Put: async (data: { signedUrl: string }) => {
              try {
                resolve({
                  default: await this._onUploadFinish(data, file),
                })
              } catch (e) {
                reject(e)
              }
            },
            uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
            server: '',
            scrubFilename: (filename) => {
              return filename.replace(/[^\w\d_\-\.]+/gi, '')
            },
          })
        }),
    )
  }

  abort() {
    return
  }
}
