import React, { useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import { CreateSalesListModal } from 'containers/IntroRequestModal/components/Modals/Sales/CreateSalesListModal'
import { ReviewSalesListHandler } from 'containers/IntroRequestModal/components/Modals/Sales/ReviewSalesListHandler'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Modal, { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'

import api, { callApi } from 'utils/api'
import { CompanyBlueprint, CompanyListBlueprint, PersonBlueprint } from 'utils/types'

import { IRCompanyListObject, IRequestor, ISalesTarget } from '../../../types'
import { IntroConnection } from './types/IntroConnection'

interface IProps {
  teamSlug: string
  requestable: CompanyBlueprint | PersonBlueprint | undefined
  gatekeeperId: number
  resolve: () => void
  advisors: any
  companyList: CompanyListBlueprint
  selectedAdvisor: CompanyBlueprint | PersonBlueprint | undefined
}

export function RequestIntroConnections({
  requestable,
  teamSlug,
  gatekeeperId,
  resolve,
  companyList,
  selectedAdvisor,
  advisors,
}: IProps) {
  const { user } = useCurrentUser()
  const { showModal, closeModal } = useModal()
  const location = useLocation()
  const { team } = useTeam(teamSlug)
  const { isAdmin } = useAccessControl(teamSlug)

  const url = window.location.href
  const urlObj = new URL(url)
  const pathSegments = urlObj.pathname.split('/')
  const listsIndex = pathSegments.indexOf('lists')
  const fragment = pathSegments[listsIndex + 1]

  if (!companyList) {
    const { data: fetchedCompanyList } = useQuery(
      ['companyList', fragment],
      () => callApi(api.getCompanyListV2, fragment),
      { enabled: !companyList },
    )
    companyList = fetchedCompanyList
  }

  const requestableUuid = requestable?._uuid || requestable?.uuid

  const globalPerson = useQuery(
    ['requestable', requestableUuid],
    () => callApi(api.getGlobalPerson, requestableUuid),
    { enabled: !!requestableUuid },
  ).data

  const { data: connections } = useQuery(['connections', requestable?.uuid], () =>
    callApi(api.getConnectionsByUuid, requestableUuid),
  )

  const connectedAdvisors = advisors || connections

  const searchParams = new URLSearchParams(location.search)
  const advisorUuidsArray: string[] = []
  searchParams.forEach((value, key) => {
    if (key === 'advisor_uuids') {
      advisorUuidsArray.push(value)
    }
  })

  const filteredConnections =
    connectedAdvisors?.filter(
      (connection) => advisorUuidsArray.length === 0 || advisorUuidsArray.includes(connection.uuid),
    ) || []

  const admins = team?.admins_and_members.filter((a) => a.team_role === 'admin')

  const adminsAndMembers = admins
    ?.filter((admin) => isAdmin || admin.uuid !== user?.uuid)
    .map((admin, index) => ({
      label:
        `${admin.first_name} ${admin.last_name} - (${admin.email}` +
        (index === 0 ? ') (default)' : ')'),
      value: admin.uuid,
    }))

  const initialGatekeeper = isAdmin
    ? adminsAndMembers?.find((a) => a.value === user.uuid)
    : adminsAndMembers?.[0]

  const [gatekeeper, setGatekeeper] = useState(initialGatekeeper?.value)
  const [selectedFacilitator, setSelectedFacilitator] = useState<IntroConnection | null>(null)
  const [enableButton, setEnableButton] = useState<boolean>(false)

  useEffect(() => {
    if (filteredConnections.length < 2) {
      setSelectedFacilitator(filteredConnections[0])
    }
  }, [filteredConnections])

  useEffect(() => {
    setEnableButton(!!selectedFacilitator && !!gatekeeper)
  }, [selectedFacilitator, gatekeeper])

  useEffect(() => {
    if (connectedAdvisors && selectedAdvisor) {
      const selectedAdvisorFromConnections = connectedAdvisors.find(
        (connection) =>
          connection.user_id === selectedAdvisor.id || connection.uuid === selectedAdvisor.uuid,
      )
      setSelectedFacilitator(selectedAdvisorFromConnections)
    }
  }, [connections, selectedAdvisor])

  const findRequestor = () => admins?.find((a) => a.uuid === gatekeeper)

  const handleGatekeeperChange = (e: any) => {
    setGatekeeper(e)
  }

  const userSameAsRequestor = (requestor: IRequestor) => user.uuid === requestor.uuid

  const openIntroRequestModal = (
    requestable: ISalesTarget,
    companyListObject: IRCompanyListObject,
    facilitator: IntroConnection,
    requestor: IRequestor,
    adminsAndMembers: IRequestor[],
  ) => {
    const ModalComponent = userSameAsRequestor(requestor)
      ? ReviewSalesListHandler
      : CreateSalesListModal

    showModal(
      () => (
        <CreateSalesListModal
          requestable={requestable}
          companyListObject={companyListObject}
          facilitator={facilitator}
          requestor={requestor}
          introRequestUuid={null}
          adminsAndMembers={adminsAndMembers}
          selectedAdvisor={facilitator}
          facilitators={filteredConnections}
        />
      ),
      'create-sales-list-handler',
    )
    closeModal('render_sales_list_modal')
    closeModal('request-intro-connections-modal')
  }

  const handleCardClick = (connection: IntroConnection) => {
    setSelectedFacilitator(connection)
    const requestor = findRequestor()
    openIntroRequestModal(requestable, companyListObject, connection, requestor, adminsAndMembers)
  }

  const closeModals = () => {
    closeModal('render_sales_list_modal')
    closeModal('request-intro-connections-modal')
  }

  if (connections && connections.length === 0) {
    return (
      <Modal
        show={true}
        header={
          <Typography
            fontSize="16"
            fontWeight={600}
            lineHeight="1.2"
            className="w-full text-center mb-2 pb-2"
          >
            {`Request intro to ${requestable?.full_name}`}
          </Typography>
        }
        onHide={resolve}
      >
        <Typography fontSize="14" className="flex items-start justify-start">
          No connections found
        </Typography>
      </Modal>
    )
  }

  const companyListObject = companyList
    ? {
        slug: companyList.slug,
        uuid: companyList.uuid,
        owning_team: {
          avatar_url: companyList.owning_team.avatar_url,
          logo: companyList.owning_team.logo,
          logo_url: companyList.owning_team.logo_url,
          name: companyList.owning_team.name,
          slug: companyList.owning_team.slug,
          uuid: companyList.owning_team.uuid,
        },
        owned: companyList.owned,
        email_template_subject: companyList.email_template_subject,
        email_template_uuid: companyList.email_template_uuid,
      }
    : {}

  return (
    <Modal
      show={true}
      className="max-w-lg"
      header={
        <span>
          <Typography
            fontSize="16"
            fontWeight={600}
            lineHeight="1.2"
            className="w-full text-center"
          >
            {`Request intro to ${requestable?.full_name}`}
          </Typography>
        </span>
      }
      onHide={() => closeModals()}
    >
      <div>
        <Typography fontSize="14" className="flex flex-wrap mb-4">
          Select facilitator
        </Typography>
        <div className="flex flex-col mb-4">
          {filteredConnections.map((connection) => {
            const {
              avatar_url: avatarUrl,
              uuid: requestableId,
              name: fullName,
              headline,
              title,
              company_name,
            } = connection
            return (
              <div key={connection.id} className="w-full flex items-center justify-between">
                <DataCardV2
                  onClick={() => handleCardClick(connection)}
                  title={fullName}
                  avatar={<Avatar name={fullName} src={avatarUrl} size="32" />}
                  titleIcon={
                    connection.linkedin_url && (
                      <Typography
                        color="fog"
                        fontSize="16"
                        onClick={() => window.open(connection.linkedin_url, '_blank')}
                        component="button"
                      >
                        <i className="fab fa-linkedin"></i>
                      </Typography>
                    )
                  }
                  description={
                    <>
                      <Typography
                        color="rain_fog"
                        fontSize="12"
                        lineHeight={1}
                        className="flex items-center gap-1.5"
                      >
                        {headline || title} {company_name && <span>at {company_name}</span>}
                      </Typography>
                    </>
                  }
                  cta={
                    <div className="flex gap-3">
                      <CabalButton
                        variant="tertiary"
                        onClick={() => handleCardClick(connection)}
                        color="purple"
                        padding="0"
                      >
                        <Typography component="button" fontSize="12" color="purple">
                          Select <i className="far fa-chevron-right ml-1" />
                        </Typography>
                      </CabalButton>
                    </div>
                  }
                />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
