import React, { useEffect, useState } from 'react'

import {
  CategoryScale,
  ChartArea,
  Chart as ChartJS,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useTheme } from 'styled-components'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const getGradient = (ctx: CanvasRenderingContext2D, chartArea: ChartArea) => {
  const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
  gradient.addColorStop(0, 'rgba(138, 151, 255, 0.1)')
  gradient.addColorStop(0.5, 'rgba(138, 151, 255, 0.3)')
  gradient.addColorStop(1, 'rgba(138, 151, 255, 0.6)')
  return gradient
}

interface Props {
  title: string
  labels: string[]
  data: number[]
}

const LineChart: React.FC<Props> = ({ title, labels, data }) => {
  const theme = useTheme()
  const [stepSize, setStepSize] = useState(5)

  useEffect(() => {
    if (data.length > 0) {
      const maxValue = Math.max(...data) + 5
      const step = maxValue / 5
      const size = Math.ceil(step / 5) * 5
      setStepSize(size)
    }
  }, [data])

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'First dataset',
        data: data,
        fill: true,
        borderColor: '#8A97FF',
        backgroundColor: function (context) {
          const chart = context.chart
          const { ctx, chartArea } = chart

          if (!chartArea) {
            // This case happens on initial chart load
            return
          }
          return getGradient(ctx, chartArea)
        },
        pointRadius: function (context) {
          const dataLength = context.dataset.data.length
          return dataLength === 1 ? 2 : 0
        },
        pointHitRadius: 16,
        pointStyle: 'circle',
        pointBackgroundColor: '#8A97FF',
        tension: 0.4,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: theme.chart.tooltip.bg,
        titleColor: theme.colors.primary,
        titleFont: {
          size: 14,
        },
        bodyColor: theme.colors.primary,
        bodyFont: {
          size: 14,
        },
        borderColor: 'rgba(0, 0, 0, 0.2)', // Transparent border
        borderWidth: 1,
        displayColors: true,
        boxWidth: 10,
        boxHeight: 10,
        cornerRadius: 4,
        caretSize: 6,
        xPadding: 10,
        yPadding: 10,
        callbacks: {
          // Customize text for tooltip header
          title: function (tooltipItems) {
            // Assuming your labels are dates, adjust as needed
            return tooltipItems[0].label
          },
          // Customize text for tooltip body
          label: function (context) {
            let label = ` ${title}` || ''

            if (label) {
              label += ': '
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y
            }
            return label
          },
        },
      },
    },
    scales: {
      x: {
        border: { display: false },
        grid: {
          display: false,
        },
      },

      y: {
        beginAtZero: true,
        border: { display: false, dash: [5, 4] },
        ticks: {
          padding: 16,
        },
        grid: {
          drawBorder: false,
          color: theme.chart.line,
          drawTicks: false,
        },
      },
    },

    layout: {
      // padding: 20,
    },
    ticks: {
      stepSize: stepSize,
    },
  }

  return <Line id="canvas" options={options} data={chartData} />
}

export default LineChart
