import React, { useState } from 'react'

import AdvisorInviteModal from 'components/AdvisorInvite'
import ShareModal from 'components/CompanyHome/AdminHomeSetup/ShareModal'
import PageEditModal from 'containers/CompanyPage/PageEditModal'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import { RenderModal } from 'global/Modal/Context'
import Tooltip from 'global/Tooltip'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'

import { List, Team } from 'utils/types'

interface Props {
  team?: Team
  companyLists: List[]
}

const CompanyHeaderSection: React.FC<Props> = ({ team, companyLists }) => {
  const teamSlug = useTeamSlug()

  const { isAdmin, isAdminOrEmployee } = useAccessControl(team?.slug)

  const { showModal } = useModal()

  const renderPageEditModal: RenderModal = (resolve) => (
    <PageEditModal onHide={() => resolve()} teamSlug={teamSlug} companyLists={companyLists} />
  )

  const renderInviteModal: RenderModal = (resolve) => (
    <AdvisorInviteModal onHide={resolve} teamSlug={teamSlug} />
  )

  const renderShareModal: RenderModal = (resolve) => (
    <ShareModal onHide={() => resolve()} teamSlug={teamSlug} />
  )

  return (
    <div className="mt-4 flex justify-between">
      <div className="flex items-end">
        <div className="flex-shrink-0">
          <Avatar src={team?.logo} name={team?.name} size={'72'} />
        </div>
        <div className="flex flex-col ml-3">
          <div className="flex items-baseline gap-2">
            <Typography lineHeight={1.2} fontSize="36" fontWeight={600}>
              {team?.name}
            </Typography>
            <Typography fontSize="20" color="purple" lineHeight={1}>
              <i className="fa fa-badge-check fa-fw -mr-1" />
            </Typography>
            {team?.twitter_url && (
              <a href={team?.twitter_url} target="_blank" rel="noreferrer">
                <Typography fontSize="20" color="fog" lineHeight={1} component="p">
                  <i className="fab fa-x-twitter" />
                </Typography>
              </a>
            )}
          </div>

          {team?.description && (
            <Tooltip label={team?.description}>
              <Typography
                className="py-1 truncate max-w-lg"
                color="fog"
                fontSize="16"
                lineHeight={1}
              >
                {team?.description}
              </Typography>
            </Tooltip>
          )}
        </div>
      </div>
      {isAdminOrEmployee && (
        <div className="flex flex-shrink-0 items-baseline">
          <CabalButton
            variant="link"
            leftIcon={<i className="far fa-pencil" />}
            onClick={() => showModal(renderPageEditModal, 'render_page_edit_modal')}
          >
            <Typography fontSize="12">Edit</Typography>
          </CabalButton>
          <CabalButton
            variant="link"
            leftIcon={<i className="far fa-arrow-up-from-bracket" />}
            onClick={() =>
              team?.enable_asks
                ? showModal(renderInviteModal, 'render_invite_modal')
                : showModal(renderShareModal, 'render_share_modal')
            }
          >
            <Typography fontSize="12">Invite</Typography>
          </CabalButton>
        </div>
      )}
    </div>
  )
}

export default CompanyHeaderSection
