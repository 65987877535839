import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import Collection from '@ckeditor/ckeditor5-utils/src/collection'
import Model from '@ckeditor/ckeditor5-ui/src/model'
import {
  addListToDropdown,
  createDropdown,
  ListDropdownItemDefinition,
} from '@ckeditor/ckeditor5-ui/src/dropdown/utils'

export default class UseVariable extends Plugin {
  init() {
    const editor = this.editor
    const variables = editor.config.get('variables')
    if (!variables) return

    editor.ui.componentFactory.add('variables', (locale) => {
      const dropdownView = createDropdown(locale)
      dropdownView.panelPosition = 'ne'

      addListToDropdown(dropdownView, this.getDropdownItemsDefinitions(variables))

      dropdownView.buttonView.set({
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M208 32h-88a56 56 0 0 0-56 56v77.49a40 40 0 0 1-11.72 28.29L7 239a24 24 0 0 0 0 34l45.24 45.24A40 40 0 0 1 64 346.52V424a56 56 0 0 0 56 56h88a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-88a8 8 0 0 1-8-8v-77.48a88.06 88.06 0 0 0-25.78-62.24L57.93 256l28.29-28.28A88.06 88.06 0 0 0 112 165.48V88a8 8 0 0 1 8-8h88a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zm361 207l-45.25-45.24A40.07 40.07 0 0 1 512 165.48V88a56 56 0 0 0-56-56h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a8 8 0 0 1 8 8v77.48a88 88 0 0 0 25.78 62.24L518.06 256l-28.28 28.28A88 88 0 0 0 464 346.52V424a8 8 0 0 1-8 8h-88a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h88a56 56 0 0 0 56-56v-77.49a40 40 0 0 1 11.72-28.29L569 273a24 24 0 0 0 0-34z"/></svg>',
        label: 'Use Variables',
        tooltip: true,
        withText: false,
      })

      this.listenTo(dropdownView, 'execute', (evt) => {
        editor.model.change((writer) => {
          const insertPosition = editor.model.document.selection.getFirstPosition()
          writer.insertText((evt.source as any).commandParam, insertPosition)
        })
        editor.editing.view.focus()
      })

      return dropdownView
    })
  }

  getDropdownItemsDefinitions(variables: string[]) {
    const itemDefinitions = new Collection<ListDropdownItemDefinition>()

    for (const variable of variables) {
      const definition: ListDropdownItemDefinition = {
        type: 'button',
        model: new Model({
          commandParam: variable,
          label: variable,
          withText: true,
        }),
      }

      itemDefinitions.add(definition)
    }

    return itemDefinitions
  }
}
