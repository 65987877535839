import React, { useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { sanitize } from 'dompurify'
import { useMutation } from 'react-query'

import DetailPageAboutSection from 'containers/ListIndexView/Components/DetailPageAboutSection'
import DetailPageFilters from 'containers/ListIndexView/Components/DetailPageFilters'
import DetailPageHeader from 'containers/ListIndexView/Components/DetailPageHeader'
import ResourceDiscountAddModal from 'containers/ListIndexView/ResourcesList/ResourceDiscountList/ResourceDiscountAddModal'
import { IResourceDiscount } from 'containers/ListIndexView/ResourcesList/types'
import ActivityWidget from 'containers/ListIndexView/TalentList/JobsList/JobsDetailPage.tsx/ActivityWidget'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import { useModal } from 'global/Modal'
import PageWrapper from 'global/PageWrapper'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import { useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'
import Widget from 'ui-components/Widget'

import api from 'utils/api'

interface DiscountDetailPageProps {
  discount: IResourceDiscount
  listUuid: string
}
const DiscountDetailPage: React.FC<DiscountDetailPageProps> = (props) => {
  const teamSlug = useTeamSlug()
  const { showModal } = useModal()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const [parent] = useAutoAnimate()
  const [memberView, setMemberView] = useState(false)
  const [filter, setFilter] = useState<string>('')
  const [discount, setDiscount] = useState<IResourceDiscount>(props.discount)

  const showAbout = filter === 'about' || filter === ''
  const showTags = filter === 'tags' || filter === ''

  const filterOptions = [
    { label: 'All', value: '' },
    { label: 'About', value: 'about', icon: 'far fa-user' },
    { label: 'Tags', value: 'tags', icon: 'far fa-list-ol' },
  ]

  const { mutate: refetchTags, data: allTags } = useMutation(() =>
    api.getResourceListTags(props.listUuid),
  )

  const { mutate: createOrModifyListItem } = useMutation(
    ({ resourceUuid, resource }: { resourceUuid?: string; resource: any }) =>
      api.addElementToList(props.listUuid, resource, resourceUuid),
    {
      onSuccess: () => {
        cabalToast({ style: 'success', content: 'Changes Saved' })
      },
      onError: () => {
        cabalToast({ style: 'error', content: 'Failed to add item' })
      },
    },
  )
  return (
    <PageWrapper title={discount.name}>
      <DetailPageHeader
        name={discount.name}
        logo={discount.logo_url}
        cta={
          <>
            {(!isAdminOrEmployee || memberView) && (discount.cta_url && discount.cta_url) && (
              <CabalButton
                variant="primary"
                onClick={() => {
                  const formattedLink =
                    discount.cta_url && !/^https?:\/\//i.test(discount.cta_url)
                      ? `http://${discount.cta_url}`
                      : discount.cta_url
                  window.open(formattedLink, '_blank', 'noreferrer')
                }}
              >
                {discount.cta_text}
              </CabalButton>
            )}
            {isAdminOrEmployee && !memberView && (
              <div>
                <CabalButton
                  leftIcon={<i className="far fa-pen"></i>}
                  onClick={() =>
                    showModal(
                      (resolve) => (
                        <ResourceDiscountAddModal
                          onHide={resolve}
                          teamSlug={teamSlug}
                          onSave={({ resourceUuid, resource }) => {
                            createOrModifyListItem({ resourceUuid, resource })
                            setDiscount({
                              name: resource.name || discount.name,
                              description: resource.description || discount.description,
                              logo_url: resource.logo_url || discount.logo_url,
                              tags: resource.tags || discount.tags,
                              cta_text: resource.cta_text || discount.cta_text,
                              cta_url: resource.cta_url || discount.cta_url,
                              uuid: resourceUuid,
                              discount_uuid: discount.uuid,
                            })
                          }}
                          tags={allTags}
                          item={discount}
                          listUuid={props.listUuid}
                          fetchListData={() => console.log('nothing')}
                        />
                      ),
                      'add-discount-modal',
                    )
                  }
                  variant="link"
                >
                  Edit
                </CabalButton>
              </div>
            )}
          </>
        }
      />
      <DetailPageFilters
        filterOptions={filterOptions}
        filter={filter}
        onSetFilter={setFilter}
        memberView={memberView}
        setMemberView={setMemberView}
      />
      <div className="mt-10 flex gap-4">
        <div ref={parent} className="flex flex-col flex-1 gap-8 max-w-[630px]">
          {showAbout && (
            <DetailPageAboutSection
              description={
                <div>
                  <p className="leading-5 mb-2">
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: sanitize(
                          discount.description
                            ?.replace(/<p>/g, '<span>')
                            .replace(/<\/p>/g, '</span>') || '',
                        ),
                      }}
                    />
                  </p>
                </div>
              }
            />
          )}

          {showTags && discount.tags && discount.tags.length > 0 && (
            <Widget title="Tags">
              <div className="mt-4">
                <Typography
                  fontSize="12"
                  component="div"
                  color="fog"
                  className="flex gap-1 flex-wrap"
                >
                  {discount.tags.map((tag, i) => (
                    <Pill variant="gray" rounded key={i}>
                      {tag}
                    </Pill>
                  ))}
                </Typography>
              </div>
            </Widget>
          )}
        </div>
        <div className="w-80 flex-shrink-0 flex flex-col gap-4">
          <ActivityWidget advisorUuid={''} />
        </div>
      </div>
    </PageWrapper>
  )
}

export default DiscountDetailPage
