import React, { useState } from 'react'

import { AxiosResponse } from 'axios'
import styled from 'styled-components'

import AddCandidate from 'containers/AddCandidate'
import CabalButton from 'global/CabalButton'
import { TextInput } from 'global/Input'
import Modal from 'global/Modal'
import { ModalInputLabel, ModalSectionWrapper } from 'global/Modal/styles'
import CKEditor from 'global/TextEditor/ckeditor'
import Typography from 'global/Typography'

import api from 'utils/api'

export const WidthWrapper = styled.div`
  max-width: 660px;
  flex-grow: 1;
`

const EditorContainer = styled.div`
  min-height: 200px;
  border-color: ${({ theme, showErrorBorder }) =>
    showErrorBorder ? theme.colors.border_danger : theme.colors.border};
`

export const NewItemModal = (props) => {
  const {
    listTypeData,
    onHide,
    teamSlug,
    listUuid,
    modal = true,
    onSaveComplete,
    onClose,
    reload,
  } = props
  const [listItem, setListItem] = useState({})
  const [saving, setSaving] = useState(false)

  const onSave = (attributes = {}) => {
    setSaving(true)
    setListItem({ ...listItem, ...attributes })

    const listItemAttributes = {
      ...listItem,
      ...attributes,
    }

    api
      .createListItem({
        list_uuid: listUuid,
        team: teamSlug,
        list_item: listItemAttributes,
      })
      .then((response: AxiosResponse) => {
        const { list_item } = response.data
        onSaveComplete?.(list_item)
        onHide?.()
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const contents = (
    <>
      {listTypeData.value == 'posts' && (
        <>
          <ModalSectionWrapper>
            <ModalInputLabel>Title</ModalInputLabel>
            <TextInput
              fontSize="14px"
              autoFocus
              value={listItem.title || ''}
              onChange={(e) => setListItem({ ...listItem, title: e.target.value })}
              className="w-full"
            />
          </ModalSectionWrapper>

          <EditorContainer className="border rounded-md mt-2">
            <CKEditor
              minHeight={'200px'}
              fixHeightOnLoad
              value={listItem.body || ''}
              onChange={(value) => setListItem({ ...listItem, body: value })}
            />
          </EditorContainer>

          <div className="mt-4 flex justify-between text-sm">
            <div>
              <CabalButton onClick={() => onSave()} working={saving}>
                {listTypeData?.add_item?.cta || 'Save'}
              </CabalButton>
            </div>
          </div>
        </>
      )}

      {listTypeData.value == 'people' && (
        <AddCandidate
          teamSlug={teamSlug}
          listUuid={listUuid} // TODO: send all-candidates if list.is_all_candidates
          reload={reload}
          onClose={onClose}
          isTalent={false}
        />
      )}
    </>
  )

  if (!modal) {
    return contents
  }

  return (
    <Modal
      header={
        <Typography fontSize="12" fontWeight={600} lineHeight="15px">
          New Item
        </Typography>
      }
      show
      onHide={onHide}
    >
      <div className="p-2 pt-0 pb-8">{contents}</div>
    </Modal>
  )
}

export default NewItemModal
